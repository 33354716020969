import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

import { colors } from '../lib/styles';
import Layout from '../components/layout';
import { Button } from '../lib/common';
import { apiFetch } from '../lib/fetch';

const baseStyles = {
  container: {
    backgroundAttachment: 'scroll',
    backgroundRepeat: 'repeat',
    height: '100%',
    minHeight: '900px',
    padding: '10px',
    color: 'white',
  },
  errorText: {
    color: colors.secondaryAccent,
    height: '18px',
    paddingTop: '18px',
  },
};

const Validate = (props) => {
  const { token } = props.match.params;
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    apiFetch(`/users/pubvalid/${token}`, { method: 'PUT' })
      .then((data) => {
        setUser(data);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  if (user) {
    return (
      <Layout>
        <div style={baseStyles.container} >
          <div>
            Your email has been validated
            {' '}
            <Button
              onClick={() => {
                document.location.href = '/agent-dashboard';
              }}
            >
              Click here
            </Button>
            {' '}
            to continue to the agent dashboard.
          </div>
        </div>
      </Layout>
    );
  }

  if (error) {
    document.location.href = '/agent-dashboard';
    return (
      <Layout/>
    );
  }

  return (
    <Layout>
      Validating...
      <CircularProgress size={80} thickness={5} />
    </Layout>
  );
};

export default Validate;
