import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import Masonry from '@mui/lab/Masonry';

import Layout from '../components/layout';
import Loading from '../components/loading';
import ClientSearch from '../components/client-search';

import { Item } from '../lib/dashUtils';
import { colors } from '../lib/styles';
import { apiFetch } from '../lib/fetch';
import { Button, TextField } from '../lib/common';
import useUser from '../lib/useUser';
import AgentForm from '../components/agent-form';
import { formatCurrency } from '../lib/util';
import rpData from '../lib/riskProfileData';

const styles = {
  container: {
    margin: '15px',
  },
  btn: {
    fontSize: 18,
    width: '100%',
  },
  search: {
    marginBottom: '24px',
    marginTop: '24px',
    fontSize: 18,
    borderRadius: '4px',
    width: '100%',
    height: '50px',
  },
  header: {
    textAlign: 'start',
    color: colors.darkAccent,
  },
  item: {
    width: 'fit-content',
    marginBottom: '24px',
    marginTop: '24px',
  },
  list: {
    width: '100%',
    borderRadius: '4px',
    backgroundColor: colors.white,
    position: 'relative',
    overflow: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    '& ul': { padding: 0 },
  },
};

function Snoozer(props) {
  const [open, setOpen] = useState(false);
  const [snoozeDate, setSnoozeDate] = useState(null);
  return (
    <>
      <Button
        style={{ margin: '5px' }}
        onClick={() => {
          setOpen(true);
        }}
      >
        Snooze...
      </Button>
      <Dialog open={open}>
        <DialogTitle>
          Snooze for how long?
        </DialogTitle>
        <div>
          <div>

            <div>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  const url = `/users/${props.user.id}/bsfs/${props.bsf_id}/snooze_anniv_til`;
                  apiFetch.put(url, { until: moment().add(2, 'day').format('YYYY-MM-DD') });
                  setOpen(false);
                  if (props.done) {
                    props.done();
                  }
                }}
                style={{ margin: '10px' }}
              >
                1 Day
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  const url = `/users/${props.user.id}/bsfs/${props.bsf_id}/snooze_anniv_til`;
                  apiFetch.put(url, { until: moment().add(4, 'days').format('YYYY-MM-DD') });
                  setOpen(false);
                  if (props.done) {
                    props.done();
                  }
                }}
                style={{ margin: '10px' }}
              >
                3 Days
              </Button>
            </div>
            <div style={{ minWidth: '300px', display: 'flex' }}>
              <div>
                <TextField
                  label="Snooze Until"
                  value={snoozeDate}
                  name="snoozeDate"
                  onChange={(e) => {
                    setSnoozeDate(e.target.value);
                  }}
                  type="date"
                  style={{ width: '100%', margin: '10px', minWidth: '130px' }}
                />
              </div>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  const url = `/users/${props.user.id}/bsfs/${props.bsf_id}/snooze_anniv_til`;
                  apiFetch.put(url, { until: moment(snoozeDate).format('YYYY-MM-DD') });
                  setOpen(false);
                  if (props.done) {
                    props.done();
                  }
                }}
                disabled={!snoozeDate}
                style={{ margin: '10px' }}
              >
                Snooze
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                style={{ margin: '10px' }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default function ClientManagement() {
  const history = useHistory();
  const userAuth = useUser();
  const [user, setUser] = useState(null);
  const [confirmAdd, setConfirmAdd] = useState(false);
  const [recents, setRecents] = useState([]);
  const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);
  const [invites, setInvites] = useState([]);
  const [notes, setNotes] = useState([]);
  const [hhStats, setHhStats] = useState([]);
  const [pending, setPending] = useState([]);
  const [hhNots, setHhNots] = useState([]);
  const [hhRequests, setHhRequests] = useState([]);
  const [rpAnswers, setRpAnswers] = useState([]);
  const [rejectedBsfs, setRejectedBsfs] = useState([]);
  const [bsfAnniversaries, setBsfAnniversaries] = useState([]);
  const [recentHouseholdShares, setRecentHouseholdShares] = useState([]);

  function findUser(id) {
    return apiFetch(`/users/${id}/stats`, { method: 'GET' });
  }

  useEffect(async () => {
    setUser(await findUser(userAuth.id));
    setRecents(await apiFetch(`/users/${userAuth.id}/clients_recent?orderBy=recent_view&sortOrder=desc`));
    setUpcomingBirthdays(await apiFetch(`/users/${userAuth.id}/clients/dob`));
    setInvites(await apiFetch(`/users/${userAuth.id}/shares/invites`));
    // TODO get new notes
    setNotes(await apiFetch(`/users/${userAuth.id}/clients/latest_notes`));
    // setHhStats(await apiFetch(`/users/${userAuth.id}/hedgehog/bsf/stats`));
    setPending(await apiFetch(`/users/${userAuth.id}/bsf/pending_agent`));
    // setHhNots(await apiFetch(`/users/${userAuth.id}/hedgehog/bsf/notifications`));
    setRejectedBsfs(await apiFetch(`/users/${userAuth.id}/bsf/rejected`));
    setHhRequests(await apiFetch(`/users/${userAuth.id}/shares/requests/owner`));
    const dashStats = await apiFetch(`/users/${userAuth.id}/clients/dashboard`);
    setHhStats(dashStats.hhStats);
    setHhNots(dashStats.hhNots);
    setRpAnswers(dashStats.recentRiskProfiles);
    setRecentHouseholdShares(dashStats.recentHouseholdShares);
    const filteredAnnivs = dashStats.bsfAnniversaries.filter((b) => {
      if (b.dismiss_anniv > 0) {
        return false;
      }
      if (b.snooze_anniv_til) {
        if (moment(b.snooze_anniv_til).isAfter(moment())) {
          return false;
        }
      }
      return true;
    });
    setBsfAnniversaries(filteredAnnivs);
    // console.log('dashStats', dashStats);
  }, [userAuth]);

  if (!user) return <Loading />;

  if (!userAuth.ep) return <Layout><AgentForm/></Layout>;

  const upcomingTasks = [];
  const clientManagementTasks = [];
  if (invites.length) {
    invites.forEach((invite) => {
      upcomingTasks.push({
        text: `Household invite from ${invite.requester_firstname} ${invite.requester_lastname}`,
        link: '/household_invites',
      });
    });
  }

  if (recentHouseholdShares.length) {
    recentHouseholdShares.forEach((share) => {
      clientManagementTasks.push({
        text: `${share.household_name} household was shared by ${share.owner_firstname} ${share.owner_lastname}`,
        link: `/household/${share.household_id}`,
      });
    });
  }

  if (notes.length) {
    notes.forEach((note) => {
      clientManagementTasks.push({
        text: `New note by ${note.firstname} ${note.lastname}`,
        link: `/household/${note.household_id}`,
      });
    });
  }

  if (pending.length) {
    pending.forEach((bsf) => {
      upcomingTasks.push({
        text: `A BSF by ${bsf.firstname} ${bsf.lastname} is awaiting your approval`,
        link: `/split-approval/${bsf.id}`,
      });
    });
  }

  if (rejectedBsfs.length) {
    rejectedBsfs.forEach((bsf) => {
      upcomingTasks.push({
        text: `${bsf.type} BSF ${bsf.policy_number} has been rejected`,
        link: `/bsfs/${bsf.id}`,
      });
    });
  }

  if (bsfAnniversaries.length) {
    bsfAnniversaries.forEach((bsf) => {
      const text = `${bsf.carrier_name} Anniversary ${bsf.policy_number}`;
      clientManagementTasks.push({
        component: (
          <>
            <ListItemText primary={text} sx={{ '& .MuiTypography-root': { fontSize: 16 } }} />
            <div>
              <Button
                style={{ margin: '5px' }}
                onClick={() => {
                  history.push(`/bsfs/${bsf.id}`);
                }}
              >
                View
              </Button>
              <Button
                style={{ margin: '5px' }}
                onClick={() => {
                  apiFetch.put(`/users/${userAuth.id}/bsfs/${bsf.id}/dismiss_anniv`, { dismiss_anniv: true });
                  setBsfAnniversaries(bsfAnniversaries.filter((b) => b.id !== bsf.id));
                }}
              >
                Dismiss
              </Button>
              <Snoozer
                bsf_id={bsf.id}
                user={userAuth}
                done={() => {
                  setBsfAnniversaries(bsfAnniversaries.filter((b) => b.id !== bsf.id));
                }}
              />
            </div>
          </>
        ),
        text: `${bsf.carrier_name} Anniversary ${bsf.policy_number}`,
        link: `/bsfs/${bsf.id}`,
      });
    });
  }

  if (hhRequests.length) {
    hhRequests.forEach((hhr) => {
      upcomingTasks.push({
        text: `${hhr.requester_firstname} ${hhr.requester_lastname} is requesting access
          to the ${hhr.household_name} household`,
        link: `/household/${hhr.household_id}/nav/settings`,
      });
    });
  }

  if (hhNots.length) {
    hhNots.forEach((n) => {
      if (n.overduePayment) {
        n.text = `The payment for Contract ${n.policy_number} is overdue`;
      } else if (n.maturingSoon) {
        n.text = `Contract ${n.policy_number} is maturing soon (${n.matureDay.substring(0, 10)})`;
      }
      n.link = `/bsfs/${n.id}`;
    });
  }

  if (rpAnswers.length) {
    rpAnswers.forEach((rp) => {
      rp.score = rpData.calcScore(rp);
      rp.default_model = rpData.getModel(rp.score);
    });
  }

  function formatRecent(recent) {
    return `${recent.firstname} ${recent.lastname}${recent.suffix ? ` ${recent.suffix}` : ''}`;
  }

  let totalCbasis = 0;
  hhStats.forEach((hh) => {
    totalCbasis += Number(hh.additional_c_basis) * (Number(hh.split) / 100);
    totalCbasis += Number(hh.contract_basis) * (Number(hh.split) / 100);
  });

  return (
    <Layout>
      <Box style={styles.container}>
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Button
              addIcon
              style={styles.btn}
              onClick={() => {
                if (userAuth.proxy) {
                  setConfirmAdd(true);
                } else {
                  history.push('/household/new');
                }
              }}
              sx={{ backgroundColor: colors.black, color: colors.white }}
            >
              Add Household
            </Button>
            <div style={styles.search}>
              <ClientSearch
                onChange={(c) => {
                  history.push(`/household/${c.household_id}/client/${c.id}`);
                }}
              />
            </div>
            <Button
              variant="outlined"
              style={styles.btn}
              onClick={() => {
                history.push('/clients');
              }}
              sx={{ borderColor: colors.black, color: colors.black }}
            >
              See Full Client List
            </Button>
            <Typography
              variant="h6"
              style={styles.header}
            >Recently Viewed
            </Typography>
            <List style={styles.list} sx={{ maxHeight: 500 }} >
              {recents.length ? recents.map((recent, idx) => (
                <div key={idx}>
                  <ListItem>
                    <ListItemButton>
                      <Link to={`/household/${recent.household_id}/client/${recent.client_id}`}>
                        <ListItemText
                          primary={formatRecent(recent)}
                          sx={{ '& .MuiTypography-root': { fontSize: 16 } }}
                          key={recent.client_id}
                        />
                      </Link>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </div>
              )) : (
                <ListItem>
                  <ListItemText
                    key="none"
                    primary="There are no recently viewed clients"
                    sx={{ '& .MuiTypography-root': { fontSize: 16 } }}
                  />
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={8}>
            <Item sx={{ paddingBottom: 0, margin: 'auto 8px' }}>
              <Typography variant="h5" style={styles.header}>
                Client Management Notifications
              </Typography>
              <Divider />
              <List style={styles.list} >
                {clientManagementTasks.length ? clientManagementTasks.map((task, idx) => (
                  <div key={idx}>
                    {task.component ? (
                      <ListItem>
                        {task.component}
                      </ListItem>
                    ) : (
                      <ListItem>
                        <ListItemText primary={task.text} sx={{ '& .MuiTypography-root': { fontSize: 16 } }} />
                        <Button
                          onClick={() => {
                            history.push(task.link);
                          }}
                        >
                          View
                        </Button>
                      </ListItem>
                    )}
                    <Divider />
                  </div>
                )) : ('')}
                {upcomingBirthdays.length ? upcomingBirthdays.map((bd, idx) => (
                  <div key={idx}>
                    <ListItem>
                      <Link to={`/household/${bd.household_id}/client/${bd.id}`}>
                        <ListItemText
                          primary={`Birthday: ${bd.firstname} ${bd.lastname} ${moment(bd.dob).format('DD-MMM-YYYY')}`}
                          sx={{ '& .MuiTypography-root': { fontSize: 16 } }}
                          key={idx}
                        />
                      </Link>
                    </ListItem>
                    <Divider />
                  </div>
                )) : ('')}
                {!upcomingTasks.length && !clientManagementTasks ? (
                  <ListItem>
                    <ListItemText
                      key="none"
                      primary="There are no current tasks or notifications"
                      sx={{ '& .MuiTypography-root': { fontSize: 16 } }}
                    />
                  </ListItem>
                ) : ''}
              </List>
            </Item>
            <Masonry
              columns={{
                xs: 1,
                sm: 2,
              }}
              spacing={2}
              sx={{ margin: 0 }}
            >
              <Item sx={{ marginRight: 3 }}>
                <Typography variant="h5" color={colors.black}>Pending Cases:</Typography>
                <Link to="/bsfs">
                  <Typography variant="h4" color={colors.black}>{user.openBSFs}</Typography>
                </Link>
              </Item>
              <Item sx={{ marginRight: 3 }}>
                <Typography variant="h5" color={colors.black}>HedgeHog:</Typography>
                <div>
                  Total contract basis for all pending hedgehog BSFs
                  <Typography variant="h6" color={colors.black}>${formatCurrency(totalCbasis)}</Typography>
                </div>
                <div>
                  <List style={styles.list} >
                    {hhNots.length ? hhNots.map((n, idx) => (
                      <div key={idx}>
                        <ListItem>
                          <ListItemText primary={n.text} sx={{ '& .MuiTypography-root': { fontSize: 16 } }} />
                          <Button
                            onClick={() => {
                              history.push(n.link);
                            }}
                          >
                            View
                          </Button>
                        </ListItem>
                        <Divider />
                      </div>
                    )) : ''}
                  </List>
                </div>
              </Item>
              <Item sx={{ paddingBottom: 0 }}>
                <Typography variant="h5" color={colors.black}>Notifications:</Typography>
                <List style={styles.list} >
                  {upcomingTasks.length ? upcomingTasks.map((task, idx) => (
                    <div key={idx}>
                      <ListItem>
                        <ListItemText primary={task.text} sx={{ '& .MuiTypography-root': { fontSize: 16 } }} />
                        <Button
                          onClick={() => {
                            history.push(task.link);
                          }}
                        >
                          View
                        </Button>
                      </ListItem>
                      <Divider />
                    </div>
                  )) : (
                    <ListItem>
                      <ListItemText
                        key="none"
                        primary="There are no upcoming tasks or notifications"
                        sx={{ '& .MuiTypography-root': { fontSize: 16 } }}
                      />
                    </ListItem>
                  )}
                </List>
              </Item>
              <Item sx={{ marginRight: 3 }}>
                <Typography variant="h5" color={colors.black}>Risk Profiles:</Typography>
                <div>
                  <Link to="/risk_profile_answers">
                    View All Risk Profiles
                  </Link>
                </div>
                <div>
                  <List style={styles.list} >
                    {rpAnswers.length ? rpAnswers.map((n, idx) => (
                      <div key={idx}>
                        <ListItem>
                          <ListItemText
                            primary={`${n.firstname} ${n.lastname} - ${n.default_model} (${n.score})`}
                            sx={{ '& .MuiTypography-root': { fontSize: 16 } }}
                          />
                          <Button
                            onClick={() => {
                              history.push(`/risk_profile_answers/${n.id}`);
                            }}
                          >
                            View
                          </Button>
                        </ListItem>
                        <Divider />
                      </div>
                    )) : ''}
                  </List>
                </div>
              </Item>
            </Masonry>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={confirmAdd}>
        <DialogTitle>
          You are attempting to create a new household for another agent.
          <br/><br/>
          Are you sure you want to do this?
        </DialogTitle>
        <div>
          <div>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setConfirmAdd(false);
                history.push('/household/new');
              }}
              style={{ margin: '10px' }}
            >
              Ok
            </Button>
            <Button
              onClick={() => {
                setConfirmAdd(false);
              }}
              style={{ margin: '10px' }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </Layout>
  );
}
