import React from 'react';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {
  TextField, Select, GridForm, MaskInput,
} from '../lib/common';
import DeleteButton from './delete-button';

const ClientBenForm = (props) => {
  const {
    ben,
    update,
    idx,
  } = props;

  const handleDelete = () => {
    ben.to_delete = true;
    update(ben);
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target || e;
    update({ ...ben, [name]: value });
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    update({ ...ben, [name]: moment(value).toISOString() });
  };

  const {
    firstname,
    middlename,
    lastname,
    dob,
    ssn,
    type,
    relationship_id,
    percentage,
    phone,
  } = ben;

  return (
    <GridForm>
      <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
        Beneficiary {'#' + (idx + 1)}
        <DeleteButton handleDelete={handleDelete} iconStyle={{ marginLeft: '15px' }}/>
      </Typography>
      <Grid container spacing={2}>
        <TextField
          id="firstname"
          name="firstname"
          label="First Name"
          value={firstname}
          onChange={handleTextChange}
        />
        <TextField
          label="Middle Name"
          name="middlename"
          value={middlename}
          onChange={handleTextChange}
        />
        <TextField
          label="Last Name"
          name="lastname"
          value={lastname}
          onChange={handleTextChange}
        />
      </Grid>
      <Grid container spacing={2}>
        <Select
          label="Type"
          value={type}
          name="type"
          onChange={handleTextChange}
        >
          <MenuItem value="PRIMARY">Primary</MenuItem>
          <MenuItem value="CONTINGENT">Contingent</MenuItem>
        </Select>
        <Select
          label="Relationship to Client"
          value={relationship_id}
          name="relationship_id"
          onChange={handleTextChange}
        >
          {
            props.relationshipTypes.sort((a, b) => a.order_val - b.order_val).map((relationship) => (
              <MenuItem value={relationship.id} key={relationship.name}>{relationship.name}</MenuItem>
            ))
          }
        </Select>
        <TextField
          label="Percentage to Receive"
          value={percentage}
          name="percentage"
          type="number"
          onChange={handleTextChange}
        />
      </Grid>
      <Grid container spacing={2}>
        <TextField
          label="Date of Birth"
          value={dob}
          name="dob"
          onChange={handleDateChange}
          type="date"
        />
        <MaskInput
          label="Social Security Number"
          value={ssn}
          name="ssn"
          onChange={handleTextChange}
          mask_type="ssn"
        />
        <MaskInput
          label="Phone Number"
          value={phone}
          name="phone"
          onChange={handleTextChange}
          mask_type="tel"
        />
      </Grid>
    </GridForm>
  );
};

export default ClientBenForm;
