/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import { Button, TextField, LinearProgressWithLabel } from '../lib/common';
import config from '../config';
import { apiFetch, sendFileDirect } from '../lib/fetch';
import { isImageFile } from '../lib/util';
// import { sendFile } from '../lib/websocket';

function RenameFileButton(props) {
  const { renameFunction, file, client_id } = props;
  const [fileName, setFileName] = useState('' + file.name);
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        startIcon={<DriveFileRenameOutlineIcon/>}
        householdadd
        style={{ margin: '5px', marginLeft: '20px' }}
        onClick={() => {
          setOpen(true);
        }}
      >
        Rename
      </Button>
      <Dialog open={open} onKeyDown={(e) => e.stopPropagation()}>
        <DialogTitle>Rename File</DialogTitle>
        <div style={{ margin: '10px', minWidth: '550px' }}>
          <div style={{ margin: '3px' }}>
            <TextField
              label="File Name"
              value={fileName}
              name="fileName"
              onChange={(e) => {
                setFileName(e.target.value);
              }}
              required={true}
            />
          </div>
          <div>
            <Button
              disabled={!fileName.trim()}
              onClick={(e) => {
                e.preventDefault();
                renameFunction(file.id, client_id, fileName);
                setOpen(false);
              }}
              style={{ margin: '10px' }}
            >
              Ok
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              style={{ margin: '10px' }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export const FileItem = (props) => {
  const {
    f,
    handleDelete,
    client_id,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const cleanName = (f.name || '').replace(/\s/g, '').replace('/', '');
  // const path = `clients/${client_id}/files/${f.id}/download/${cleanName}`;
  let fullPath;
  if (isImageFile(f)) {
    fullPath = `/image-viewer/client/${f.id}/${client_id}`;
  } else {
    fullPath = `${config.API_URL}/clients/${client_id}/files/${f.id}/download/${cleanName}`;
  }

  return (
    <div>
      <Typography
        variant="subtitle1"
        style={{
          marginLeft: '30px', color: 'black', display: 'flex', alignItems: 'center',
        }}
      >
        <a
          href={fullPath}
          target="_blank"
          rel="noreferrer"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <FileIcon/> {f.name}
        </a>
        <Tooltip
          title="Edit"
          style={{ marginLeft: '10px' }}
        >
          <MoreVertIcon
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          />
        </Tooltip>
        <Menu
          id="basic-menu"
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            top: '20px',
          }}
        >
          <MenuItem>
            <RenameFileButton
              file={f}
              client_id={client_id}
              renameFunction={(fileId, clientId, name) => {
                setAnchorEl(null);
                props.renameFunction(fileId, clientId, name);
              }}
            />
          </MenuItem>
          <Divider />
          <MenuItem>
            <DeleteFileButton
              callback={() => {
                setAnchorEl(null);
                handleDelete(f.id, client_id);
              }}
            />
          </MenuItem>
        </Menu>
      </Typography>
    </div>
  );
};

export function FolderItem(props) {
  const {
    record,
    handleDelete,
    depth,
    basePath,
    handleFileAdded,
    client_id,
    client_visible,
    setError,
  } = props;

  const [open, setOpen] = useState(false);
  const [folderAnchorEl, setFolderAnchorEl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const openFolder = !!folderAnchorEl;

  function addFile(parent) {
    setError(null);
    const inputDialog = document.createElement('input');
    inputDialog.id = 'fileUpload';
    inputDialog.type = 'file';
    // inputDialog.accept = 'image/png, image/jpeg';
    inputDialog.click();
    inputDialog.onchange = async (data) => {
      const [selectedFile] = data.target.files;
      if (selectedFile) {
        try {
          setUploading(true);
          setProgress(0);
          const path = `/${basePath}`;
          setUploading(true);
          const presigned = await apiFetch.post(`${path}/presign`, { content_type: selectedFile.type });
          setProgress(5);
          const statusFunc = (pct) => {
            setProgress(5 + (pct * 0.9));
          };
          await sendFileDirect({
            file: selectedFile,
            statusFunc,
            presigned,
          });
          const newFile = await apiFetch.post(`${path}/resolve/pre`, {
            name: selectedFile.name,
            client_visible,
            parent_id: parent.id,
            cloud_name: presigned.cloud_name,
            content_type: selectedFile.type,
          });
          setProgress(100);
          setUploading(false);
          handleFileAdded(newFile);
        } catch (e) {
          // eslint-disable-next-line
          console.error('error uploading file', e);
          setUploading(false);
          setError(e.message);
        }
      }
    };
  }

  const canDel = !record.children.length && !record.files.length && !(depth === 2);
  const canRen = !(depth === 2);

  return (
    <div
      style={{ marginLeft: `${15 * depth}px`, marginBottom: '5px' }}
    >
      <div>
        <Typography
          variant="subtitle1"
          nodeid={props.nodeId}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div
            onClick={() => {
              setOpen(!open);
            }}
            style={{ display: 'flex' }}
          >
            {open ? <ExpandMoreIcon/> : <ChevronRightIcon/>}
            {depth === 1 ? <PersonIcon/> : <FolderIcon/>}
            <span style={{ marginTop: '-2px', cursor: 'default' }}>{`${record.name}`}</span>
            <Dialog open={uploading} onKeyDown={(e) => e.stopPropagation()}>
              <DialogTitle>Uploading File</DialogTitle>
              <div style={{ margin: '15px' }}>
                <div>
                  <LinearProgressWithLabel variant="determinate" value={progress} />
                </div>
              </div>
            </Dialog>
          </div>
          {(record.folder && (!record.rootFolder)) ? (
            <Tooltip title="More">
              <span
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setFolderAnchorEl(event.currentTarget);
                }}
                style={{ display: 'flex' }}
              >
                <MoreVertIcon/>
              </span>
            </Tooltip>
          ) : ''}
          <Menu
            id="basic-menu"
            open={openFolder}
            onClose={() => {
              setFolderAnchorEl(null);
            }}
            onKeyDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            anchorEl={folderAnchorEl}
          >
            <MenuItem>
              <Button
                householdadd
                onClick={() => {
                  if (!open) {
                    setOpen(true);
                  }
                  addFile(record);
                  setFolderAnchorEl(null);
                }}
                style={{ margin: '5px', marginLeft: '20px' }}
                // disabled={uploading}
              >
                Add File
              </Button>
            </MenuItem>
            <Divider />
            <MenuItem>
              <AddFolderButton
                callback={(folderName) => {
                  const parent = record;
                  setFolderAnchorEl(null);
                  if (folderName && parent) {
                    if (!open) {
                      setOpen(true);
                    }
                    props.saveFolder(folderName, parent.id, client_id);
                  }
                }}
              />
            </MenuItem>
            {canDel ? (
              <Divider />
            ) : ''}
            {canDel ? (
              <MenuItem>
                <DeleteFolderButton
                  callback={() => {
                    setFolderAnchorEl(null);
                    props.deleteFolder(record.id, client_id);
                  }}
                />
              </MenuItem>
            ) : ''}
            {canRen ? (
              <Divider />
            ) : ''}
            {canRen ? (
              <MenuItem>
                <RenameFolderButton
                  folderName={record.name}
                  callback={(newname) => {
                    // console.log('rename', newname);
                    setFolderAnchorEl(null);
                    props.renameFolder(record.id, client_id, newname);
                  }}
                />
              </MenuItem>
            ) : ''}
          </Menu>
        </Typography>
      </div>
      {open ? (
        <>
          <div>
            <table>
              <tbody>
                {record.files.map((f, idx) => {
                  return (
                    <tr key={idx} style={{ display: 'flex', alignItems: 'center' }}>
                      <td>
                        <div style={{ marginLeft: '10px' }}>
                          <FileItem
                            depth={depth + 1}
                            f={f}
                            client_id={client_id}
                            handleDelete={handleDelete}
                            renameFunction={(fileId, clientId, name) => {
                              props.renameFunction(fileId, clientId, name);
                            }}
                            basePath={basePath}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {record.children.map((c, idx) => {
            return (
              <FolderItem
                depth={depth + 1}
                record={c}
                nodeId={c.nodeId}
                key={idx}
                client_id={client_id}
                client_visible={client_visible}
                labelIcon={record.folder ? <FolderIcon/> : <FileIcon/>}
                handleDelete={handleDelete}
                renameFunction={(fileId, clientId, name) => {
                  props.renameFunction(fileId, clientId, name);
                }}
                basePath={basePath}
                handleFileAdded={handleFileAdded}
                saveFolder={(name, parent_id, clientId) => {
                  props.saveFolder(name, parent_id, clientId);
                }}
                renameFolder={(fileId, clientId, name) => {
                  props.renameFolder(fileId, clientId, name);
                }}
                deleteFolder={(fileId, clientId) => {
                  props.deleteFolder(fileId, clientId);
                }}
                setError={setError}
              />
            );
          })}
        </>
      ) : ''}
    </div>
  );
}

export function AddFolderButton(props) {
  const [folderName, setFolderName] = useState('');
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        householdadd
        style={{ margin: '5px', marginLeft: '20px' }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.label || 'Add Folder'}
      </Button>
      <Dialog open={open} onKeyDown={(e) => e.stopPropagation()}>
        <DialogTitle>{props.label || 'Add Folder'}</DialogTitle>
        <div style={{ margin: '15px' }}>
          <div>
            <TextField
              label="Folder Name"
              value={folderName}
              name="folderName"
              onChange={(e) => {
                setFolderName(e.target.value);
                e.stopPropagation();
                e.preventDefault();
              }}
              required={true}
            />
          </div>
          <div>
            <Button
              onClick={(e) => {
                e.preventDefault();
                if (props.callback) {
                  props.callback(folderName);
                }
                setFolderName('');
                setOpen(false);
              }}
              style={{ margin: '10px' }}
            >
              Ok
            </Button>
            <Button
              onClick={() => {
                if (props.callback) {
                  props.callback(folderName);
                }
                setFolderName('');
                setOpen(false);
              }}
              style={{ margin: '10px' }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

function DeleteFolderButton(props) {
  const [folderName, setFolderName] = useState('');
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        startIcon={<RemoveCircleIcon/>}
        householdadd
        style={{ margin: '5px', marginLeft: '20px' }}
        onClick={() => {
          setOpen(true);
        }}
      >
        Delete
      </Button>
      <Dialog open={open} onKeyDown={(e) => e.stopPropagation()}>
        <DialogTitle>Are you sure you want to delete the folder?</DialogTitle>
        <div style={{ margin: '15px' }}>
          <div>
            <Button
              onClick={(e) => {
                e.preventDefault();
                if (props.callback) {
                  props.callback(folderName);
                }
                setFolderName('');
                setOpen(false);
              }}
              style={{ margin: '10px' }}
            >
              Ok
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              style={{ margin: '10px' }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

function DeleteFileButton(props) {
  const [folderName, setFolderName] = useState('');
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        startIcon={<RemoveCircleIcon/>}
        householdadd
        style={{ margin: '5px', marginLeft: '20px' }}
        onClick={() => {
          setOpen(true);
        }}
      >
        Delete
      </Button>
      <Dialog open={open} onKeyDown={(e) => e.stopPropagation()}>
        <DialogTitle>Are you sure you want to delete the file?</DialogTitle>
        <div style={{ margin: '15px' }}>
          <div>
            <Button
              onClick={(e) => {
                e.preventDefault();
                if (props.callback) {
                  props.callback(folderName);
                }
                setFolderName('');
                setOpen(false);
              }}
              style={{ margin: '10px' }}
            >
              Ok
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              style={{ margin: '10px' }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

function RenameFolderButton(props) {
  const [folderName, setFolderName] = useState('' + props.folderName);
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        startIcon={<DriveFileRenameOutlineIcon/>}
        householdadd
        style={{ margin: '5px', marginLeft: '20px' }}
        onClick={() => {
          setOpen(true);
        }}
      >
        Rename
      </Button>
      <Dialog open={open} onKeyDown={(e) => e.stopPropagation()}>
        <DialogTitle>Rename Folder</DialogTitle>
        <div style={{ margin: '15px' }}>
          <div>
            <TextField
              label="Folder Name"
              value={folderName}
              name="folderName"
              onChange={(e) => {
                setFolderName(e.target.value);
              }}
              required={true}
            />
          </div>
          <div>
            <Button
              disabled={!folderName.trim()}
              onClick={(e) => {
                e.preventDefault();
                if (props.callback) {
                  props.callback(folderName);
                }
                setOpen(false);
              }}
              style={{ margin: '10px' }}
            >
              Ok
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              style={{ margin: '10px' }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
