import React from 'react';
import { Card, CardActions, CardHeader } from '@mui/material';

import CopyButton from './copy-link-button';
import DesertRoseButton from './desert-rose-button';
import { Button } from '../lib/common';
import { colors } from '../lib/styles';

/* eslint-disable */
const hedgehogUrl = 'https://calendly.com/hedgehog-investments/hedgehog-stronghold-initial-meeting';

const styles = {
  cardActions: {
    display: 'flex',
    justifyContent: 'center',
  },
};

function AgentLinksCard(props) {
  const { agent } = props;
  const registerUrl = `${document.location.origin}/register/${agent.num}`;
  const emailUrl = 'https://mail.google.com/mail/u/0/#inbox';
  const rpUrl = `${document.location.origin}/risk_profile/${agent.num}`;
  
  return (
    <Card
      variant="outlined"
      sx={{ height: { md: '100%' } }}
    >
      <CardHeader
        title="Quick Links"
        sx={{ color: colors.white, backgroundColor: colors.black, textAlign: 'center' }}
      />
      <CardActions style={styles.cardActions}>
        <Button
          style={{ width: '100%', margin: 8 }}
          gold
          onClick={() => {
            window.open(emailUrl, '_blank').focus();
          }}
        >
          Access StrongHold Email
        </Button>
        <CopyButton link={emailUrl} />
      </CardActions>
      <CardActions style={styles.cardActions}>
        <Button
          style={{ width: '100%', margin: 8 }}
          gold
          onClick={() => {
            window.open(registerUrl, '_blank').focus();
          }}
        >
          My Recruitment Page
        </Button>
        <CopyButton link={registerUrl} />
      </CardActions>
      <CardActions style={styles.cardActions}>
        <Button
          style={{ width: '100%', margin: 8 }}
          gold
          onClick={() => {
            window.open(rpUrl, '_blank').focus();
          }}
        >
          Risk Profile Questionnaire
        </Button>
        <CopyButton link={rpUrl} />
      </CardActions>
      <CardActions style={styles.cardActions}>
        <Button
          style={{ width: '100%', margin: 8 }}
          gold
          onClick={() => {
            window.open(hedgehogUrl, "_blank");
          }}
        >
          Schedule HedgeHog Investments
        </Button>
        <CopyButton link={hedgehogUrl} />
      </CardActions>
      <CardActions style={styles.cardActions}>
        <DesertRoseButton />
      </CardActions>
    </Card>
  );
}

export default AgentLinksCard;
