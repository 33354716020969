import * as React from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import throttle from 'lodash/throttle';
import { apiFetch } from '../lib/fetch';

export default function UserSearch(props) {
  const [value, setValue] = React.useState(props.value || props.agent);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const fetch = React.useMemo(() => throttle(async (request, callback) => {
    let rows = await apiFetch('/users/search', { query: { term: request.input } });
    const ignore = props.ignore || [];
    rows = rows.filter((r) => {
      return !ignore.includes(r.id);
    });
    return callback(rows);
  }, 200), []);

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      sx={{ flexGrow: 1 }}
      getOptionLabel={(option) => {
        return typeof option === 'string' ? option : `${option.firstname} ${option.lastname} ${option.email}`;
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (props.onChange) {
          props.onChange(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Agent" fullWidth />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={PersonIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="body2" color="text.secondary">
                  {`${option.firstname} ${option.lastname} ${option.email}`}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
