import React from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';

import {
  colors,
} from '../lib/styles';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 350,
  },
  emptyGridContainer: {
    width: '100%',
    display: 'flex',
    height: 50,
    padding: 10,
  },
  submitBtn: {
    margin: '15px',
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'details',
    headerName: 'Details',
    align: 'left',
    width: 100,
    renderCell: (params) => {
      return <Link style={{ color: colors.darkAccent }} to={`/bsfs/${params.row.id}`}>Detail</Link>;
    },
  },
  {
    field: 'carrier_name',
    headerName: 'Carrier',
    align: 'left',
    width: 150,
  },
  {
    field: 'product_type_name',
    headerName: 'Product',
    align: 'left',
    width: 150,
  },
  {
    field: 'funds_type_name',
    headerName: 'Funds Type',
    align: 'left',
    width: 150,
  },
  {
    field: 'policy_number',
    headerName: 'Policy',
    align: 'left',
    width: 150,
  },
  {
    field: 'split',
    headerName: 'My split',
    align: 'left',
    width: 100,
  },
  {
    field: 'submitted_at',
    headerName: 'Effective',
    align: 'left',
    width: 200,
    renderCell: ({ row }) => {
      if (row.submitted_at) {
        return <span>{moment(row.submitted_at).format('DD-MMM-YYYY')}</span>;
      }
      return '';
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'left',
    width: 200,
    renderCell: (params) => {
      if (params.row.status === 'PENDING_AGENTS') {
        return <span>{params.row.agent === params.row.writing_agent ? 'PENDING_AGENTS' : 'NEEDS_APPROVAL'}</span>;
      }
      if (params.row.status === 'OPEN') {
        return <span>APPROVED</span>;
      }
      return <span>{params.row.status}</span>;
    },
  },
];

const BSFs = (props) => {
  const { client } = props;
  const classes = useStyles();

  const { data: bsfs, loading: bsfsLoading } = useSWR(`/clients/${client.id}/bsf`);

  const rows = bsfs || [];

  return (
    <>
      <Paper>
        {!bsfsLoading ? (
          <div className={rows.length ? classes.gridContainer : classes.emptyGridContainer}>
            {rows.length ? (
              <DataGrid columns={columns} rows={rows} />
            ) : 'none'}
          </div>
        ) : <CircularProgress/>}
      </Paper>
    </>
  );
};

export default BSFs;
