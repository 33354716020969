/* eslint-disable max-len */
import React, { useState } from 'react';
import moment from 'moment';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import {
  MaskInput, Select, TextArea, TextField,
} from '../lib/common';
import states from '../lib/states';
import { formatCurrency } from '../lib/util';

const ClientAnnuityForm = (props) => {
  const {
    annuity, annuityTypes, fundTypes, update,
  } = props;
  const [type_id, setTypeId] = useState(annuity.type_id);
  const [funds_type_id, setFundsTypeId] = useState(annuity.funds_type_id);
  const [issue_date, setIssueDate] = useState(annuity.issue_date);
  const [death_amount, setDeathAmount] = useState(formatCurrency(annuity.death_amount));
  const [init_premium_amount, setInitPremiumAmount] = useState(formatCurrency(annuity.init_premium_amount));
  const [accum_amount, setAccumAmount] = useState(formatCurrency(annuity.accum_amount));
  const [benefit_base_amount, setBenefitBaseAmount] = useState(formatCurrency(annuity.benefit_base_amount));
  const [rmd_friendly, setRmdFriendly] = useState(annuity.rmd_friendly);
  const [partial_roth, setPartialRoth] = useState(annuity.partial_roth);
  const [contract_number, setContractNumber] = useState(annuity.contract_number);
  const [fee_withdrawal_amount, setFeeWithdrawalAmount] = useState(annuity.fee_withdrawal_amount);
  const [fixed_account_rate, setFixedAccountRate] = useState(annuity.fixed_account_rate);
  const [guaranteed_roll_up, setGuaranteedRollUp] = useState(annuity.guaranteed_roll_up);
  const [income_stream_increase, setIncomeStreamIncrease] = useState(annuity.income_stream_increase);
  const [payout_options, setPayoutOptions] = useState(annuity.payout_options);
  const [inheritance_options, setInheritanceOptions] = useState(annuity.inheritance_options);
  const [ltc_benefits, setLtcBenefits] = useState(annuity.ltc_benefits);
  const [ltc_benefits_apply, setLtcBenefitsApply] = useState(annuity.ltc_benefits_apply);
  const [fees, setFees] = useState(annuity.fees);
  const [sub_account_fees, setSubAccountFees] = useState(annuity.sub_account_fees);
  const [rider_fees, setRiderFees] = useState(annuity.rider_fees);
  const [admin_fees, setAdminFees] = useState(annuity.admin_fees);
  const [mortality_fees, setMortalityFees] = useState(annuity.mortality_fees);
  const [death_benefit_fees, setDeathBenefitFees] = useState(annuity.death_benefit_fees);
  const [transferring_fees, setTransferringFees] = useState(annuity.transferring_fees);
  const [other_fees, setOtherFees] = useState(annuity.other_fees);
  const [surrender_years, setSurrenderYears] = useState(annuity.surrender_years);
  const [surrender_amount, setSurrenderAmount] = useState(formatCurrency(annuity.surrender_amount));
  const [subject_to_losses, setSubjectToLosses] = useState(annuity.subject_to_losses);
  const [phone, setPhone] = useState(annuity.phone);
  const [fax_accepted, setFaxAccepted] = useState(annuity.fax_accepted);
  const [electronic_accepted, setElectronicAccepted] = useState(annuity.electronic_accepted);
  const [transfer_fax, setTransferFax] = useState(annuity.transfer_fax);
  const [transfer_email, setTransferEmail] = useState(annuity.transfer_email);
  const [medallion_stamp, setMedallionStamp] = useState(annuity.medallion_stamp);
  const [overnight_address, setOvernightAddress] = useState(annuity.overnight_address);
  const [overnight_city, setOvernightCity] = useState(annuity.overnight_city);
  const [overnight_state, setOvernightState] = useState(annuity.overnight_state);
  const [overnight_zip, setOvernightZip] = useState(annuity.overnight_zip);
  const [regular_address, setRegularAddress] = useState(annuity.regular_address);
  const [regular_city, setRegularCity] = useState(annuity.regular_city);
  const [regular_state, setRegularState] = useState(annuity.regular_state);
  const [regular_zip, setRegularZip] = useState(annuity.regular_zip);
  const [funds_liquidated, setFundsLiquidated] = useState(annuity.funds_liquidated);
  const [cost_basis, setCostBasis] = useState(annuity.cost_basis);

  const data = {
    type_id,
    funds_type_id,
    issue_date,
    death_amount,
    init_premium_amount,
    accum_amount,
    benefit_base_amount,
    rmd_friendly,
    partial_roth,
    fee_withdrawal_amount,
    fixed_account_rate,
    guaranteed_roll_up,
    income_stream_increase,
    payout_options,
    inheritance_options,
    ltc_benefits,
    ltc_benefits_apply,
    fees,
    sub_account_fees,
    rider_fees,
    admin_fees,
    mortality_fees,
    death_benefit_fees,
    transferring_fees,
    other_fees,
    surrender_years,
    surrender_amount,
    subject_to_losses,
    phone,
    fax_accepted,
    electronic_accepted,
    transfer_fax,
    transfer_email,
    medallion_stamp,
    overnight_address,
    overnight_city,
    overnight_state,
    overnight_zip,
    regular_address,
    regular_city,
    regular_state,
    regular_zip,
    funds_liquidated,
    cost_basis,
    contract_number,
  };

  const handleTextChange = (e, setter) => {
    const { name, value } = e.target || e;
    update({ ...annuity, ...data, [name]: value });
    setter(value);
  };

  const handleDateChange = (e, setter) => {
    const { name, value } = e.target;
    const formattedValue = moment(value).toISOString();
    update({ ...annuity, ...data, [name]: formattedValue });
    setter(formattedValue);
  };

  const handleRadioChange = (e, setter) => {
    const { name, value } = e.target;
    update({ ...annuity, ...data, [name]: value === 'true' });
    setter(value === 'true');
  };

  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h6" align="center">Annuity Details</Typography>
      <div>
        <Grid container spacing={2}>
          <TextField
            name="contract_number"
            label="Contract Number"
            value={contract_number}
            onChange={(e) => handleTextChange(e, setContractNumber)}
          />
          <Select
            value={type_id}
            label="Type"
            name="type_id"
            onChange={(e) => handleTextChange(e, setTypeId)}
          >
            {
            annuityTypes
              .sort((a, b) => a.order_val - b.order_val)
              .map((aType) => <MenuItem value={aType.id} key={aType.id}>{aType.name}</MenuItem>)
            }
          </Select>
          <TextField
            value={issue_date}
            label="Issue/Effective Date"
            name="issue_date"
            onChange={(e) => handleDateChange(e, setIssueDate)}
            type="date"
          />
          <TextField
            name="fixed_account_rate"
            label="Fixed Account Rate"
            value={fixed_account_rate}
            onChange={(e) => handleTextChange(e, setFixedAccountRate)}
            type="number"
          />
        </Grid>
        <Grid container spacing={2}>
          <TextField
            id="surrender_years"
            name="surrender_years"
            label="Surrender Schedule Length"
            type="number"
            value={surrender_years}
            onChange={(e) => handleTextChange(e, setSurrenderYears)}
          />
          <MaskInput
            mask_type="currency"
            id="init_premium_amount"
            label="Initial Premium Amount"
            name="init_premium_amount"
            value={init_premium_amount}
            onChange={(e) => handleTextChange(e, setInitPremiumAmount)}
          />
          <Select
            value={funds_type_id}
            label="Funds Type"
            name="funds_type_id"
            onChange={(e) => handleTextChange(e, setFundsTypeId)}
          >
            {
              fundTypes.sort((a, b) => a.order_val - b.order_val).map((i) => {
                return (<MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>);
              })
            }
          </Select>
          <Select
            value={rmd_friendly}
            label="Contract is RMD Friendly?"
            name="rmd_friendly"
            onChange={(e) => handleTextChange(e, setRmdFriendly)}
          >
            <MenuItem value="YES">Yes</MenuItem>
            <MenuItem value="NO">No</MenuItem>
          </Select>
        </Grid>
        <Grid container spacing={2}>

          <MaskInput
            mask_type="currency"
            id="accum_amount"
            label="Accumulation Amount"
            value={accum_amount}
            name="accum_amount"
            onChange={(e) => handleTextChange(e, setAccumAmount)}
          />
          <MaskInput
            mask_type="currency"
            id="death_amount"
            label="Death Benefit Amount"
            name="death_amount"
            value={death_amount}
            onChange={(e) => handleTextChange(e, setDeathAmount)}
          />
          <MaskInput
            mask_type="currency"
            id="surrender_amount"
            label="Surrender Amount"
            name="surrender_amount"
            value={surrender_amount}
            onChange={(e) => handleTextChange(e, setSurrenderAmount)}
          />
          <MaskInput
            mask_type="currency"
            id="cost_basis"
            label="Cost Basis"
            name="cost_basis"
            value={cost_basis}
            onChange={(e) => handleTextChange(e, setCostBasis)}
          />
        </Grid>
        <Grid container spacing={2}>
          <MaskInput
            mask_type="currency"
            id="benefit_base_amount"
            label="Income Benefit Base Amount"
            name="benefit_base_amount"
            value={benefit_base_amount}
            onChange={(e) => handleTextChange(e, setBenefitBaseAmount)}
            expand
          />
          <TextField
            name="fee_withdrawal_amount"
            label="Free Withdrawal amount/percentage?"
            value={fee_withdrawal_amount}
            onChange={(e) => handleTextChange(e, setFeeWithdrawalAmount)}
            expand
          />
        </Grid>
        <Grid container spacing={2}>
          <Select
            value={partial_roth}
            label="Partial ROTH conversions allowed?"
            name="partial_roth"
            onChange={(e) => handleTextChange(e, setPartialRoth)}
            expand
          >
            <MenuItem value="YES">Yes</MenuItem>
            <MenuItem value="NO">No</MenuItem>
          </Select>
        </Grid>

        <Typography
          variant="h6"
          sx={{ fontSize: '1.2rem', borderBottom: '1px solid #ccc' }}
        >
          Income Rider Details
        </Typography>

        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          What is the guaranteed roll up rate and will it ever stop or go away? If so, when?
        </Typography>
        <Grid container spacing={2}>
          <TextArea
            id="guaranteed_roll_up"
            name="guaranteed_roll_up"
            value={guaranteed_roll_up}
            onChange={(e) => handleTextChange(e, setGuaranteedRollUp)}
          />
        </Grid>

        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          Once the income is turned on, can the income stream increase, decrease, or does it stay level?
        </Typography>
        <Grid container spacing={2}>
          <TextArea
            id="income_stream_increase"
            name="income_stream_increase"
            value={income_stream_increase}
            onChange={(e) => handleTextChange(e, setIncomeStreamIncrease)}
          />
        </Grid>

        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          What are the contract payout options (Single, Joint)? What are the contract payout factors/bands?
        </Typography>
        <Grid container spacing={2}>
          <TextArea
            id="payout_options"
            name="payout_options"
            value={payout_options}
            onChange={(e) => handleTextChange(e, setPayoutOptions)}
          />
        </Grid>

        <Typography
          variant="h6"
          sx={{ fontSize: '1.2rem', borderBottom: '1px solid #ccc' }}
        >
          Inheritance Options Details
        </Typography>
        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          What are the inheritance options for heirs? Accumulation amount? Benefit Base Amount? Payout time frame?
        </Typography>
        <Grid container spacing={2}>
          <TextArea
            id="inheritance_options"
            name="inheritance_options"
            value={inheritance_options}
            onChange={(e) => handleTextChange(e, setInheritanceOptions)}
          />
        </Grid>

        <Typography
          variant="h6"
          sx={{ fontSize: '1.2rem', borderBottom: '1px solid #ccc' }}
        >
          Long Term Care Benefit Details
        </Typography>
        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          Are there any LTC benefits on the contract? If so, what are they? (i.e. Income doubler)
        </Typography>
        <Grid container spacing={2}>
          <TextArea
            id="ltc_benefits"
            name="ltc_benefits"
            value={ltc_benefits}
            onChange={(e) => handleTextChange(e, setLtcBenefits)}
          />
        </Grid>

        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          Does LTC benefits apply to the contract owner only?
          Will the LTC benefits still trigger if the non-owner spouse has a LTC event?
        </Typography>
        <Grid container spacing={2}>
          <TextArea
            id="ltc_benefits_apply"
            name="ltc_benefits_apply"
            value={ltc_benefits_apply}
            onChange={(e) => handleTextChange(e, setLtcBenefitsApply)}
          />
        </Grid>

        <Typography
          variant="h6"
          sx={{ fontSize: '1.2rem', borderBottom: '1px solid #ccc' }}
        >
          Annuity Fees
        </Typography>
        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          What are my fees on the contract?
        </Typography>
        <Grid container spacing={2}>
          <TextArea
            id="fees"
            name="fees"
            value={fees}
            onChange={(e) => handleTextChange(e, setFees)}
          />
        </Grid>
        <Grid container spacing={2}>
          <TextField
            id="sub_account_fees"
            label="Sub-account Fees"
            name="sub_account_fees"
            value={sub_account_fees}
            onChange={(e) => handleTextChange(e, setSubAccountFees)}
          />
          <TextField
            id="rider_fees"
            label="Rider Fees"
            name="rider_fees"
            value={rider_fees}
            onChange={(e) => handleTextChange(e, setRiderFees)}
          />
          <TextField
            id="admin_fees"
            label="Admin Fees"
            name="admin_fees"
            value={admin_fees}
            onChange={(e) => handleTextChange(e, setAdminFees)}
          />
        </Grid>
        <Grid container spacing={2}>
          <TextField
            id="mortality_fees"
            label="Mortality & Expense Fees"
            name="mortality_fees"
            value={mortality_fees}
            onChange={(e) => handleTextChange(e, setMortalityFees)}
          />
          <TextField
            id="death_benefit_fees"
            label="Greater of Death Benefit Fees"
            name="death_benefit_fees"
            value={death_benefit_fees}
            onChange={(e) => handleTextChange(e, setDeathBenefitFees)}
          />
          <TextField
            id="transferring_fees"
            label="Transferring Fees"
            name="transferring_fees"
            value={transferring_fees}
            onChange={(e) => handleTextChange(e, setTransferringFees)}
          />
        </Grid>

        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          Any other fees we should know about?
        </Typography>
        <Grid container spacing={2}>
          <TextArea
            id="other_fees"
            name="other_fees"
            value={other_fees}
            onChange={(e) => handleTextChange(e, setOtherFees)}
          />
        </Grid>

        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          If variable, is contract subject to possible market losses if market declines?
        </Typography>
        <Grid container spacing={2}>
          <TextArea
            id="subject_to_losses"
            name="subject_to_losses"
            value={subject_to_losses}
            onChange={(e) => handleTextChange(e, setSubjectToLosses)}
          />
        </Grid>

        <Typography
          variant="h6"
          sx={{ fontSize: '1.2rem', borderBottom: '1px solid #ccc' }}
        >
          Transferring Information
        </Typography>
        <Grid container spacing={2}>
          <MaskInput
            id="phone"
            label="Existing Custodian Phone Number"
            name="phone"
            value={phone}
            onChange={(e) => handleTextChange(e, setPhone)}
            mask_type="tel"
            expand
          />
        </Grid>

        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          Are faxed transfer forms accepted or is wet signature required?
        </Typography>
        <RadioGroup
          aria-label="fax_accepted"
          name="fax_accepted"
          value={fax_accepted}
          onChange={(e) => handleRadioChange(e, setFaxAccepted)}
          row
          sx={{ marginLeft: 1 }}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes, fax is accepted" />
          <FormControlLabel value={false} control={<Radio />} label="No, wet signature required" />
        </RadioGroup>
        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          Is electronic signature accepted?
        </Typography>
        <RadioGroup
          aria-label="electronic_accepted"
          name="electronic_accepted"
          value={electronic_accepted}
          onChange={(e) => handleRadioChange(e, setElectronicAccepted)}
          row
          sx={{ marginLeft: 1 }}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>

        <Grid container spacing={2}>
          <MaskInput
            id="transfer_fax"
            label="Fax Number for Transfer Requests"
            name="transfer_fax"
            value={transfer_fax}
            onChange={(e) => handleTextChange(e, setTransferFax)}
            mask_type="tel"
            expand
          />
        </Grid>

        <Grid container spacing={2}>
          <TextField
            id="transfer_email"
            label="Email for Transfer Requests"
            name="transfer_email"
            value={transfer_email}
            onChange={(e) => handleTextChange(e, setTransferEmail)}
            type="email"
            expand
          />
        </Grid>

        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          Is a Medallion Stamp Guarantee required on all requests or only at a certain dollar amount?
        </Typography>
        <Grid container spacing={2}>
          <TextArea
            id="medallion_stamp"
            name="medallion_stamp"
            value={medallion_stamp}
            onChange={(e) => handleTextChange(e, setMedallionStamp)}
          />
        </Grid>

        <Box
          display="grid"
          gridTemplateColumns="repeat(15, 1fr)"
          gap={2}
          width={985}
        >
          <Box gridColumn="span 6">
            <TextField
              label="Overnight Mailing Address (physical address, no PO Boxes)"
              value={overnight_address}
              name="overnight_address"
              onChange={(e) => handleTextChange(e, setOvernightAddress)}
            />
          </Box>
          <Box gridColumn="span 3">
            <TextField
              label="City"
              value={overnight_city}
              name="overnight_city"
              onChange={(e) => handleTextChange(e, setOvernightCity)}
            />
          </Box>
          <Box gridColumn="span 3">
            <Select
              label="State"
              value={overnight_state}
              name="overnight_state"
              onChange={(e) => handleTextChange(e, setOvernightState)}
            >
              {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
            </Select>
          </Box>
          <Box gridColumn="span 3">
            <MaskInput
              mask_type="zip"
              label="Postal Code"
              value={overnight_zip}
              name="overnight_zip"
              onChange={(e) => handleTextChange(e, setOvernightZip)}
            />
          </Box>
        </Box>

        <Box
          display="grid"
          gridTemplateColumns="repeat(15, 1fr)"
          gap={2}
          width={985}
        >
          <Box gridColumn="span 6">
            <TextField
              label="Regular Mailing Address (typically a PO Box)"
              value={regular_address}
              name="regular_address"
              onChange={(e) => handleTextChange(e, setRegularAddress)}
            />
          </Box>
          <Box gridColumn="span 3">
            <TextField
              label="City"
              value={regular_city}
              name="regular_city"
              onChange={(e) => handleTextChange(e, setRegularCity)}
            />
          </Box>
          <Box gridColumn="span 3">
            <Select
              label="State"
              value={regular_state}
              name="regular_state"
              onChange={(e) => handleTextChange(e, setRegularState)}
            >
              {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
            </Select>
          </Box>
          <Box gridColumn="span 3">
            <MaskInput
              mask_type="zip"
              label="Postal Code"
              value={regular_zip}
              name="regular_zip"
              onChange={(e) => handleTextChange(e, setRegularZip)}
            />
          </Box>
        </Box>

        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          Do funds need to be liquidated before a transfer request can be processed? What does that process look like?
        </Typography>
        <Grid container spacing={2}>
          <TextArea
            id="funds_liquidated"
            name="funds_liquidated"
            value={funds_liquidated}
            onChange={(e) => handleTextChange(e, setFundsLiquidated)}
          />
        </Grid>
      </div>
    </div>
  );
};

export default ClientAnnuityForm;
