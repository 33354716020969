import React, { useState } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { Button, TextArea } from '../lib/common';
import useUser from '../lib/useUser';
import { apiFetch } from '../lib/fetch';

const DEFAULT_TYPE = 'SUGGESTION';

function FeedbackDialog(props) {
  const { iconStyle, open, handleClose } = props;
  const [sent, setSent] = useState(false);
  const [type, setType] = useState(DEFAULT_TYPE);
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const user = useUser();

  const fullIconStyle = (iconStyle || { margin: '5px' });
  fullIconStyle.cursor = 'pointer';
  return (
    <>
      <Dialog open={open} disableScrollLock>
        <DialogTitle>Submit feedback</DialogTitle>
        <div style={{ margin: '20px' }}>
          {!sent ? (
            <div>
              <FormControl>
                <FormLabel id="radio-buttons-group-label">Type</FormLabel>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  defaultValue={DEFAULT_TYPE}
                  name="radio-buttons-group"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  <FormControlLabel value="SUGGESTION" control={<Radio />} label="Suggestion" />
                  <FormControlLabel value="PROBLEM" control={<Radio />} label="Problem" />
                </RadioGroup>
              </FormControl>
              <div>
                <FormControl>
                  <FormLabel id="msg-label">Message</FormLabel>
                  <TextArea
                    name="message"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    style={{ width: '300px', height: '200px' }}
                  />
                </FormControl>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setSent(false);
                    setMessage('');
                    setType(DEFAULT_TYPE);
                    handleClose();
                  }}
                  style={{ margin: '10px' }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={async (e) => {
                    e.preventDefault();
                    try {
                      const url = `/users/${user.id}/feedback`;
                      const path = window.location.pathname + window.location.search;
                      setSubmitting(true);
                      await apiFetch.post(url, {
                        message,
                        type,
                        path,
                      });
                      setSent(true);
                      setType(DEFAULT_TYPE);
                      setMessage('');
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                  disabled={!message || submitting}
                  style={{ margin: '10px' }}
                >
                  Ok
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div>Feedback sent. Thank you.</div>
              <Button
                type="button"
                onClick={() => {
                  handleClose();
                  setSent(false);
                }}
                style={{ margin: '10px' }}
              >
                Close
              </Button>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}

export default FeedbackDialog;
