import React from 'react';
import moment from 'moment';
import { TextField } from '../lib/common';

function ClientTextField(props) {
  const { client } = props;
  let clientStr = '';
  if (client) {
    clientStr = `${client.firstname} ${client.lastname}`;
    if (client.dob) {
      const dobStr = moment(client.dob).format('YYYY-MM-DD');
      const ageStr = moment().diff(client.dob, 'years', false) + ' yrs';
      clientStr += ` ${ageStr} ${dobStr}`;
    }
  }
  return (
    <TextField
      label="Client"
      value={clientStr}
      disabled
      style={{
        minWidth: '240px',
      }}
    />
  );
}

export default ClientTextField;
