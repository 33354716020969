import React, { useState } from 'react';
import { Button } from '../lib/common';

const styles = {
  calcContainer: {
    margin: 'auto',
    minWidth: 650,
  },
  input: {
    width: '7em',
    textAlign: 'right',
    borderRadius: '5px',
    padding: '4px 4px !important',
    color: 'black',
  },
  th: {
    padding: '5px',
    wordWrap: 'break-word',
  },
  thead: {
    fontFamily: 'Maven Pro, Helvetica, Arial, Lucida, sans-serif',
    fontSize: '22px',
    fontWeight: '600',
  },
  table: {
    border: '1px solid white',
    background: '#000000',
    color: '#ffffff',
    borderRadius: '10px',
    padding: '20px',
    width: '100%',
    tableLayout: 'fixed',
  },
  tr: {
    fontWeight: '600',
    textTransform: 'uppercase',
  },
};

export default function CommissionTable() {
  const [ap, setAvgProduction] = useState(0);
  const [p1, setP1] = useState(0);
  const [p2, setP2] = useState(0);
  const [p3, setP3] = useState(0);
  const [p4, setP4] = useState(0);
  const [p5, setP5] = useState(0);
  const [p6, setP6] = useState(0);
  const [p7, setP7] = useState(0);

  const ls1 = (ap * 0.035) * p1;
  const pt1 = ls1;

  const ps2 = (ap * 0.002) * p2;
  const ls2 = p1 > 4 ? (ap * 0.038) * p2 : 0;
  const pt2 = ps2 + ls2;

  const ps3 = (ap * 0.001) * p3;
  const ls3 = p1 > 9 ? (ap * 0.024) * p3 : 0;
  const pt3 = ps3 + ls3;

  const ps4 = (ap * 0.001) * p4;
  const ls4 = p1 > 14 ? (ap * 0.014) * p4 : 0;
  const pt4 = ps4 + ls4;

  const ps5 = (ap * 0.001) * p5;
  const ls5 = p1 > 19 ? (ap * 0.009) * p5 : 0;
  const pt5 = ps5 + ls5;

  const ps6 = (ap * 0.005) * p6;
  const ls6 = p1 > 24 ? (ap * 0.02) * p6 : 0;
  const pt6 = ps6 + ls6;

  const ps7 = (ap * 0.005) * p7;
  const ls7 = p1 > 39 ? (ap * 0.045) * p7 : 0;
  const pt7 = ps7 + ls7;

  const createChangeHandler = (setter) => {
    return (event) => {
      const val = Number(event.target.value || 0);
      setter(Math.min(Math.abs(Math.round(val)), 8000000000));
    };
  };

  const reset = () => {
    setAvgProduction(0);
    setP1(0);
    setP2(0);
    setP3(0);
    setP4(0);
    setP5(0);
    setP6(0);
    setP7(0);
  };

  const format = (input) => {
    const val = input || 0;
    return '$' + Math.round(Number(val)).toLocaleString('en-US');
  };

  return (
    <div style={styles.calcContainer}>
      <style>
        {`input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
          -webkit-appearance: none; 
          margin: 0; 
        }`}
      </style>
      <table style={styles.table} cellSpacing="0" cellPadding="4">
        <thead style={styles.thead}>
          <tr style={styles.tr}>
            <td align="center" colSpan={5}>Average Annual Production</td>
            <td>
              <Button
                gold
                onClick={reset}
              >
                reset
              </Button>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr style={styles.tr}>
            <td align="center" colSpan={5}>
              $<input
                style={styles.input}
                value={ap || ''}
                type="number"
                onChange={(event) => {
                  let val = Math.abs(Math.round(Number(event.target.value || 0)));
                  if (val > 80000) {
                    val = 80000;
                  }
                  setAvgProduction(val);
                }}
              />
            </td>
            <td/>
          </tr>
          <tr style={styles.tr}>
            <td align="center" style={styles.th}>Pillar</td>
            <td align="center" style={styles.th}>Directs Needed</td>
            <td align="center" style={styles.th}>Agents Per Pillar</td>
            <td align="center" style={styles.th}>Partner Share</td>
            <td align="center" style={styles.th}>Lion Share</td>
            <td align="center" style={styles.th}>Pillar Totals</td>
          </tr>
          <tr style={styles.tr}>
            <td align="center">1</td>
            <td align="center">0-4</td>
            <td align="center">
              <input
                style={styles.input}
                value={p1 || ''}
                type="number"
                onChange={createChangeHandler(setP1)}
              />
            </td>
            <td align="center">N/A</td>
            <td align="center">{format(ls1)}</td>
            <td align="center">{format(pt1)}</td>
          </tr>
          <tr style={styles.tr}>
            <td align="center">2</td>
            <td align="center">5-9</td>
            <td align="center">
              <input
                style={styles.input}
                value={p2 || ''}
                type="number"
                onChange={createChangeHandler(setP2)}
              />
            </td>
            <td align="center">{format(ps2)}</td>
            <td align="center">{format(ls2)}</td>
            <td align="center">{format(pt2)}</td>
          </tr>
          <tr style={styles.tr}>
            <td align="center">3</td>
            <td align="center">10-14</td>
            <td align="center">
              <input
                style={styles.input}
                value={p3 || ''}
                type="number"
                onChange={createChangeHandler(setP3)}
              />
            </td>
            <td align="center">{format(ps3)}</td>
            <td align="center">{format(ls3)}</td>
            <td align="center">{format(pt3)}</td>
          </tr>
          <tr style={styles.tr}>
            <td align="center">4</td>
            <td align="center">15-19</td>
            <td align="center">
              <input
                style={styles.input}
                value={p4 || ''}
                type="number"
                onChange={createChangeHandler(setP4)}
              />
            </td>
            <td align="center">{format(ps4)}</td>
            <td align="center">{format(ls4)}</td>
            <td align="center">{format(pt4)}</td>
          </tr>
          <tr style={styles.tr}>
            <td align="center">5</td>
            <td align="center">20-24</td>
            <td align="center">
              <input
                style={styles.input}
                value={p5 || ''}
                type="number"
                onChange={createChangeHandler(setP5)}
              />
            </td>
            <td align="center">{format(ps5)}</td>
            <td align="center">{format(ls5)}</td>
            <td align="center">{format(pt5)}</td>
          </tr>
          <tr style={styles.tr}>
            <td align="center">6</td>
            <td align="center">25-39</td>
            <td align="center">
              <input
                style={styles.input}
                value={p6 || ''}
                type="number"
                onChange={createChangeHandler(setP6)}
              />
            </td>
            <td align="center">{format(ps6)}</td>
            <td align="center">{format(ls6)}</td>
            <td align="center">{format(pt6)}</td>
          </tr>
          <tr style={styles.tr}>
            <td align="center">7</td>
            <td align="center">40+</td>
            <td align="center">
              <input
                style={styles.input}
                value={p7 || ''}
                type="number"
                onChange={createChangeHandler(setP7)}
              />
            </td>
            <td align="center">{format(ps7)}</td>
            <td align="center">{format(ls7)}</td>
            <td align="center">{format(pt7)}</td>
          </tr>
          <tr style={styles.tr}>
            <td align="center"> </td>
            <td
              align="right"
              style={{
                backgroundColor: 'white',
                color: 'black',
                borderRadius: '5px 0 0 5px',
              }}
            >
              Annual Totals:
            </td>
            <td
              align="center"
              style={{
                backgroundColor: 'white',
                color: 'black',
              }}
            >
              {p1 + p2 + p3 + p4 + p5 + p6 + p7}
            </td>
            <td
              align="center"
              style={{
                backgroundColor: 'white',
                color: 'black',
              }}
            >
              {format(ps2 + ps3 + ps4 + ps5 + ps6 + ps7)}
            </td>
            <td
              align="center"
              style={{
                backgroundColor: 'white',
                color: 'black',
              }}
            >
              {format(ls1 + ls2 + ls3 + ls4 + ls5 + ls6 + ls7)}
            </td>
            <td
              align="center"
              style={{
                backgroundColor: 'white',
                color: 'black',
                borderRadius: '0 5px 5px 0',
              }}
            >
              {format(pt1 + pt2 + pt3 + pt4 + pt5 + pt6 + pt7)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
