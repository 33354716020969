import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useSWR from 'swr';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import useUser from '../lib/useUser';
import Layout from '../components/layout';
import DownloadButton from '../components/download-csv';
import data from '../lib/riskProfileData';
import { colors } from '../lib/styles';
import RiskProfileSearch from '../components/risk-profile-search';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  search: {
    marginBottom: '24px',
    marginTop: '24px',
    fontSize: 18,
    borderRadius: '4px',
    width: '100%',
    height: '30px',
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'firstname',
    headerName: 'First',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last',
    align: 'left',
    width: 150,
  },
  {
    field: 'email',
    headerName: 'Email',
    align: 'left',
    width: 200,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    align: 'left',
    width: 150,
  },
  {
    field: 'score',
    headerName: 'Score',
    align: 'left',
    width: 75,
  },
  {
    field: 'default_model',
    headerName: 'Default Model',
    align: 'left',
    width: 150,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return <span>{params.row.created_at ? moment(params.row.created_at).format('YYYY-MM-DD') : ''}</span>;
    },
  },
  {
    field: 'details',
    headerName: 'Details',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return <Link to={`/risk_profile_answers/${params.row.id}`} style={{ color: colors.darkAccent }}>Details</Link>;
    },
  },
];

const RiskProfileAnswers = () => {
  const classes = useStyles();
  const history = useHistory();
  const [err] = useState(null);
  const user = useUser();
  const path = `/users/${user.id}/risk_profiles?orderBy=created_at`;
  const { data: profiles, loading: profilesLoading } = useSWR(path);
  let cleanprofiles = [];
  if (profiles) {
    cleanprofiles = profiles.map((p) => {
      p.score = data.calcScore(p);
      p.default_model = data.getModel(p.score);
      return p;
    });
  }

  return (
    <Layout>
      <div>{err?.toString()}</div>
      <div>
        <Typography variant="h6">
          Risk Profile Questionnaire Submissions
        </Typography>
      </div>
      <Grid container>
        <Grid item xs={6}>
          <div className="search">
            <RiskProfileSearch
              onChange={(c) => {
                history.push(`/risk_profile_answers/${c.id}`);
              }}
            />
          </div>
        </Grid>
      </Grid>
      <Paper style={{ marginTop: '15px' }}>
        {!profilesLoading ? (
          <div>
            <div>
              <DownloadButton rows={cleanprofiles} name="profiles"/>
            </div>
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={cleanprofiles} />
            </div>
          </div>
        ) : <CircularProgress/>}
      </Paper>
    </Layout>
  );
};

export default RiskProfileAnswers;
