import React, { useState } from 'react';

import { Box, MenuItem } from '@mui/material';
import DeleteButton from './delete-button';
import { MaskInput, Select } from '../lib/common';
import { formatCurrency } from '../lib/util';

const ClientIncomeForm = (props) => {
  const {
    update,
    income,
    incomeSources,
  } = props;

  const [annual, setAnnual] = useState(formatCurrency(income.annual));
  const [gross, setGross] = useState(income.gross);
  const [source, setSource] = useState(income.source);
  let monthly = 0;

  if (income?.annual) {
    monthly = (income.annual / 12).toFixed(2);
  }

  const handleTextChange = (e, setter) => {
    const { name, value } = e.target || e;

    const data = {
      annual,
      gross,
      source,
    };

    update({ ...income, ...data, [name]: value });
    setter(value);
  };

  const handleDelete = () => {
    income.to_delete = true;
    update(income);
  };

  return (
    <Box display="grid" gridTemplateColumns="repeat(15, 1fr)" gap={2} minWidth={985}>
      <Box gridColumn="span 3">
        <Select
          label="Source"
          value={source}
          name="source"
          onChange={(e) => handleTextChange(e, setSource)}
        >
          {incomeSources.sort((a, b) => a.order_val - b.order_val).map((i) => {
            return (<MenuItem value={Number(i.id)} key={i.id}>{i.name}</MenuItem>);
          })}
        </Select>
      </Box>
      <Box gridColumn="span 3">
        <Select
          label="Gross/Net"
          value={gross}
          name="gross"
          onChange={(e) => handleTextChange(e, setGross)}
        >
          <MenuItem value={true}>Gross</MenuItem>
          <MenuItem value={false}>Net</MenuItem>
        </Select>
      </Box>
      <Box gridColumn="span 3">
        <MaskInput
          mask_type="currency"
          id="annual"
          label="Annual Income"
          name="annual"
          value={annual}
          onChange={(e) => handleTextChange(e, setAnnual)}
        />
      </Box>
      <Box gridColumn="span 3">
        <MaskInput
          mask_type="currency"
          label="Monthly Income"
          id="monthly"
          name="monthly"
          value={monthly}
          disabled
          sx={{ border: 'none' }}
        />
      </Box>
      <Box gridColumn="span 3" sx={{ display: 'flex', alignItems: 'center' }}>
        <DeleteButton handleDelete={handleDelete}/>
      </Box>
    </Box>
  );
};

export default ClientIncomeForm;
