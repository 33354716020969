import React, { useState } from 'react';
import { Snackbar } from '@mui/material';

import { Button, GridForm } from '../lib/common';
import ClientTermInsuranceForm from './client-term-insurance-form';

const TermInsurance = (props) => {
  const { client } = props;
  const [term_insurance, setTermInsurance] = useState(client.records.termInsurance);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const snackMessage = (
    <span>
      ALERT: Cash Value Insurance can be listed and found in the &quot;Tax Free&quot; Bucket under the Assets section.
    </span>
  );

  function newTermInsurance() {
    return {
      company_name: '',
      carrier_id: '',
      type: '',
      issue_date: null,
      policy_length: '',
      death_amount: null,
      premium_amount: null,
      frequency: '',
      accum_amount: null,
      cash_amount: null,
      interest_rate: null,
      current_loan_rate: null,
      fixed: '',
      loan_cap: null,
      surrender_schedule: null,
      surrender_value: null,
      total_surrender: null,
      living_benefits: '',
      contract_fees: '',
      subject_to_losses: '',
      isNew: true,
    };
  }

  return (
    <GridForm>
      {term_insurance.filter((policy) => !policy.to_delete).map((policy, idx) => {
        return (
          <div key={idx}>
            <ClientTermInsuranceForm
              idx={idx}
              client={client}
              term_insurance={policy}
              isNew={client.isNew}
              carriers={props.carriers}
              termInsuranceTypes={props.termInsuranceTypes}
              update={(record) => {
                term_insurance[idx] = record;
                client.records.termInsurance = term_insurance.slice();
                setTermInsurance(client.records.termInsurance);
              }}
            />
          </div>
        );
      })}
      <Button
        householdadd
        onClick={() => {
          const newPolicy = newTermInsurance();
          term_insurance.push(newPolicy);
          client.records.termInsurance = term_insurance.slice();
          setTermInsurance(client.records.termInsurance);
          setSnackbarOpen(true);
        }}
        style={{ margin: '15px' }}
      >
        Add Insurance
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        message={snackMessage}
      />
    </GridForm>
  );
};

export default TermInsurance;
