import React, { useState } from 'react';
import useSWR from 'swr';
import { useHistory, Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';

import { colors } from '../lib/styles';
import DownloadButton from '../components/download-csv';
import useUser from '../lib/useUser';
import Layout from '../components/layout';
import { Button, Select } from '../lib/common';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  w200: {
    width: '200px',
    margin: '10px',
  },
  downloadBtn: {
    marginTop: '10px',
    marginBottom: '10px',
    display: 'inline-flex',
    alignItems: 'center',
    height: '50px',
  },
  submitBtn: {
    margin: '15px',
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: '#',
    align: 'left',
    width: 150,
  },
  {
    field: 'household_name',
    headerName: 'Household',
    align: 'left',
    width: 150,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'age',
    headerName: 'Age',
    align: 'left',
    width: 60,
    renderCell: (params) => {
      return params.row.dob ? moment().diff(params.row.dob, 'years', false) : '';
    },
  },
  {
    field: 'dob',
    headerName: 'DOB',
    align: 'left',
    width: 120,
    renderCell: (params) => {
      return params.row.dob ? moment(params.row.dob).format('YYYY-MM-DD') : '';
    },
  },
  {
    field: 'details',
    headerName: 'Detail',
    align: 'left',
    width: 80,
    renderCell: (params) => {
      const url = `/household/${params.row.household_id}/client/${params.row.id}`;
      return <Link style={{ color: colors.black }} to={url}>Details</Link>;
    },
  },
  {
    field: 'permission',
    headerName: 'Permissions',
    align: 'left',
    width: 100,
  },
];

const Clients = () => {
  const history = useHistory();
  const classes = useStyles();
  const [err] = useState(null);
  const [share, setShare] = useState('all');
  const userId = useUser()?.id;

  let url = `/users/${userId}/clients/all?is_deleted=false&orderBy=created_at&limit=5000`;
  if (share !== 'all') {
    url += `&owner=${share}`;
  }

  const { data: bsf, loading: bsfLoading } = useSWR(url);

  return (
    <Layout>
      <div style={classes.mainBody}>
        <div>{err?.toString()}</div>
        <Button
          addIcon
          gold
          className={classes.submitBtn}
          onClick={() => {
            history.push('/household/new');
          }}
        >
          Add Household
        </Button>
        {bsf && bsf.length ? (
          <FormControl required className={classes.downloadBtn} >
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <DownloadButton rows={bsf} name="Clients"/>
            </div>
          </FormControl>
        ) : ''}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: 10,
          }}
        >
          <Select
            style={{ width: '200px' }}
            label="Permissions"
            value={share}
            name="permissions"
            onChange={(evt) => {
              setShare(evt.target.value);
            }}
          >
            <MenuItem value="all"> All</MenuItem>
            <MenuItem value="true">Owner</MenuItem>
            <MenuItem value="false">Shared</MenuItem>
          </Select>
        </div>
        <Paper>
          {!bsfLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={bsf || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </Layout>
  );
};

export default Clients;
