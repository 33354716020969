import React from 'react';
import UnderConstruction from '../components/under-construction';

export default function Training() {
  return (
    <>
      <UnderConstruction />
    </>
  );
}
