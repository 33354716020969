import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';

import Layout from '../components/layout';
import AgentForm from '../components/agent-settings-form';
import Billing from '../components/billing';
import Contracting from '../components/contracting';
import Licensing from '../components/licensing';
import { colors } from '../lib/styles';

const SIDE_NAV_WIDTH = 180;

const useStyles = makeStyles({
  sideNav: {
    backgroundColor: colors.lightGrey,
    display: 'flex',
    flexDirection: 'column',
    // position: 'fixed',
    // zIndex: 100,
    top: 90,
    textAlign: 'center',
    height: 'calc(100vh - 85px)',
    width: SIDE_NAV_WIDTH,
    borderRight: `1px solid ${colors.blackOpacity}`,
    overflowY: 'scroll',
    paddingBottom: 25,
    minWidth: 100,
  },
  btnText: {
    color: colors.black,
    fontSize: 14,
  },
  hashLink: {
    fontFamily: 'Maven Pro, Roboto, Circular Std, sans-serif',
    textAlign: 'start',
    padding: '12px',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
  },
});

const Settings = () => {
  const classes = useStyles();
  const [nav, setNav] = useState('personal-info');

  function NavItem(props) {
    const { navIndex, name } = props;
    const textStyle = {};
    if (nav === navIndex) {
      textStyle.fontWeight = 'bold';
    }
    return (
      <button
        type="button"
        onClick={() => setNav(navIndex)}
        className={classes.hashLink}
      >
        <span
          className={classes.btnText}
          style={textStyle}
        >
          {name}
        </span>
      </button>
    );
  }

  return (
    <Layout>
      <div style={{ display: 'flex', backgroudColor: 'white' }}>
        <div className={classes.sideNav} >
          <NavItem name="Personal Info" navIndex={'personal-info'}/>
          <NavItem name="Billing" navIndex={'billing'}/>
          <NavItem name="Licensing" navIndex={'licensing'}/>
          <NavItem name="Contracting" navIndex={'contracting'}/>
        </div>
        <Paper style={{ width: '100%' }}>
          <div
            style={{ display: nav === 'personal-info' ? '' : 'none' }}
          >
            <AgentForm/>
          </div>
          <div
            style={{ display: nav === 'billing' ? '' : 'none' }}
          >
            <Billing/>
          </div>
          <div
            style={{ display: nav === 'licensing' ? '' : 'none' }}
          >
            <Licensing/>
          </div>
          <div
            style={{ display: nav === 'contracting' ? '' : 'none' }}
          >
            <Contracting/>
          </div>
        </Paper>
      </div>
    </Layout>
  );
};

export default Settings;
