import React, { useState } from 'react';
import { Button } from '../lib/common';

import ClientEmploymentForm from './client-employment-form';
import { newEmployment } from '../lib/initializers';
import { colors } from '../lib/styles';

const ClientEmployment = (props) => {
  const {
    client,
    employmentStatusTypes,
    employmentOccupationTypes,
    scpErrors,
  } = props;
  const [employment, setEmployment] = useState(client.records.employment);

  return (
    <div>
      <div>
        {employment.filter((policy) => !policy.to_delete).map((policy, idx) => {
          return (
            <div key={idx}>
              <ClientEmploymentForm
                idx={idx}
                client={client}
                employment={policy}
                employmentStatusTypes={employmentStatusTypes}
                employmentOccupationTypes={employmentOccupationTypes}
                scpErrors={scpErrors && scpErrors.length && scpErrors.find((err) => err.id === policy.id)}
                update={(record) => {
                  if (record.id === 'new') {
                    delete record.id;
                  }
                  employment[idx] = record;
                  client.records.employment = employment.slice();
                  setEmployment(client.records.employment);
                }}
              />
            </div>
          );
        })}
        <Button
          householdadd
          onClick={() => {
            const record = newEmployment();
            employment.push(record);
            client.records.employment = employment.slice();
            setEmployment(client.records.employment);
          }}
          style={{ margin: '15px', color: colors.primaryColor }}
        >
          Add Employer
        </Button>
      </div>
    </div>
  );
};

export default ClientEmployment;
