import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import FolderIcon from '@mui/icons-material/Folder';
import Collapse from '@mui/material/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Button } from '../lib/common';
import { apiFetch } from '../lib/fetch';

import useUser from '../lib/useUser';
import { FolderItem } from './file-tree';
import { getSocket } from '../lib/websocket';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    padding: '5px',
    flexDirection: 'column',
  },
  fileBox: {
    width: '100%',
    margin: '5px',
    border: '1px solid black',
    borderRadius: '3px',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  emptyGridContainer: {
    width: '100%',
    display: 'flex',
    height: 50,
    padding: 10,
  },
  submitBtn: {
    margin: '15px',
  },
  fileHeader: {
    display: 'flex',
    fontSize: '1.1rem',
    alignItems: 'center',
    cursor: 'pointer',
  },
  errorText: {
    color: 'red',
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
});

const DocList = (props) => {
  const {
    client_visible,
    user,
    household,
  } = props;
  const classes = useStyles();
  // const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const folderMaps = {};
  const rootFileMap = {};
  const allFileMap = {};

  const rootFiles = household.clients.map((c) => {
    const rf = {
      name: c.firstname + ' ' + c.lastname,
      id: c.id,
      nodeId: 'n' + c.id,
      children: [],
      folder: true,
      rootFolder: true,
      files: [],
      client_id: c.id,
    };
    folderMaps[c.id] = {
      n0: rf,
    };
    rootFileMap[c.id] = rf;
    return rf;
  });

  files.forEach((f) => {
    f.nodeId = 'n' + f.id;
    if (f.folder) {
      f.children = [];
      // folderMap['n' + f.id] = f;
      if (folderMaps[f.client]) {
        folderMaps[f.client]['n' + f.id] = f;
      }
      f.files = [];
    }
    allFileMap[f.nodeId] = f;
  });

  files.forEach((f) => {
    // const parent = folderMap['n' + f.parent_id];
    const folderMap = folderMaps[f.client];
    const parent = folderMap ? folderMap['n' + f.parent_id] : null;
    if (f.folder && parent && parent.folder) {
      parent.children.push(f);
    } else if (!parent && f.folder) {
      if (rootFileMap[f.client] && rootFileMap[f.client].children) {
        rootFileMap[f.client].children.push(f);
      }
    } else if (parent && !f.folder) {
      parent.files.push(f);
    } else if (!parent && !f.folder) {
      if (rootFileMap[f.client] && rootFileMap[f.client].files) {
        rootFileMap[f.client].files.push(f);
      }
    }
  });

  async function saveFolder(name, parent_id, clientId) {
    try {
      // const path = `/users/${user.id}/households/${household.id}/folders`;
      setError(null);
      const path = `/users/${user.id}/clients/${clientId}/folders`;
      const toSave = {
        name,
        client_visible,
      };
      if (parent_id) {
        toSave.parent_id = parent_id;
      }
      const newFile = await apiFetch.post(path, toSave);
      const filesClone = files.slice(0);
      filesClone.push(newFile);
      setFiles(filesClone);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      setError(e.message);
    }
  }

  async function delFile(fileId, clientId) {
    try {
      setError(null);
      const path = `/users/${user.id}/clients/${clientId}/files/${fileId}`;
      // const path = `/users/${user.id}/households/${household.id}/files/${fileId}`;
      await apiFetch.del(path);
      const filesClone = [];
      files.forEach((f) => {
        if (f.id !== fileId) {
          filesClone.push(f);
        }
      });
      setFiles(filesClone);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      setError(e.message);
    }
  }

  async function renameFolder(fileId, clientId, newName) {
    try {
      setError(null);
      const path = `/users/${user.id}/clients/${clientId}/files/${fileId}`;
      // const path = `/users/${user.id}/households/${household.id}/files/${fileId}`;
      await apiFetch.put(path, { name: newName });
      const filesClone = [];
      files.forEach((f) => {
        if (f.id === fileId) {
          f.name = newName;
        }
        filesClone.push(f);
      });
      setFiles(filesClone);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      setError(e.message);
    }
  }

  useEffect(async () => {
    const path = `/users/${user.id}/households/${household.id}/clients/files?client_visible=${client_visible}`;
    getSocket();
    const savedFiles = await apiFetch(path);
    setFiles(savedFiles);
  }, []);

  return (
    <Collapse in={show} collapsedSize={45} className={classes.fileBox}>
      {error ? (
        <div className={classes.errorText}>{String(error)}</div>
      ) : ''}
      <div
        className={classes.fileHeader}
        onClick={() => setShow(!show)}
        style={{ fontWeight: 'bolder' }}
      >
        {props.label}
        <Button variant="">{show ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Button>
      </div>
      <div>
        <div
          style={{ width: '100%' }}
        >
          {rootFiles.map((rf) => (
            <FolderItem
              client_visible={client_visible}
              depth={1}
              key={rf.id}
              record={rf}
              nodeId={rf.nodeId}
              household={household}
              basePath={`users/${user.id}/clients/${rf.client_id}/files`}
              client_id={rf.client_id}
              labelIcon={<FolderIcon/>}
              renameFunction={(fileId, clientId, name) => {
                renameFolder(fileId, clientId, name);
              }}
              handleDelete={(id, clientId) => {
                delFile(id, clientId);
              }}
              saveFolder={(name, parent_id, clientId) => {
                saveFolder(name, parent_id, clientId);
              }}
              renameFolder={(fileId, clientId, name) => {
                renameFolder(fileId, clientId, name);
              }}
              deleteFolder={(fileId, clientId) => {
                delFile(fileId, clientId);
              }}
              handleFileAdded={(newFile) => {
                const filesClone = files.slice(0);
                filesClone.push(newFile);
                setFiles(filesClone);
              }}
              setError={setError}
            />
          ))}
        </div>
      </div>
    </Collapse>
  );
};

const ClientFiles = (props) => {
  const { household } = props;
  const classes = useStyles();
  const user = useUser();

  return (
    <>
      <Paper>
        <div className={classes.gridContainer}>
          <DocList
            household={household}
            user={user}
            client_visible={false}
            label="Agent Only Viewing Rights(No Client Access)"
          />
          <DocList
            household={household}
            user={user}
            client_visible={true}
            label={'Client Has Viewing Rights via "Vault"'}
          />
        </div>
      </Paper>
    </>
  );
};

export default ClientFiles;
