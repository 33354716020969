import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  TextField, Button, Card, CardHeader, Typography,
} from '@mui/material';
import { get } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { updatePassword } from '../state/user';
import { colors, formButton } from '../lib/styles';
import Layout from '../components/layout';

const styles = {
  container: {
    backgroundAttachment: 'scroll',
    backgroundRepeat: 'repeat',
    height: '100%',
    minHeight: '900px',
    padding: '10px',
  },
  cardHeader: {
    color: colors.primaryText,
    fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
  },
  loginBox: {
    margin: '0 auto 100px',
    textAlign: 'center',
    width: '500px',
    backgroundColor: colors.blackOpacity,
  },
  errorText: {
    color: colors.secondaryAccent,
    height: '18px',
    paddingTop: '18px',
  },
  form: {
    margin: 'auto',
    padding: '35px 50px 50px',
  },
  textField: {
    margin: '10px',
    '& label.Mui-focused': {
      color: colors.lightGrey,
    },
    '& .MuiInputLabel-root': {
      color: colors.lightGrey,
    },
    '& .MuiOutlinedInput-input': {
      color: colors.lightGrey,
    },
    '& .MuiOutlinedInput-root': {
      '& > fieldset': {
        borderColor: colors.lightGrey,
        '& .Mui-focused': {
          color: colors.lightGrey,
        },
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      color: colors.lightGrey,
      '& > fieldset': {
        borderColor: colors.lightGrey,
        color: colors.lightGrey,
      },
    },
    '& .MuiOutlinedInput-root:hover': {
      '& > fieldset': {
        borderColor: colors.lightGrey,
        color: colors.lightGrey,
      },
    },
  },

};

const UpdatePassword = (props) => {
  const formik = useFormik({
    initialValues: {
      confirmPassword: '',
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .max(10000, 'Must be 10000 characters or less')
        .min(8, 'Must be 8 characters or more')
        .required('Required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: (values) => {
      const { confirmPassword, password } = values;
      props.updatePassword(props.match.params.token, password, confirmPassword)
        .then((res) => {
          document.location.href = '/agent-dashboard';
          return res;
        });
    },
  });

  const { user } = props;

  if (!user.updatePassword) {
    const propErrors = {};
    let errorMsg = '';
    if (user.updatePasswordError && user.updatePasswordError.details) {
      user.updatePasswordError.details.forEach((detail) => {
        propErrors[detail.path] = detail.message;
      });
    } else if (user.updatePasswordError) {
      // TODO fetch can get better errors
      // eslint-disable-next-line
      errorMsg = get(user.updatePasswordError, 'content.message') || get(user.updatePasswordError, 'content.error') || user.updatePasswordError.message;
    }

    return (
      <Layout>
        <div style={styles.container} >
          <Card style={styles.loginBox}>
            <CardHeader style={styles.cardHeader} title={<Typography variant="h5">Update your password</Typography>} />
            <div style={{ ...styles.errorText, display: errorMsg ? '' : 'none' }}>{errorMsg}</div>
            <form onSubmit={formik.handleSubmit} style={styles.form}>
              <TextField
                className="login-field"
                label="Password"
                fullWidth={true}
                error={formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                value={formik.values.password}
                onChange={formik.handleChange}
                name="password"
                type="password"
                sx={styles.textField}
              />
              <TextField
                className="login-field"
                label="Confirm Password"
                fullWidth={true}
                error={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                name="confirmPassword"
                type="password"
                sx={styles.textField}
              />
              <Button
                variant="contained"
                color="primary"
                style={formButton}
                type="submit"
              >
                Update Password
              </Button>
            </form>
          </Card>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div style={styles.container} >
        <Card style={styles.loginBox}>
          <span>Your password has been reset.</span>
          <Link to="/app">
            <Button
              variant="contained"
              color="primary"
            >
              Login
            </Button>
          </Link>
        </Card>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  const { user } = state;

  return { user };
}

export default connect(mapStateToProps, { updatePassword })(UpdatePassword);
