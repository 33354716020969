import React, { useState } from 'react';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import UserSearch from '../components/user-search';
import states from '../lib/states';
import useUser from '../lib/useUser';

import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';
import Layout from '../components/layout';
import { Button } from '../lib/common';

const useStyles = makeStyles({
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px auto',
    minWidth: '300px',
    width: '80%',
  },
  orgs: {
    margin: '10px auto',
    minWidth: '350px',
    width: '100%',
  },
  tintedBackground: {
    padding: '5px',
    backgroundColor: colors.blackOpacity,
  },
  form: {
    width: '80%',
    margin: 'auto',
  },
  saveBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },
  w200: {
    width: '200px',
  },
  w300: {
    width: '300px',
  },
  splitBad: {
    color: 'red',
  },
  splitsContainer: {
    fontSize: '1.5em',
  },
  writingAgent: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  radioLabel: {
    color: colors.primaryText,
  },
  targets: {
    margin: '15px',
  },
});

const createChangeHandler = (setter) => {
  return (event) => {
    setter(event.target.value);
  };
};

const BSFs = () => {
  const history = useHistory();
  const classes = useStyles();
  const user = useUser();
  const [err, setErr] = useState(null);
  const [submitted_at, setSubmitted_at] = useState(null);
  const [client_name, setClient_name] = useState('');
  const [client_dob, setClient_dob] = useState(null);
  const [client_ssn, setClient_ssn] = useState('');
  const [custodian_id, setCustodian_id] = useState(null);
  const [capital_type_id, setCapital_type_id] = useState(null);
  const [state, setState] = useState();

  const [product_name] = useState();
  const [policy_number, setPolicy_number] = useState();
  const [source_id, setSource_id] = useState('');
  const [first_distribution] = useState();
  const [life_target, setLife_target] = useState();
  const [life_annual] = useState();
  const [joint, setJoint] = useState(false);
  const [personal] = useState(false);
  const [writing_agent_split, setWriting_agent_split] = useState(100);
  const [agent2, setAgent2] = useState();
  const [agent3, setAgent3] = useState();
  const [agent4, setAgent4] = useState();
  const [agent2Split, setAgent2Split] = useState('');
  const [agent3Split, setAgent3Split] = useState('');
  const [agent4Split, setAgent4Split] = useState('');
  const [saving, setSaving] = useState(false);

  let totalSplits = Number(writing_agent_split);
  if (agent2) {
    totalSplits += Number(agent2Split);
  }
  if (agent3) {
    totalSplits += Number(agent3Split);
  }
  if (agent4) {
    totalSplits += Number(agent4Split);
  }

  const validSplits = () => {
    if (!joint) {
      return true;
    }
    if (!writing_agent_split) {
      return false;
    }
    if (!agent2 && !agent3 && !agent4) {
      return false;
    }
    if (agent2 && !Number(agent2Split)) {
      return false;
    }
    if (agent3 && !Number(agent3Split)) {
      return false;
    }
    if (agent4 && !Number(agent4Split)) {
      return false;
    }
    return totalSplits === 100;
  };

  const handleSubmit = async () => {
    setErr('');
    setSaving(true);

    const data = {
      writing_agent: user.id,
      submitted_at,
      client_name,
      client_dob,
      client_ssn,
      custodian_id,
      capital_type_id,
      product_name,
      policy_number,
      first_distribution,
      state,
      source_id,
      life_target: Number(life_target) || undefined,
      life_annual: Number(life_annual) || undefined,
      joint,
      personal,
      writing_agent_split,
      splits: [],
    };

    if (!joint) {
      data.writing_agent_split = 100;
    } else {
      if (agent2) {
        data.splits.push({ agent: agent2.id, split: agent2Split });
      }
      if (agent3) {
        data.splits.push({ agent: agent3.id, split: agent3Split });
      }
      if (agent4) {
        data.splits.push({ agent: agent4.id, split: agent4Split });
      }
    }

    try {
      await apiFetch.post('/bsf', data);
      history.push('/bsfs');
    } catch (e) {
      setErr(e.toString());
      setSaving(false);
    }
  };

  const disabled = !submitted_at
  || !client_name
  || !client_dob
  || !client_ssn
  || !custodian_id
  || !capital_type_id
  || !product_name
  || !policy_number
  || !source_id
  || (!life_target && !life_annual)
  || !validSplits()
  || saving;

  const { data: custodians } = useSWR('/custodians?orderBy=name&sortOrder=asc');
  const { data: capital_types } = useSWR('/capital_types?orderBy=name&sortOrder=asc');
  const { data: sources } = useSWR('/sources?orderBy=name&sortOrder=asc');

  return (
    <Layout>
      <div style={classes.mainBody}>
        <div>{err?.toString()}</div>
        <Paper className={classes.tintedBackground}>
          {custodians && sources && capital_types ? (
            <form style={{ color: colors.primaryText }} className={classes.form}>
              <div className={classes.writingAgent}>
                <Typography variant="h5">Stronghold Capital</Typography>
              </div>
              <div className={classes.writingAgent}>
                <Typography variant="h5">Writing Agent: {user.email}</Typography>
              </div>
              <Box
                fullWidth
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(1, 1fr)',
                  gridTemplateRows: 'auto',
                  gridTemplateAreas: `
                  "header header header header"
                  "row1 row1 row1 row1"
                  "row2 row2 row2 row2"
                  "row3 row3 row3 row3"
                  "row4 row4 row4 row4"
                  "row5 row5 row5 row5"
                `,
                }}
              >
                <Box sx={{
                  gridArea: 'row1',
                  display: 'flex',
                  gap: 4,
                  marginBottom: 4,
                }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Submitted On"
                      value={submitted_at}
                      maxDate={new Date()}
                      name="submitted_at"
                      onChange={setSubmitted_at}
                      renderInput={(params) => <TextField className={classes.w200} {...params} />}
                    />
                  </LocalizationProvider>
                  <TextField
                    label={'Client\'s Name'}
                    defaultValue=""
                    value={client_name}
                    name="client_name"
                    onChange={createChangeHandler(setClient_name)}
                    sx={{ flexGrow: 1 }}
                  />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      openTo="year"
                      views={['year', 'month', 'day']}
                      label={'Client\'s DOB'}
                      value={client_dob}
                      maxDate={new Date()}
                      name="client_dob"
                      onChange={setClient_dob}
                      renderInput={(params) => <TextField className={classes.w200} {...params} />}
                      sx={{ flexGrow: 1 }}
                    />
                  </LocalizationProvider>
                  <TextField
                    className={classes.w200}
                    label={'Client\'s SSN (Last 4)'}
                    defaultValue=""
                    value={client_ssn}
                    maxLength={4}
                    name="client_ssn"
                    onChange={createChangeHandler(setClient_ssn)}
                    inputProps={{ type: 'numeric', inputMode: 'tel', maxLength: 4 }}
                  />
                </Box>
                <Box sx={{
                  gridArea: 'row2',
                  display: 'flex',
                  gap: 2,
                  marginBottom: 4,
                }}
                >
                  <FormControl className={classes.w300} >
                    <TextField
                      select
                      labelId="demo-simple-select-label"
                      label="Custodian"
                      value={custodian_id}
                      name="custodian_id"
                      onChange={createChangeHandler(setCustodian_id)}
                    >
                      {custodians
                        .filter((custodian) => custodian.active === true)
                        .sort((a, b) => a.order_val - b.order_val)
                        .map((custodian) => {
                          return <MenuItem value={custodian.id} key={custodian.id}>{custodian.name}</MenuItem>;
                        })}
                    </TextField>
                  </FormControl>
                  <FormControl required className={classes.w200} >
                    <TextField
                      labelId="demo-simple-select-label"
                      label="Client's State"
                      value={state}
                      name="state"
                      onChange={createChangeHandler(setState)}
                      select
                    >
                      {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
                    </TextField>
                  </FormControl>
                </Box>
                <Box sx={{
                  gridArea: 'row3',
                  display: 'flex',
                  gap: 2,
                  marginBottom: 4,
                }}
                >
                  <TextField
                    label="Account Number"
                    defaultValue=""
                    value={policy_number}
                    name="policy_number"
                    onChange={createChangeHandler(setPolicy_number)}
                    sx={{ flexGrow: 1 }}
                  />
                  <FormControl className={classes.w200} >
                    <TextField
                      select
                      labelId="demo-simple-select-label"
                      label="Source"
                      value={source_id}
                      name="source_id"
                      onChange={createChangeHandler(setSource_id)}
                    >
                      {sources
                        .filter((source) => source.active === true)
                        .sort((a, b) => a.order_val - b.order_val)
                        .map((source) => <MenuItem value={source.id} key={source.id}>{source.name}</MenuItem>)}
                    </TextField>
                  </FormControl>
                </Box>
                <Box sx={{
                  gridArea: 'row5',
                  display: 'flex',
                  gap: 2,
                  marginBottom: 4,
                }}
                >
                  <TextField
                    disabled={life_annual}
                    label="Assets under management (aum)"
                    defaultValue=""
                    type="number"
                    value={life_target}
                    name="life_target"
                    onChange={createChangeHandler(setLife_target)}
                    sx={{ flexGrow: 1 }}
                  />
                  <FormControl className={classes.w300} >
                    <TextField
                      select
                      labelId="demo-simple-select-label"
                      label="Capital Type"
                      value={capital_type_id}
                      name="capital_type_id"
                      onChange={createChangeHandler(setCapital_type_id)}
                    >
                      {capital_types
                        .filter((capital_type) => capital_type.active === true)
                        .sort((a, b) => a.order_val - b.order_val)
                        .map((capital_type) => {
                          return <MenuItem value={capital_type.id} key={capital_type.id}>{capital_type.name}</MenuItem>;
                        })}
                    </TextField>
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(5, 1fr)',
                  gap: 1,
                  gridTemplateRows: 'auto',
                  gridTemplateAreas: `
                  "header header header header header"
                  ". row1 row1 row1 row1"
                  ". row2 row2 row2 row2"
                  ". row3 row3 row3 row3"
                  ". row4 row4 row4 row4"
                  ". row5 row5 row5 row5"
                  ". row6 row6 row6 row6"
                `,
                }}
              >
                <Box
                  sx={{
                    gridArea: 'row1',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="subtitle1">Is this a joint work case?*</Typography>
                  <RadioGroup
                    aria-label="commission-split"
                    name="joint"
                    value={joint}
                    onChange={(evt) => {
                      const isJoint = evt.target.value === 'true';
                      setWriting_agent_split(isJoint ? 0 : 100);
                      setJoint(isJoint);
                    }}
                    row
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                  </RadioGroup>
                </Box>
                {!joint ? '' : (
                  <>
                    <Box sx={{
                      gridArea: 'row2',
                      display: 'flex',
                      gap: 2,
                      marginBottom: 4,
                    }}
                    >
                      <div className={classes.splitsContainer}>
                        <Typography variant="h6">Total Splits = </Typography>
                        <Typography variant="h6" className={validSplits() ? '' : classes.splitBad}>
                          {totalSplits}%
                        </Typography>
                      </div>
                    </Box>
                    <Box sx={{
                      gridArea: 'row3',
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 2,
                      marginBottom: 4,
                    }}
                    >
                      <TextField
                        disabled
                        label={user.email}
                        sx={{ flexGrow: 1 }}
                      />
                      <TextField
                        label="Split percentage"
                        type="number"
                        value={writing_agent_split}
                        className={classes.w200}
                        onChange={createChangeHandler(setWriting_agent_split)}
                      />
                    </Box>
                    <Box sx={{
                      gridArea: 'row4',
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 2,
                      marginBottom: 4,
                    }}
                    >
                      <UserSearch
                        defaultValue=""
                        ignore={[user.id]}
                        value={agent2}
                        onChange={setAgent2}
                      />
                      <TextField
                        label="Split percentage"
                        type="number"
                        value={agent2Split}
                        className={classes.w200}
                        onChange={createChangeHandler(setAgent2Split)}
                      />
                    </Box>
                    <Box sx={{
                      gridArea: 'row5',
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 2,
                      marginBottom: 4,
                    }}
                    >
                      <UserSearch
                        defaultValue=""
                        ignore={[user.id]}
                        value={agent3}
                        onChange={setAgent3}
                      />
                      <TextField
                        label="Split percentage"
                        type="number"
                        value={agent3Split}
                        className={classes.w200}
                        onChange={createChangeHandler(setAgent3Split)}
                      />
                    </Box>
                    <Box sx={{
                      gridArea: 'row6',
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 2,
                      marginBottom: 4,
                    }}
                    >
                      <UserSearch
                        defaultValue=""
                        ignore={[user.id]}
                        value={agent4}
                        onChange={setAgent4}
                      />
                      <TextField
                        label="Split percentage"
                        type="number"
                        value={agent4Split}
                        className={classes.w200}
                        onChange={createChangeHandler(setAgent4Split)}
                      />
                    </Box>
                  </>
                )}
              </Box>
              <div className={classes.saveBtnContainer}>
                <Button
                  onClick={() => {
                    history.push('/bsfs');
                  }}
                  sx={{ width: '100px' }}
                  style={{ marginRight: '50px' }}
                >
                  Cancel
                </Button>
                <Button
                  gold
                  disabled={disabled}
                  sx={{ width: '100px' }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
            </form>
          ) : <CircularProgress/>}
        </Paper>

      </div>
    </Layout>
  );
};

export default BSFs;
