import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { reset } from 'cooldux';
import { useDispatch, useSelector } from 'react-redux';
import { BsPinAngle, BsPinAngleFill } from 'react-icons/bs';
import moment from 'moment';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ArticleIcon from '@mui/icons-material/Article';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContactsIcon from '@mui/icons-material/Contacts';
import DashboardIcon from '@mui/icons-material/Home';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PaidIcon from '@mui/icons-material/Paid';
import PaymentsIcon from '@mui/icons-material/Payments';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SchoolIcon from '@mui/icons-material/School';
import ChatIcon from '@mui/icons-material/ChatBubbleOutline';

import Header from './header';

import { colors } from '../lib/styles';
import { Button } from '../lib/common';

import { logout } from '../state/user';
import useUser from '../lib/useUser';
import {
  compressNav,
  expandNav,
  updateTitle,
  pinNav,
  unpinNav,
} from '../state/navigation';
import FeedbackDialog from './feedback-form';
import config from '../config';
import { requestForms } from '../lib/applicationForms';
import { isAdmin } from '../lib/util';

const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const styles = {
  icon: {
    color: colors.white,
  },
  logo: {
    height: '80px',
    marginRight: '10px',
  },
  menuItem: {
    margin: '5px',
    backgroundColor: 'white',
    padding: '3px',
  },
  pubLink: {
    textDecoration: 'none',
    color: 'white',
    margin: '10px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  pubSubLink: {
    textDecoration: 'none',
    color: 'white',
    margin: '8px',
    marginLeft: '30px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
};

const tabs = [
  {
    label: 'Agent Dashboard',
    path: '/agent-dashboard',
    icon: <DashboardIcon />,
    serviceRepOk: true,
  },
  {
    label: 'Client Management',
    path: '/client-management',
    icon: <PeopleAltIcon />,
    approveRequired: true,
    serviceRepOk: true,
  },
  {
    label: 'My BSFs',
    path: '/bsfs',
    icon: <HistoryEduIcon />,
    approveRequired: true,
  },
  {
    label: 'Payments',
    path: '/payments',
    icon: <PaymentsIcon />,
    approveRequired: true,
  },
  {
    label: 'Wealth Share',
    path: '/overrides',
    icon: <PaidIcon />,
    approveRequired: true,
  },
  {
    label: 'Business Resources',
    path: '/business_resources',
    icon: <BarChartIcon />,
    approveRequired: true,
  },
  {
    label: 'Training',
    path: 'https://academy.strongholdwealthpartners.com/',
    icon: <SchoolIcon />,
    approveRequired: true,
  },
  {
    label: 'Network',
    path: '/network',
    icon: <AccountTreeIcon />,
  },
  {
    label: 'Contacts',
    path: '/contacts',
    icon: <ContactsIcon />,
    approveRequired: true,
  },
  {
    label: 'Reports',
    path: '/reports',
    icon: <ArticleIcon />,
    approveRequired: true,
  },
];

const now = moment();
const aug2 = moment('2023-08-02');
const billingLive = now.isAfter(aug2);

export default function Layout({ children, showFull = true }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { title, navbarExpanded, navbarPinned } = useSelector((state) => state.navigation);
  const user = useUser();
  const adminUser = isAdmin(user);

  const approved = adminUser || (user?.approved && (billingLive ? user?.pc : true));
  const isServiceRep = user?.isServiceRep;
  const enrollmentPaid = user?.ep;

  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleMenuSelection = (e) => {
    setUserMenuOpen(e.currentTarget);
  };

  const handleClose = () => {
    setUserMenuOpen(null);
  };

  const handlePinNav = () => {
    dispatch(pinNav());
    dispatch(expandNav());
  };

  const handleUnpinNav = () => {
    dispatch(unpinNav());
  };

  const handleMouseLeave = () => {
    if (navbarPinned) return;
    dispatch(compressNav());
  };

  const handleCloseNav = () => {
    dispatch(unpinNav());
    dispatch(compressNav());
  };

  const handleOpenNav = () => {
    dispatch(expandNav());
  };

  const handleOutsideLinks = (path) => {
    window.open(path, '_blank');
  };

  const handleSettings = () => {
    history.push('/settings');
    setUserMenuOpen(null);
  };

  const handleLogout = () => {
    setUserMenuOpen(null);
    logout()
      .then(() => {
        reset();
      })
      .then(() => {
        window.location.href = '/login';
      });
  };

  const handleChangeTab = (tab) => {
    return (tab.label === 'Training')
      ? window.open(tab.path, '_blank')
      : history.push(tab.path);
  };

  const innerComp = children;

  useEffect(() => {
    let name = tabs.find((tab) => (tab.path === pathname));

    if (!name) {
      switch (true) {
        case pathname === '/':
          name = 'Home';
          break;
        case pathname === `/client/${pathname.split('/')[2]}/edit`:
          name = 'Client Details';
          break;
        case pathname === '/clients':
          name = 'Clients';
          break;
        case pathname.includes('/client/new'):
          name = 'Add Client';
          break;
        case pathname.includes('bsf'):
          name = 'Business Submission Form';
          break;
        default:
          name = '';
      }
    } else {
      name = name.label;
    }
    dispatch(updateTitle({ title: name }));
  }, [pathname]);

  return (
    <>
      <FeedbackDialog
        open={feedbackOpen}
        handleClose={() => {
          setFeedbackOpen(false);
        }}
      />
      {
      showFull && user ? (
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar
              disableGutters
              sx={{
                backgroundColor: colors.black,
                minWidth: '100%',
                textAlign: 'center',
                padding: '0px',
                height: '90px',
              }}
            >
              <Button
                variant="inherit"
                href="https://strongholdwealthpartners.com/"
                sx={{ flexShrink: 1, justifyContent: 'flex-start' }}
              >
                <img alt="Home" style={styles.logo} src="/images/stronghold_logo.png" />
              </Button>
              <Box sx={{ flexGrow: 3, display: 'flex', justifyContent: 'center' }}>
                {
                  user.proxy ? (
                    <div>
                      <p style={{
                        fontSize: 32,
                        fontWeight: 'lighter',
                        padding: 0,
                        margin: 15,
                        marginBottom: 0,
                        fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
                      }}
                      >
                        {title}
                      </p>
                      <p style={{ marginTop: 0, fontWeight: 'bolder' }}>
                        Viewing as {user.firstname} {user.lastname} (
                        <a
                          style={{ color: 'white' }}
                          href={`${config.API_URL}/users/rp`}
                        >
                          return
                        </a>)
                      </p>
                    </div>
                  ) : (
                    <p style={{
                      fontSize: 32,
                      fontWeight: 'lighter',
                      padding: 0,
                      margin: 15,
                      fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
                    }}
                    >
                      {title}
                    </p>
                  )
                }
              </Box>
              <Box sx={{
                flexShrink: 1, display: 'inline-flex', alignItems: 'center', float: 'flex-end',
              }}
              >
                <Button
                  gold
                  onClick={() => setOpenDialog(true)}
                  sx={{
                    height: 40,
                  }}
                >
                  Requests
                </Button>
                <Button
                  variant="inherit"
                  aria-controls={userMenuOpen ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={userMenuOpen ? 'true' : undefined}
                  onClick={handleMenuSelection}
                  sx={{
                    fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
                    fontSize: 12,
                    background: 'primary',
                    color: colors.white,
                    minWidth: '80px',
                  }}
                >
                  <AccountCircleIcon sx={{ fontSize: 40 }} />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={userMenuOpen}
                  open={Boolean(userMenuOpen)}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  sx={{
                    top: '20px',
                  }}
                >
                  <MenuItem onClick={handleSettings}>Agent Profile</MenuItem>
                  <Divider />
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Box>

            </Toolbar>
          </AppBar>
          <Drawer
            open={navbarExpanded}
            onMouseLeave={handleMouseLeave}
            variant="permanent"
            sx={{
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box', backgroundColor: colors.highlightDark, paddingTop: '20px',
              },
            }}
          >
            <Toolbar />
            <List style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <ListItem
                disablePadding
                sx={{
                  display: 'block',
                  m: 'auto',
                  paddingBottom: '5px',
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: 'initial',
                    px: 2.5,
                  }}
                  onMouseEnter={handleOpenNav}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: navbarExpanded ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    { navbarExpanded ? <ChevronLeftIcon onClick={handleCloseNav} /> : <ChevronRightIcon /> }
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: 14 }}
                    sx={{
                      opacity: navbarExpanded ? 1 : 0,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {
                      navbarPinned
                        ? <BsPinAngleFill size={18} onClick={handleUnpinNav} />
                        : <BsPinAngle size={18} onClick={handlePinNav} />
                      }
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider color={colors.white} />
              {tabs.map((tab) => {
                if ((!approved || !enrollmentPaid) && tab.approveRequired) {
                  return '';
                }
                if (isServiceRep && !tab.serviceRepOk) {
                  return '';
                }
                return (
                  <ListItem
                    key={tab.path}
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => handleChangeTab(tab)}
                      sx={{
                        backgroundColor: tab.path === pathname ? colors.highlightDarker : null,
                        color: tab.path === pathname ? colors.white : null,
                        minHeight: 48,
                        justifyContent: navbarExpanded ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color: tab.path === pathname ? colors.white : null,
                          minWidth: 0,
                          mr: navbarExpanded ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {tab.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={tab.label}
                        primaryTypographyProps={{ fontSize: 14 }}
                        sx={{ opacity: navbarExpanded ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
              <div style={{ margin: 'auto', flexGrow: 1 }} />
              <ListItem
                disablePadding
                sx={{
                  display: 'block',
                  m: 'auto',
                  paddingBottom: '5px',
                }}
              >
                <ListItemButton
                  onClick={() => setFeedbackOpen(true)}
                  sx={{
                    backgroundColor: null,
                    color: null,
                    minHeight: 48,
                    justifyContent: navbarExpanded ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: null,
                      minWidth: 0,
                      mr: navbarExpanded ? 3 : 'auto',
                      justifyContent: 'center',
                      mt: 'auto',
                    }}
                  >
                    <ChatIcon/>
                  </ListItemIcon>
                  <ListItemText
                    primary="Feedback"
                    primaryTypographyProps={{ fontSize: 14 }}
                    sx={{ opacity: navbarExpanded ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{
              flex: '1 0 auto',
              backgroundColor: colors.lightGrey,
              minHeight: '100vh',
              paddingTop: '30px',
              width: `calc(100% - ${drawerWidth}px)`,
            }}
          >
            <Toolbar />
            <Box>
              {innerComp}
            </Box>
          </Box>
          <Dialog fullWidth maxWidth="md" open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle sx={{ textAlign: 'center', mb: '0px' }}>
              Request Type
            </DialogTitle>
            <DialogContent sx={{ display: 'flex' }}>
              <iframe
                height="350"
                width="50%"
                src="https://www.youtube.com/embed/GBewPCwxbao"
                title="Request Menu Breakdown"
                allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;web-share"
                allowFullScreen
              />
              <Box sx={{ width: '50%', display: 'flex', justifyContent: 'space-between' }}>
                <List>
                  {requestForms.filter((form) => form.name.includes('Portfolio Medics')).map((form, idx) => {
                    return (
                      <ListItemButton key={idx} sx={{ pl: 4 }} onClick={() => handleOutsideLinks(form.link)}>
                        <ListItemText primary={form.name} />
                      </ListItemButton>
                    );
                  })}
                </List>
                <List>
                  {requestForms.filter((form) => !form.name.includes('Portfolio Medics')).map((form, idx) => {
                    return (
                      <ListItemButton key={idx} sx={{ pl: 4 }} onClick={() => handleOutsideLinks(form.link)}>
                        <ListItemText primary={form.name} />
                      </ListItemButton>
                    );
                  })}
                </List>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      ) : (
        <>
          <Header />
          {innerComp}
        </>
      )
     }
    </>
  );
}
