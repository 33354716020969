import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import useUser from '../lib/useUser';
import { Button, TextField } from '../lib/common';
import { apiFetch } from '../lib/fetch';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    minWidth: 1000,
    display: 'flex',
    height: 450,
  },
  container: {
    margin: 20,
    width: '100%',
  },
});

const DateForm = (props) => {
  const styles = {
    dateForm: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      padding: '10px',
      width: '100%',
    },
  };
  const {
    bsf,
    updateBsfs,
    user,
    bsfs,
  } = props;
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [updating, setUpdating] = useState(false);
  return (
    <div style={styles.dateForm}>
      <TextField
        label="Effecitve Date"
        value={effectiveDate}
        name="dob"
        onChange={(e) => {
          setEffectiveDate(e.target.value);
        }}
        type="date"
        errorMsg="Date of birth is required"
        xs={2}
      />
      <Button
        disabled={updating || !effectiveDate}
        onClick={async () => {
          setUpdating(true);
          try {
            const url = `/users/${user.id}/bsfs/${bsf.id}/effective_date`;
            await apiFetch.put(url, { submitted_at: effectiveDate });
            const remaining = bsfs.filter((b) => b.id !== bsf.id);
            updateBsfs(remaining);
          } catch (e) {
            setUpdating(false);
          }
        }}
      >
        Update
      </Button>
    </div>
  );
};

const DismissForm = (props) => {
  const {
    bsf,
    // user,
    updateBsfs,
    bsfs,
    localDismissedBSFs,
  } = props;
  const [updating, setUpdating] = useState(false);
  return (
    <div>
      <Button
        disabled={updating}
        onClick={async () => {
          setUpdating(true);
          try {
            // const url = `/users/${user.id}/bsfs/${bsf.id}/dismiss_effective`;
            // await apiFetch.put(url, { dismiss_effective: true });
            localDismissedBSFs['bsf' + bsf.id] = { timestamp: Date.now() };
            localStorage.setItem('dismissedBSFs', JSON.stringify(localDismissedBSFs));
            const remaining = bsfs.filter((b) => b.id !== bsf.id);
            updateBsfs(remaining);
          } catch (e) {
            setUpdating(false);
          }
        }}
      >
        Dismiss
      </Button>
    </div>
  );
};

const UndismissedBSFs = (props) => {
  const user = useUser();
  const { bsfs, updateBsfs } = props;
  const classes = useStyles();
  let localDismissedBSFs = localStorage.getItem('dismissedBSFs');
  if (localDismissedBSFs) {
    try {
      localDismissedBSFs = JSON.parse(localDismissedBSFs);
    } catch (e) {
      localDismissedBSFs = {};
    }
  }
  localDismissedBSFs = localDismissedBSFs || {};
  const undismissed = [];
  (bsfs || []).forEach((bsf) => {
    const loc = localDismissedBSFs['bsf' + bsf.id];
    const now = Date.now();
    const day = 1000 * 60 * 60 * 24;
    if (loc) {
      if (loc.timestamp) {
        const elapsed = now - loc.timestamp;
        // if it's been more than a day, remove it from the dismissed list
        if (elapsed > day) {
          delete localDismissedBSFs['bsf' + bsf.id];
          undismissed.push(bsf);
        }
      }
    } else {
      undismissed.push(bsf);
    }
  });

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'carrier_name',
      headerName: 'Carrier',
      align: 'left',
      width: 190,
      renderCell: (params) => {
        if (params.row.carrier_url) {
          return (
            <a href={params.row.carrier_url} target="_blank" rel="noreferrer">{params.row.carrier_name}</a>
          );
        }
        return params.row.carrier_name;
      },
    },
    {
      field: 'policy_number',
      headerName: 'Policy',
      width: 190,
    },
    {
      field: 'cfirstname',
      headerName: 'Client',
      width: 190,
      renderCell: (params) => {
        return `${params.row.cfirstname} ${params.row.clastname}`;
      },
    },
    {
      field: 'type',
      headerName: 'Effective Date',
      align: 'right',
      width: 280,
      renderCell: (params) => {
        return (
          <DateForm
            user={user}
            bsf={params.row}
            updateBsfs={updateBsfs}
            bsfs={undismissed}
            classes={classes}
          />
        );
      },
    },
    {
      field: 'dimiss',
      headerName: 'Dismiss',
      align: 'right',
      width: 100,
      renderCell: (params) => {
        return (
          <DismissForm
            user={user}
            bsf={params.row}
            updateBsfs={updateBsfs}
            localDismissedBSFs={localDismissedBSFs}
            bsfs={undismissed}
          />
        );
      },
    },
  ];

  if (!undismissed.length) {
    return '';
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogContent
        style={{ padding: '5px' }}
      >
        <h2>You have closed BSFs without an effective date:</h2>
        <div className={classes.gridContainer}>
          <DataGrid autoHeight density="comfortable" columns={columns} rows={undismissed || []} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UndismissedBSFs;
