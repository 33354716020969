export function getRequirement(pillar) {
  const p = String(pillar);
  if (p === '7') {
    return 40;
  }
  if (p === '6') {
    return 25;
  }
  if (p === '5') {
    return 20;
  }
  if (p === '4') {
    return 15;
  }
  if (p === '3') {
    return 10;
  }
  if (p === '2') {
    return 5;
  }
  return 0;
}
