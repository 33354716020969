import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';

import useUser from '../lib/useUser';
import Layout from '../components/layout';
import { Button } from '../lib/common';
import { apiFetch } from '../lib/fetch';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  submitBtn: {
    margin: '15px',
  },
});

const ShareInvites = () => {
  const classes = useStyles();
  const user = useUser();
  const [err] = useState(null);
  const history = useHistory();
  const url = `/users/${user.id}/shares/invites`;
  const { data: users, loading: usersLoading } = useSWR(url);
  const rows = users || [];

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'requester_firstname',
      headerName: 'Agent First Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'requester_lastname',
      headerName: 'Agent Last Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'household_name',
      headerName: 'Household',
      align: 'left',
      width: 200,
    },
    {
      field: 'approve',
      headerName: 'Approve',
      align: 'left',
      width: 200,
      renderCell: (params) => {
        const { id, household_id } = params.row;
        return (
          <Button
            onClick={async () => {
              const path = `/users/${user.id}/household/${household_id}/request/${id}`;
              await apiFetch.put(path, { action: 'ACCEPT' });
              history.push(`/household/${household_id}`);
            }}
          >
            Approve
          </Button>
        );
      },
    },
    {
      field: 'decline',
      headerName: 'Decline',
      align: 'left',
      width: 200,
      renderCell: (params) => {
        const { id, household_id } = params.row;
        return (
          <Button
            onClick={async () => {
              const path = `/users/${user.id}/household/${household_id}/request/${id}`;
              await apiFetch.put(path, { action: 'REJECT' });
              // mutate(url);
              // mutate(url);
              history.push('/client-management');
            }}
          >
            Decline
          </Button>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Household',
      align: 'left',
      width: 200,
    },
  ];

  return (
    <Layout>
      <div>{err?.toString()}</div>
      <Paper>
        {!usersLoading ? (
          <div className={classes.gridContainer}>
            <DataGrid columns={columns} rows={rows || []} />
          </div>
        ) : <CircularProgress/>}
      </Paper>
    </Layout>
  );
};

export default ShareInvites;
