import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import useSWR from 'swr';

import DownloadButton from './download-csv';
import { Select } from '../lib/common';
import useUser from '../lib/useUser';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'left',
    width: 150,
  },
  {
    field: 'email',
    headerName: 'Email',
    align: 'left',
    width: 200,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    align: 'left',
    width: 200,
  },
  {
    field: 'depth',
    headerName: 'Level',
    align: 'left',
    width: 80,
  },
  {
    field: 'sponsor',
    headerName: 'Sponsor',
    align: 'left',
    width: 200,
  },
];

const NetworkRoster = () => {
  const user = useUser();
  const classes = useStyles();
  const [includesTag, setIncludesTag] = useState('true');
  const [selectedTag, setSelectedTag] = useState(null);

  const path = `/users/${user.id}/tree/full`;
  const { data, loading } = useSWR(path);
  const { data: allTags } = useSWR('/tags/active/agents');

  let rows = (data || []);
  const userMap = {};
  rows.forEach((u) => {
    userMap[u.id] = u;
  });
  rows.forEach((u) => {
    const parent = userMap[u.parent_id];
    if (parent) {
      u.sponsor = `${parent.firstname} ${parent.lastname} #${parent.num}`;
    }
  });

  if (selectedTag) {
    rows = rows.filter((u) => {
      const hasTag = (u.tags || []).includes(selectedTag);
      return includesTag === 'true' ? hasTag : !hasTag;
    });
  }

  return (
    <div>
      <Typography variant="h4">Network Roster</Typography>
      <div style={{ display: 'flex' }}>
        {(allTags && allTags.length) && (
          <>
            <Box gridColumn="span 3" style={{ minWidth: '100px', marginRight: '5px' }}>
              <Select
                label="has tag?"
                value={includesTag}
                name="includesTag"
                onChange={(evt) => {
                  setIncludesTag(evt.target.value);
                }}
                style={{ minWidth: '175px' }}
              >
                <MenuItem value="true">has tag of</MenuItem>
                <MenuItem value="false">does NOT have tag of</MenuItem>
              </Select>
            </Box>
            <Box gridColumn="span 3" style={{ minWidth: '100px' }}>
              <Select
                label="tag name"
                value={selectedTag}
                name="selectedTag"
                onChange={(evt) => {
                  setSelectedTag(evt.target.value);
                }}
                style={{ minWidth: '200px' }}
              >
                <MenuItem value={null}>-</MenuItem>
                {allTags.map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>{tag.name}</MenuItem>
                ))}
              </Select>
            </Box>
          </>
        )}
        <DownloadButton rows={rows} name="NetworkRoster"/>
      </div>
      {!loading ? (
        <div className={classes.gridContainer}>
          <DataGrid columns={columns} rows={rows} />
        </div>
      ) : <CircularProgress/>}
    </div>
  );
};

export default NetworkRoster;
