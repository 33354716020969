import React from 'react';
import { useParams, useHistory } from 'react-router';
import useSWR from 'swr';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useUser from '../lib/useUser';
import Layout from '../components/layout';
import data from '../lib/riskProfileData';
import { Button } from '../lib/common';

const styles = {
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  question: {
    fontSize: '1.5em',
  },
  answer: {
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
};

const RiskProfileAnswer = () => {
  const user = useUser();
  const params = useParams();
  const history = useHistory();
  const { id } = params;
  const path = `/users/${user.id}/risk_profiles?orderBy=created_at&id=${id}`;
  const { data: profiles } = useSWR(path);
  let cleanprofiles = [];
  if (profiles) {
    cleanprofiles = profiles.map((p) => {
      p.score = data.calcScore(p);
      p.default_model = data.getModel(p.score);
      p.modelDesc = data.modelText[p.default_model];
      return p;
    });
  }
  const rec = cleanprofiles[0];

  function FormQuestion({ name, num }) {
    const { answers } = data[name];
    let answer = '';
    let value = '';
    answers.forEach((a) => {
      if (a.value === rec[name]) {
        answer = a.label;
        value = a.value;
      }
    });
    return (
      <div
        style={{
          padding: 5,
          margin: 5,
        }}
      >
        <div style={styles.question}>
          {num}. {data[name].question}
        </div>
        <div style={styles.answer}>
          {num !== 10 ? `(${value}) ` : ''}{answer}
        </div>
      </div>
    );
  }

  return (
    <Layout>
      <Paper>
        {rec ? (
          <div
            style={{
              padding: 20,
            }}
          >
            <div>
              <Typography variant="h6">
                Risk Profile Questionnaire
              </Typography>
            </div>
            <div
              style={{
                padding: 20,
              }}
            >
              <div>
                <span style={styles.question}>
                  Created:
                </span>
                <span style={styles.answer}>
                  {rec.created_at ? moment(rec.created_at).format('YYYY-MM-DD') : ''}
                </span>
              </div>
              <div>
                <span style={styles.question}>
                  Score:
                </span>
                <span style={styles.answer}>
                  {rec.score}
                </span>
              </div>
              <div>
                <span style={styles.question}>
                  Default Model:
                </span>
                <span style={styles.answer}>
                  {rec.default_model}
                </span>
              </div>
              {rec.modelDesc && (
                <div>
                  <span style={styles.question}>
                    {rec.modelDesc}
                  </span>
                </div>
              )}
            </div>
            <div
              style={{
                padding: 20,
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <div style={styles.question}>
                    First Name
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={styles.question}>
                    Last Name
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={styles.answer}>
                    {rec.firstname}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={styles.answer}>
                    {rec.lastname}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={styles.question}>
                    Email
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={styles.question}>
                    Phone
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={styles.answer}>
                    {rec.email}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={styles.answer}>
                    {rec.phone}
                  </div>
                </Grid>
              </Grid>
              <hr/>
              <div>
                <FormQuestion num={1} name="return_type"/>
                <FormQuestion num={2} name="years_need"/>
                <FormQuestion num={3} name="expected_5_year"/>
                <FormQuestion num={4} name="inflaction_concern"/>
                <FormQuestion num={5} name="long_term_delcine_10"/>
                <FormQuestion num={6} name="long_term_delcine_20"/>
                <FormQuestion num={7} name="annual_return"/>
                <FormQuestion num={8} name="invested_stocks_bonds"/>
                <FormQuestion num={9} name="choosing_investment"/>
                <FormQuestion num={10} name="maximum_loss"/>
              </div>
            </div>
          </div>
        ) : <CircularProgress/>}
        <div>
          <Button
            onClick={() => history.push('/risk_profile_answers')}
            sx={{ margin: '20px' }}
          >
            Back to all Risk Profiles
          </Button>
        </div>
      </Paper>
    </Layout>
  );
};

export default RiskProfileAnswer;
