import React, { useState } from 'react';
import { clone } from 'lodash';
import moment from 'moment';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import { FormGroup, FormControlLabel } from '@mui/material';

import states from '../lib/states';
import {
  TextField, Select, Button, GridForm, MaskInput,
} from '../lib/common';
import useUser from '../lib/useUser';
import { apiFetch } from '../lib/fetch';

const AgentForm = () => {
  const user = useUser();
  const [consent, setConsent] = useState(false);
  const [agent, setAgent] = useState(clone(user));

  const handleTextChange = (e) => {
    const { name, value } = e.target || e;
    const updatedAgent = { ...agent, [name]: value };
    setAgent(updatedAgent);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    const updatedAgent = { ...agent, [name]: moment(value).toISOString() };
    setAgent(updatedAgent);
  };

  const {
    firstname,
    lastname,
    phone,
    address,
    city,
    state,
    postalcode,
    ssn,
    dob,
    password,
    confirmPassword,
  } = agent;

  const submitInfo = async () => {
    // console.log(agent);
    const body = {
      firstname,
      lastname,
      phone,
      address,
      city,
      state,
      postalcode,
      ssn,
      dob,
    };
    if (user.unsetPword) {
      body.password = password;
    }
    const result = await apiFetch.put(`/users/${user.id}/basic_info_reg`, body);
    // console.log(result);
    document.location.href = result.stripe.url;
  };

  // eslint-disable-next-line max-len
  const consentText = 'I consent to a background check as a part of the onboarding process with StrongHold Wealth Partners.';
  let disabledSubmit = !address || !phone || !city || !state || !firstname || !ssn || !dob || !consent;
  if (!disabledSubmit && user.unsetPword) {
    disabledSubmit = !password || !confirmPassword || (password !== confirmPassword);
  }
  return (
    <Box
      component="form"
      sx={{
        display: 'grid',
        width: '98%',
        overflowX: 'auto',
        margin: 'auto',
        '& .MuiTextField-root': { m: 1 },
      }}
    >
      <h1>Please verify your information and submit payment for enrollment fee.</h1>
      <GridForm>
        {user.unsetPword ? (
          <Grid container spacing={2}>
            <TextField
              type="password"
              name="password"
              label="Create Password"
              value={password || ''}
              required={true}
              onChange={handleTextChange}
            />
            <TextField
              type="password"
              label="Confirm Password"
              name="confirmPassword"
              value={confirmPassword || ''}
              required
              onChange={handleTextChange}
            />
          </Grid>
        ) : ''}
        <Grid container spacing={2}>
          <TextField
            name="firstname"
            label="First Name"
            value={firstname}
            required={true}
            onChange={handleTextChange}
          />
          <TextField
            label="Last Name"
            name="lastname"
            value={lastname}
            required
            onChange={handleTextChange}
          />
        </Grid>
        <Grid container spacing={2}>
          <MaskInput
            label="Phone Number"
            name="phone"
            value={phone}
            required={true}
            onChange={handleTextChange}
            mask_type="tel"
          />
          <MaskInput
            label="Social Security Number"
            value={ssn}
            name="ssn"
            required
            onChange={handleTextChange}
            mask_type="ssn"
          />
          <TextField
            label="Date of Birth"
            value={dob}
            name="dob"
            onChange={handleDateChange}
            type="date"
            required={true}
          />
        </Grid>
        <Box
          display="grid"
          gridTemplateColumns="repeat(15, 1fr)"
          gap={2}
          width={985}
        >
          <Box gridColumn="span 6">
            <TextField
              label="Address"
              value={address}
              name="address"
              required
              onChange={handleTextChange}
            />
          </Box>
          <Box gridColumn="span 3">
            <TextField
              label="City"
              value={city}
              name="city"
              required
              onChange={handleTextChange}
            />
          </Box>
          <Box gridColumn="span 3">
            <Select
              label="State *"
              value={state}
              required={true}
              name="state"
              onChange={handleTextChange}
            >
              {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
            </Select>
          </Box>
          <Box gridColumn="span 3">
            <MaskInput
              mask_type="zip"
              label="Postal Code"
              value={postalcode}
              name="postalcode"
              required
              onChange={handleTextChange}
            />
          </Box>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="repeat(15, 1fr)"
          gap={2}
          width={985}
        >
          <Box gridColumn="span 6">
            <TextField
              label="Email"
              id="email"
              name="email"
              value={user.email}
              disabled
            />
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={consent}
                    onChange={() => {
                      setConsent(!consent);
                    }}
                  />
                )}
                label={<span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{consentText}</span>}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={submitInfo}
              disabled={disabledSubmit}
            >
              Save and continue to payment
            </Button>
          </Grid>
        </Grid>
      </GridForm>
    </Box>
  );
};

export default AgentForm;
