import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';

import { colors } from '../lib/styles';
import { apiFetch } from '../lib/fetch';
import useUser from '../lib/useUser';
import Layout from '../components/layout';
import { formatCurrency } from '../lib/util';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  oHeader: {
    margin: '15px',
    fontSize: '1.5rem',
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'from_num',
    headerName: 'Agent #',
    align: 'right',
    width: 80,
  },
  {
    field: 'from_firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'from_lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'tier',
    headerName: 'Pillar',
    align: 'right',
    width: 75,
  },
  {
    field: 'qualify',
    headerName: 'Qualified?',
    align: 'right',
    width: 100,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 130,
    renderCell: (params) => {
      return <span>{formatCurrency(Number(params.row.amount).toFixed(2))}</span>;
    },
  },
  {
    field: 'statement_date',
    headerName: 'Month',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return <span>{params.row.statement_date ? moment(params.row.statement_date).format('YYYY-MM') : ''}</span>;
    },
  },
];

const OverrideDetails = () => {
  const classes = useStyles();
  const params = useParams();
  const { overrideId } = params;
  const [err] = useState(null);
  const [override, setOverride] = useState(null);
  const [details, setDetails] = useState(null);
  const user = useUser();

  useEffect(async () => {
    const userId = user.id;
    if (overrideId && userId) {
      const [op] = await apiFetch(`/users/${userId}/override_payments?id=${overrideId}`);
      const od = await apiFetch(`/users/${userId}/override_details/to?&statement_date=${op.statement_date}`);
      setOverride(op);
      setDetails(od);
    }
  }, []);

  return (
    <Layout>
      <div style={classes.mainBody}>
        <div>{err?.toString()}</div>
        <div>
          {override ? (
            <>
              <div className={classes.oHeader}>
                <Typography
                  variant="h5"
                  style={{ color: colors.primaryText }}
                >
                  {`Qualified Pillar: ${override.qual_tier}`}
                </Typography>
              </div>
              <div className={classes.oHeader}>
                <Typography
                  variant="h6"
                  style={{ color: colors.primaryText }}
                >
                  {`Amount: $${Number(override.amount).toFixed(2)}`}
                </Typography>
              </div>
            </>
          ) : ''}
        </div>
        <Paper className={classes.tintedBackground}>
          {override ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={details || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </Layout>
  );
};

export default OverrideDetails;
