import React from 'react';

import WarningIcon from '@mui/icons-material/Warning';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function UnderConstruction() {
  return (
    <Box>
      <WarningIcon my="auto" sx={{ fontSize: '150px', width: '100%' }} />

      <Typography sx={{ fontSize: 18, textAlign: 'center' }} paragraph>
        This page is currently under construction. Thank you for your patience.
      </Typography>
    </Box>
  );
}
