import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import FolderIcon from '@mui/icons-material/Folder';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import Typography from '@mui/material/Typography';
import { TreeItem } from '@mui/x-tree-view';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Collapse from '@mui/material/Collapse';

import { Button } from '../lib/common';
import { apiFetch } from '../lib/fetch';
import config from '../config';
import Layout from '../components/layout';
import { isImageFile } from '../lib/util';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    padding: '5px',
    flexDirection: 'column',
  },
  fileBox: {
    width: '100%',
    margin: '5px',
    border: '1px solid black',
    borderRadius: '3px',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  emptyGridContainer: {
    width: '100%',
    display: 'flex',
    height: 50,
    padding: 10,
  },
  submitBtn: {
    margin: '15px',
  },
  fileHeader: {
    display: 'flex',
    fontSize: '1.1rem',
    alignItems: 'center',
    cursor: 'pointer',
  },
});

const FileLink = (props) => {
  const { f, idx } = props;
  const cleanName = f.name.replace(/\s/g, '').replace('/', '');
  let fullPath;
  if (isImageFile(f)) {
    fullPath = `/image-viewer/br/${f.id}`;
  } else {
    fullPath = `${config.API_URL}/stronghold_files/${f.id}/download/${cleanName}`;
  }
  return (
    <tr key={idx} style={{ display: 'flex', alignItems: 'center' }}>
      <td>
        <Typography variant="subtitle1" style={{ marginLeft: '25px', color: 'black', display: 'flex' }}>
          <a
            href={fullPath}
            target="_blank"
            rel="noreferrer"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <FileIcon/> {f.name}
          </a>
        </Typography>
      </td>
    </tr>
  );
};

const FolderItem = (props) => {
  const { record } = props;
  return (
    <TreeItem
      nodeId={props.nodeId}
      itemId={props.nodeId}
      label={(
        <Typography
          variant="subtitle1"
          nodeid={props.nodeId}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {record.folder ? <FolderIcon/> : ''}
          {`${record.name}`}
        </Typography>
      )}
      sx={{ margin: '-3px' }}
    >
      <div>
        <table>
          <tbody>
            {record.files ? (
              <>
                {record.files.map((f, idx) => {
                  return <FileLink f={f} key={idx}/>;
                })}
              </>
            ) : ''}
            {!record.folder ? (
              <FileLink f={record}/>
            ) : ''}
          </tbody>
        </table>
      </div>
      {record.children ? (
        <>
          {record.children.map((c, idx) => {
            return (
              <FolderItem
                record={c}
                nodeId={c.nodeId}
                key={idx}
                labelIcon={record.folder ? <FolderIcon/> : <FileIcon/>}
              />
            );
          })}
        </>
      ) : ''}
    </TreeItem>
  );
};

const DocList = (props) => {
  const { root } = props;
  const classes = useStyles();
  const [selectedFolder, setSelectedFolder] = useState('n0');
  const [show, setShow] = useState(false);
  return (
    <Collapse in={show} collapsedSize={45} className={classes.fileBox}>
      <div
        className={classes.fileHeader}
        onClick={() => setShow(!show)}
        style={{ fontWeight: 'bolder' }}
      >
        {props.label}
        <Button variant="">{show ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Button>
      </div>
      <div>
        <SimpleTreeView
          aria-label="file system navigator"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{ flexGrow: 1 }}
          defaultExpanded={['n0']}
          selected={selectedFolder}
          onNodeSelect={(e) => {
            setSelectedFolder(e.target.getAttribute('nodeid'));
          }}
        >
          {root.children?.map((c) => (
            <FolderItem
              key={c.id}
              record={c}
              nodeId={c.nodeId}
              labelIcon={<FolderIcon/>}
            />
          ))}
          {root.files?.map((c) => (
            <FileLink f={c} key={c.id}/>
          ))}
        </SimpleTreeView>
      </div>
    </Collapse>
  );
};

const StrongholdFiles = () => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const topLevel = [];
  const allFileMap = {};
  files.forEach((f) => {
    f.nodeId = 'n' + f.id;
    if (f.folder) {
      f.children = [];
      // folderMap['n' + f.id] = f;
      // folderMaps[f.client]['n' + f.id] = f;
      f.files = [];
    }
    if (!f.parent_id) {
      topLevel.push(f);
    }
    allFileMap[f.nodeId] = f;
  });
  files.forEach((f) => {
    // const parent = folderMap['n' + f.parent_id];
    const parent = allFileMap['n' + (f.parent_id || 0)];
    if (f.folder && parent && parent.folder) {
      parent.children.push(f);
    } else if (parent && !f.folder) {
      parent.files.push(f);
    }
  });
  useEffect(async () => {
    const path = '/stronghold_files?orderBy=name&sortOrder=asc';
    const savedFiles = await apiFetch(path);
    setFiles(savedFiles);
  }, []);

  return (
    <Layout>
      <Paper>
        <div className={classes.gridContainer}>
          {topLevel.map((f) => (
            <DocList
              root={f}
              label={f.name}
              key={f.id}
              allFileMap={allFileMap}
            />
          ))}
        </div>
      </Paper>
    </Layout>
  );
};

export default StrongholdFiles;
