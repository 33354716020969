import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '../lib/common';

const valerie = 'https://calendly.com/valerie-drcm/45minutes?back=1&month=2024-09';
const spencer = 'https://calendly.com/desertrosespencer/45min?month=2024-09';
const sherika = 'https://api.leadconnectorhq.com/widget/group/vHqwqZfNVyCLaSxSYQE6';

function DesertRoseButton() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        style={{ width: '100%', margin: 8 }}
        gold
        onClick={() => {
          setOpen(true);
        }}
      >
        Schedule Desert Rose
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          Schedule Desert Rose
        </DialogTitle>
        <DialogContent>
          <div>
            <Button
              style={{ width: '100%', margin: 8 }}
              onClick={() => {
                window.open(valerie, '_blank');
              }}
              gold
            >
              Book Valerie
            </Button>
          </div>
          <div>
            <Button
              style={{ width: '100%', margin: 8 }}
              onClick={() => {
                window.open(spencer, '_blank');
              }}
              gold
            >
              Book Spencer
            </Button>
          </div>
          <div>
            <Button
              style={{ width: '100%', margin: 8 }}
              onClick={() => {
                window.open(sherika, '_blank');
              }}
              gold
            >
              Book Sherika
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default DesertRoseButton;
