import React, { useState } from 'react';

import { Grid, MenuItem } from '@mui/material';
import { TextField, Select, MaskInput } from '../lib/common';
import DeleteButton from './delete-button';
import { formatCurrency } from '../lib/util';

const ClientDebtForm = (props) => {
  const {
    debt,
    update,
  } = props;

  const [type, setType] = useState(debt.type);
  const [balance, setBalance] = useState(formatCurrency(debt.balance));
  const [rate, setRate] = useState(debt.rate);

  const handleTextChange = (e, setter) => {
    const { name, value } = e.target || e;
    const data = {
      type,
      balance,
      rate,
    };
    update({ ...debt, ...data, [name]: value });
    setter(value);
  };

  const handleDelete = () => {
    debt.to_delete = true;
    update({ ...debt });
  };

  return (
    <Grid container spacing={2}>
      <Select
        label="Type"
        value={type}
        name="type"
        onChange={(e) => handleTextChange(e, setType)}
      >
        {props.debtTypes.sort((a, b) => a.order_val - b.order_val).map((i) => {
          return (<MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>);
        })}
      </Select>
      <TextField
        label="Interest Rate"
        id="rate"
        name="rate"
        type="number"
        value={rate}
        onChange={(e) => handleTextChange(e, setRate)}
      />
      <MaskInput
        mask_type="currency"
        label="Balance"
        id="balance"
        name="balance"
        value={balance}
        onChange={(e) => handleTextChange(e, setBalance)}
      />
      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
        <DeleteButton handleDelete={handleDelete}/>
      </Grid>
    </Grid>
  );
};

export default ClientDebtForm;
