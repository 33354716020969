import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment/moment';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

import Masonry from '@mui/lab/Masonry';

import DoneIcon from '@mui/icons-material/Done';
import CheckIcon from '@mui/icons-material/CheckBox';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PaymentIcon from '@mui/icons-material/Payment';
import PendingIcon from '@mui/icons-material/Pending';
import PersonIcon from '@mui/icons-material/Person';
import PublishIcon from '@mui/icons-material/Publish';
import UncheckIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import Loading from '../components/loading';
import ProgressCircle from '../components/progress-circle';
import config from '../config';
import { Button, MaskInput } from '../lib/common';
import { colors } from '../lib/styles';
import { apiFetch } from '../lib/fetch';
import useUser from '../lib/useUser';
import { formatCurrency, formatPhoneNumber, isAdmin } from '../lib/util';
import Layout from '../components/layout';
import AgentForm from '../components/agent-form';
import UndismissedBSFs from '../components/undismissed-bsfs';
import AgentLinksCard from '../components/agent-links-card';

/* eslint-disable */
const calSrc = 'https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FPhoenix&mode=WEEK&src=Y19xNjFwcWs3djU3cGo0b2FzNHVlNnJkdXNrc0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23009688"';

const now = moment();
const aug2 = moment('2023-08-02');
const billingLive = now.isAfter(aug2);

const styles = {
  card: {
    cursor: 'pointer',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'center',
  },
  signDiv: {
    display: 'flex',
    margin: '25px',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  signMsg: {
    fontSize: '1.5em',
    margin: '10px',
  },
  pendingApproval: {
    width: '100%',
    color: 'white',
    margin: '20px',
    padding: '10px',
  },
  pendingApprovalText: {
    width: '100%',
    backgroundColor: 'green',
    color: 'white',
    borderRadius: '5px',
    fontSize: '2em',
    margin: '20px',
    padding: '10px',
    textAlign: 'center',
  },
  agentApprovalItem: {
    marginLeft: '20px',
    fontSize: '0.9em',
    display: 'flex',
  },
};

const ColorLibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,#FFF8C8 0%,#FACE41 50%,#D7B565 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,#FFF8C8 0%,#FACE41 50%,#D7B565 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorLibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: colors.white,
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    color: colors.black,
    backgroundImage:
      colors.gold,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    color: colors.black,
    backgroundImage:
      colors.gold,
  }),
}));

const ApprovalItem = ({ condition, label }) => (
  <div style={styles.agentApprovalItem}>
    <span style={{ marginRight: 5 }}>{condition ? <CheckIcon /> : <UncheckIcon />}</span>
    {label}
  </div>
);

function ColorLibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <PaymentIcon />,
    2: <HistoryEduIcon />,
    3: <PublishIcon />,
    4: <PendingIcon />,
    5: <DoneIcon />,
  };

  return (
    <ColorLibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorLibStepIconRoot>
  );
}

ColorLibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};


export default function AgentDashboard() {
  const history = useHistory();
  const authUser = useUser();
  const [user, setUser] = useState(null);
  const adminUser = isAdmin(authUser);
  const steps = ['Pay Enrollment Fee', 'Sign Contract Agreement', 'Submit W-9', 'Awaiting Approval', 'Approved'];
  let task;
  let activeStep;

  useEffect(async () => {
    setUser(await apiFetch(`/users/${authUser.id}/stats`));
  }, [authUser]);

  const { id, ep, pc, ca_signed, w9_signed, approved } = authUser;

  const pedingDocs = user?.pendingDocs || [];
  const hasPendingW9 = pedingDocs.some((doc) => doc.type === 'w9');
  const hasPendingCA = pedingDocs.some((doc) => doc.type === 'ca');

  if (!user) return <Loading />;

  const in_progress = !ep || !ca_signed || !w9_signed
  
  switch (true) {
    case !ep:
      activeStep=0
      task = <AgentForm/>
      break;
    case !ca_signed:
      activeStep=1
      task = (
        <div style={styles.signDiv}>
          <div style={styles.signMsg}>
            Please click the button to complete your Contractor's Agreement.
          </div>
          <Button
            onClick={() => {
              document.location.href = `${config.API_URL}/users/${id}/sign/ca/start`;
            }}
          >
            Contractor's Agreement
          </Button>
        </div>
      )
      break;
    case !w9_signed:
      activeStep=2
      task = (
        <div style={styles.signDiv}>
          <div style={styles.signMsg}>
            Please click the button to complete your W9 form.
          </div>
          <Button
            onClick={() => {
              document.location.href = `${config.API_URL}/users/${id}/sign/w9/start`;
            }}
          >
            W9 form
          </Button>
        </div>
      )
      break;
    default:
      activeStep=3
      break;
  }
  if ((!ca_signed && hasPendingCA) || (!w9_signed && hasPendingW9)) {
    setTimeout(() => {
      document.location.reload();
    }, 10000);
    return (
      <Layout>
        <div style={{ paddingLeft: 20, paddingRight: 20, margin: 20 }}>
          <h2>We are awaiting confirmation of your signed documents.  Please check back in a few minutes.</h2>
        </div>
      </Layout>
    )
  }

  if(in_progress) {
    return (
      <Layout>
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorLibConnector />} sx={{ my: 5}}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorLibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          {task}
        </div>
      </Layout>
    )
  }

  if (approved && !pc && billingLive && !adminUser) {
    return (
      <Layout>
        <div style={styles.signDiv}>
          <div style={styles.signMsg}>
            Please click the button to setup your Agent Portal subscription.
          </div>
          <Button
            onClick={async () => {
              const start = await apiFetch('/subscription_start');
              if (start.hasExisting) {
                return document.location.reload();
              }
              document.location.href = start.url;
            }}
          >
            Start Subscription
          </Button>
        </div>
      </Layout>
    )
  }

  const {
    currentSales,
    currentSHSales,
    ytdWealthShare,
    treeSize,
  } = user;
  const sales = Number(currentSales + currentSHSales).toFixed(2);
  const ytd = formatCurrency(Number(sales || 0).toFixed(2));
  const salesValue = (sales / 80000) * 100;

  let directs = 0;
  const directsDisplay = [];
  let qualified = 0;
  if (user) {
    user.directsBusiness.forEach((d) => {
      directs++;
      if (!d.is_deleted) {
        let disp = !d.approved;
        global.moment = moment;
        if (d.approved && moment(d.approved_at || undefined).isAfter(moment().subtract(7, 'days'))) {
          disp = true;
        }
        if (disp) {
          directsDisplay.push(d);
        }
      }
      if (d.fbs >= 5000) {
        qualified++;
      }
    });
  }

  let currentPillar = 1;
  const pillars = [1, 5, 10, 15, 20, 25, 40];
  pillars.forEach((pillar, idx) => {
    if (qualified >= pillar) {
      currentPillar = idx + 1;
    }
  });
  let nextPillar = currentPillar + 1;
  if (currentPillar > 6) {
    nextPillar = 7;
  }

  const directsRatio = (qualified / pillars[nextPillar]) * 100;

  const formattedYTDSales = (
    <MaskInput
      mask_type="currency"
      id="ytd"
      name="ytd"
      value={ytd}
      fullWidth
      readOnly
      customInput={InputBase}
      style={{
        textAlign: 'center',
        fontSize: 19,
        fontFamily: 'Maven Pro,Roboto,Circular Std,sans-serif',
        color: 'rgba(0, 0, 0, 0.6)',
      }}
    />
  );

  return (
    <Layout>
      {user?.undismissedBSFs?.length > 0 ? (
        <UndismissedBSFs
          bsfs={user.undismissedBSFs} 
          updateBsfs={(updatedBSFs) => setUser({...user, undismissedBSFs: updatedBSFs})}
        />
      ) : ''}
      <Grid container spacing={{ xs: 0, md: 2 }} sx={{ md: {maxHeight: 500}, overflowY: 'hidden', marginBottom: 3, paddingLeft: {xs: 0, md: 5}, paddingRight: {xs: 0, md: 7, lg: '10px'}}}>
        {!approved ? (
          <div style={styles.pendingApproval}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorLibConnector />} sx={{ my: 3}}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorLibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div style={styles.pendingApprovalText}>
              Thanks for completing the enrollment process.  Your account is pending approval.  You will receive next steps shortly within 48 business hours
            </div>
          </div>
          
        ) : ''}
        <Grid item xs={12} lg={8}>
          <iframe
            src="https://strongholdwealthpartners.com/agent-portal-carousel/"
            frameBorder="0"
            title="agent portal carousel"
            style={{
              width: '100%',
              height: '650px',
              zIndex: -1,
            }}
          />
        </Grid>
        <Grid container item xs={12} lg={4}>
          <Grid item xs={12} md={6} lg={12} sx={{ paddingRight: { md: '8px', lg: 0 }, paddingBottom: {lg: '20px'} }}>
            <Card
              variant="outlined"
              sx={{ height: { lg: '100%' } }}
            >
              <CardHeader
                title={(
                  <>
                    <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}}><PersonIcon fontSize="large"/> {`${user.firstname} ${user.lastname}`}</span>
                  </>
                )}
                sx={{ color: colors.white, backgroundColor: colors.black, textAlign: 'center' }}
              />
              <CardActions style={styles.cardActions}>
                <Typography
                  variant="body2"
                >
                  Stronghold Agent: #{user.num}
                </Typography>
              </CardActions>
              {user.start_date ? (
                <CardActions style={styles.cardActions}>
                  <Typography
                    variant="body2"
                  >
                    Start Date: {moment(user.start_date).format('DD-MMM-YYYY')}
                  </Typography>
                </CardActions>
              ) : ''}
              <CardActions style={styles.cardActions}>
                <Typography
                  variant="body2"
                >
                  Phone: {formatPhoneNumber(user.phone)}
                </Typography>
              </CardActions>
              <CardActions style={styles.cardActions}>
                <Typography
                  variant="body2"
                >
                  {user.email}
                </Typography>
              </CardActions>
              <CardActions style={styles.cardActions}>
                <Typography
                  variant="body2"
                >
                  YTD Earnings {user.currentYear}: ${formatCurrency(Number(user.ytdSales).toFixed(2))}
                </Typography>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={12} sx={{ paddingLeft: { md: '8px', lg: 0 } }}>
            <AgentLinksCard agent={authUser} />
          </Grid>
        </Grid>
      </Grid>
     
      <Masonry
        columns={{ xs: 1, md: 2, lg: 3 }}
        spacing={{ xs: 0, md: 2 }}
        sx={{ paddingLeft: {xs: 0, md: 5}, paddingRight: {xs: 0, md: 5} }}
      >
        <Card
          variant="outlined"
          style={styles.card}
          onClick={() => {
            if (!approved) {
              return;
            }
            history.push('/payments');
          }}
        >
          <CardHeader
            title="Capped Status"
            sx={{ color: colors.white, backgroundColor: colors.black, textAlign: 'center' }}
          />
          {user.start_date ? (
            <>
              <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  variant="body2"
                >
                  Since {moment(user.start_date).format('L')}:
                </Typography>
              </CardActions>
              <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                <ProgressCircle
                  value={salesValue}
                  label={sales >= 80000 ? 'Capped' : formattedYTDSales}
                  size={150}
                  sx={{ color: '#FACE41' }}
                />
              </CardContent>
            </>
          ) : ''}
          <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              variant="body2"
            >
              Gross Business counting up to $80k
            </Typography>
          </CardActions>
        </Card>

        <Card
          variant="outlined"
          style={styles.card}
          onClick={() => {
            if (!approved) {
              return;
            }
            history.push('/overrides');
          }}
        >
          <CardHeader
            title="YTD Wealth Share"
            sx={{ color: colors.white, backgroundColor: colors.black, textAlign: 'center' }}
          />
          <CardContent sx={{ display: 'flex', justifyContent: 'center', fontSize: '2em' }}>
            <Typography variant="p" color={colors.black}>Total Network: {treeSize}</Typography>
          </CardContent>
          <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
            <MaskInput
              mask_type="currency"
              id="ytdWealthShare"
              name="ytdWealthShare"
              value={ytdWealthShare}
              fullWidth
              readOnly
              customInput={InputBase}
              style={{
                textAlign: 'center',
                fontSize: 36,
                fontFamily: 'Maven Pro,Roboto,Circular Std,sans-serif',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            />
          </CardContent>
          <CardContent sx={{ display: 'flex', justifyContent: 'center', fontSize: '2em' }}>
            <Typography variant="p" color={colors.black}>Current Pillar: {user?.latestTier || 1}</Typography>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              variant="body2"
            >
              Wealth Share Collected
            </Typography>
          </CardActions>
        </Card>

        <Card
          variant="outlined"
          style={styles.card}
        >
          <CardHeader
            title="New Direct Recruits"
            sx={{ color: colors.white, backgroundColor: colors.black, textAlign: 'center' }}
          />
          <CardContent sx={{ display: 'grid', justifyContent: 'center', fontSize: '1.5em' }}>
            {directsDisplay.map((d) => {
              return (
                <div key={d.id} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <div style={{ fontWeight: 'bold' }}>{d.firstname} {d.lastname}</div>
                  {d.approved ? (<ApprovalItem condition={true} label="Approved" />) : (
                    <>
                    <ApprovalItem condition={d.ep} label="Enrollment paid" />
                    <ApprovalItem condition={d.ca_signed} label="Contractor Agreement Signed" />
                    <ApprovalItem condition={d.w9_signed} label="W9 Completed" />
                    <ApprovalItem condition={d.approved} label="Stronghold Approved" />
                  </>
                  )}
                </div>
              );
            })}
          </CardContent>
        </Card>

        <Card
          variant="outlined"
          style={styles.card}
          onClick={() => {
            if (!approved) {
              return;
            }
            history.push('/chargebacks');
          }}
        >
          <CardHeader
            title="Chargeback Balance"
            sx={{ color: colors.white, backgroundColor: colors.black, textAlign: 'center' }}
          />
          <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
            <MaskInput
              mask_type="currency"
              id="chargebackBalance"
              name="chargebackBalance"
              value={user.chargebackBalance}
              fullWidth
              readOnly
              customInput={InputBase}
              style={{
                textAlign: 'center',
                fontSize: 36,
                fontFamily: 'Maven Pro,Roboto,Circular Std,sans-serif',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            />
          </CardContent>
        </Card>

        <Card
          variant="outlined"
          style={styles.card}
          onClick={() => {
            history.push('/network');
          }}
        >
          <CardHeader
            title="Qualified Recruits"
            sx={{ color: colors.white, backgroundColor: colors.black, textAlign: 'center' }}
          />
          <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
            <ProgressCircle
              value={directsRatio}
              label={`${qualified} / ${pillars[nextPillar - 1]}`}
              sx={{ justifyContent: 'center', color: '#FACE41' }}
              size={150}
            />
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="body2">Current / Needed for Next Pillar</Typography>
          </CardActions>
        </Card>

        <Card variant="outlined">
          <CardHeader
            title="Calendar"
            sx={{ color: colors.white, backgroundColor: colors.black, textAlign: 'center' }}
          />
          <CardContent>
            <div id="calendar-entries">
              <iframe
                src={calSrc}
                frameBorder="0"
                scrolling="no"
                width="100%"
                height="480"
                title="calendar entries"/>
            </div>
          </CardContent>
        </Card>

      </Masonry>
    </Layout>
  );
}