import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import BarChart from '@mui/icons-material/BarChart';
import Launch from '@mui/icons-material/Launch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

import { MaskInput, Select } from '../lib/common';
import useUser from '../lib/useUser';
import { getRequirement } from '../lib/pillars';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '',
    },
  },
};

export const lineChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '',
    },
  },
};

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const now = new Date();
const defaultMonthStr = now.toISOString().substring(5, 7);
const defaultYearEnd = String(now.getFullYear());
const defaultYearStart = String(now.getFullYear() - 1);
const monthOptions = [
  ['01', 'January'],
  ['02', 'February'],
  ['03', 'March'],
  ['04', 'April'],
  ['05', 'May'],
  ['06', 'June'],
  ['07', 'July'],
  ['08', 'August'],
  ['09', 'September'],
  ['10', 'October'],
  ['11', 'November'],
  ['12', 'December'],
];
const yearOptions = [];
for (let i = 0; i < 15; i++) {
  yearOptions.push(String(now.getFullYear() - i));
}

export default function Reports() {
  const user = useUser();
  const [type, setType] = useState('year');
  const [startMonth, setStartMonth] = useState(defaultMonthStr);
  const [endMonth, setEndMonth] = useState(defaultMonthStr);
  const [startYear, setStartYear] = useState(defaultYearStart);
  const [endYear, setEndYear] = useState(defaultYearEnd);
  const byYearUrl = `/users/${user.id}/missed_money/year/pillar?orderBy=missed_start&sortOrder=desc`;
  const { data: byYear } = useSWR(byYearUrl);
  let byMonthUrl = `/users/${user.id}/missed_money/month/pillar?orderBy=missed_start&sortOrder=desc`;
  if (type === 'last12') {
    byMonthUrl = `/users/${user.id}/missed_money/range`;
    byMonthUrl += `?startMonth=${defaultMonthStr}&endMonth=${defaultMonthStr}`;
    byMonthUrl += `&startYear=${defaultYearStart}&endYear=${defaultYearEnd}`;
  } else if (type === 'range') {
    byMonthUrl = `/users/${user.id}/missed_money/range`;
    byMonthUrl += `?startMonth=${startMonth}&endMonth=${endMonth}&startYear=${startYear}&endYear=${endYear}`;
  }
  const { data: byMonth } = useSWR(byMonthUrl);

  const years = [];
  let currentYear;
  let currentYearIdx = 0;
  const months = [];
  let currentMonth;
  let currentMonthIdx = 0;

  if (byYear) {
    byYear.forEach((row) => {
      row.total_amount = Number(row.total_amount);
      row.total_max_amount = Number(row.total_max_amount);
      row.avg_qual_directs = Number(row.avg_qual_directs || 0);
      const rowYear = row.missed_start.substring(0, 4);
      if (!years.length) {
        years.push({
          year: rowYear,
          label: rowYear,
          details: [],
          total_amount: 0,
          total_max_amount: 0,
        });
        currentYear = rowYear;
      }
      if (rowYear !== currentYear) {
        years.push({
          year: rowYear,
          label: rowYear,
          details: [],
          total_amount: 0,
          total_max_amount: 0,
        });
        currentYear = rowYear;
        currentYearIdx++;
      }
      years[currentYearIdx].details.push(row);
      years[currentYearIdx].total_amount += row.total_amount;
      years[currentYearIdx].total_max_amount += row.total_max_amount;
    });
  }

  if (byMonth) {
    byMonth.forEach((row) => {
      row.total_amount = Number(row.total_amount);
      row.total_max_amount = Number(row.total_max_amount);
      row.avg_qual_directs = Number(row.avg_qual_directs || 0);
      const rowMonth = row.missed_start.substring(0, 7);
      if (!months.length) {
        months.push({
          month: rowMonth,
          label: rowMonth,
          details: [],
          total_amount: 0,
          total_max_amount: 0,
        });
        currentMonth = rowMonth;
      }
      if (rowMonth !== currentMonth) {
        months.push({
          month: rowMonth,
          label: rowMonth,
          details: [],
          total_amount: 0,
          total_max_amount: 0,
        });
        currentMonth = rowMonth;
        currentMonthIdx++;
      }
      months[currentMonthIdx].details.push(row);
      months[currentMonthIdx].total_amount += row.total_amount;
      months[currentMonthIdx].total_max_amount += row.total_max_amount;
    });
  }

  months.forEach((m) => {
    m.total_qual_directs = 0;
    m.details.forEach((det) => {
      m.total_qual_directs += det.avg_qual_directs;
    });
    m.avg_qual_directs = Math.round(m.total_qual_directs / m.details.length);
  });

  let disp = [];
  let chartDisp = [];
  if (type === 'year') {
    disp = years;
    chartDisp = [...years].reverse();
  } else if ((type === 'month') || (type === 'last12') || (type === 'range')) {
    disp = months;
    chartDisp = [...months].reverse();
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const chartData = {};
  if (type === 'year' || type === 'month' || type === 'last12' || type === 'range') {
    chartData.labels = chartDisp.map((row) => row.label);
    chartData.datasets = [
      {
        label: 'Missed',
        data: chartDisp.map((row) => {
          return row.total_max_amount - row.total_amount;
        }),
        backgroundColor: '#888888',
      },
      {
        label: 'Earned',
        data: chartDisp.map((row) => {
          return row.total_amount;
        }),
        backgroundColor: '#e8b923',
      },
    ];
  }

  const lineChartData = {};
  if (type === 'month' || type === 'last12' || type === 'range') {
    lineChartData.labels = chartDisp.map((row) => row.label);
    lineChartData.datasets = [
      {
        label: 'Qualified Directs',
        data: chartDisp.map((row) => {
          return row.avg_qual_directs;
        }),
        backgroundColor: '#e8b923',
      },
    ];
  }

  return (
    <div style={{ margin: 20 }}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        >
          <div>
            <Typography variant="h4">Missed Money</Typography>
            <Select
              value={type}
              label="Type"
              name="selectType"
              onChange={(e) => setType(e.target.value)}
              style={{ width: 150, marginBottom: 15 }}
            >
              <MenuItem value="year">By Year</MenuItem>
              <MenuItem value="month">By Month</MenuItem>
              <MenuItem value="last12">Last 12 Months</MenuItem>
              <MenuItem value="range">Range</MenuItem>
            </Select>
          </div>
          {type === 'range' ? (
            <div style={{ display: 'flex' }}>
              <Select
                value={startMonth}
                label="Start Month"
                name="selectType"
                onChange={(e) => setStartMonth(e.target.value)}
                style={{ width: 150, margin: 5 }}
              >
                {monthOptions.map((m, idx) => <MenuItem key={idx} value={m[0]}>{m[1]}</MenuItem>)}
              </Select>
              <Select
                value={startYear}
                label="Start Year"
                name="selectType"
                onChange={(e) => setStartYear(e.target.value)}
                style={{ width: 150, margin: 5, marginRight: 15 }}
              >
                {yearOptions.map((m, idx) => <MenuItem key={idx} value={m}>{m}</MenuItem>)}
              </Select>
              <Select
                value={endMonth}
                label="End Month"
                name="selectType"
                onChange={(e) => setEndMonth(e.target.value)}
                style={{ width: 150, margin: 5 }}
              >
                {monthOptions.map((m, idx) => <MenuItem key={idx} value={m[0]}>{m[1]}</MenuItem>)}
              </Select>
              <Select
                value={endYear}
                label="End Year"
                name="selectType"
                onChange={(e) => setEndYear(e.target.value)}
                style={{ width: 150, margin: 5 }}
              >
                {yearOptions.map((m, idx) => <MenuItem key={idx} value={m}>{m}</MenuItem>)}
              </Select>
            </div>
          ) : ''}
          <Tabs value={value} onChange={handleChange} aria-label="missed money tabs">
            <Tab label="Details" {...tabProps(0)} sx={{ fontSize: 18 }}/>
            <Tab
              label={<span>Growth <BarChart sx={{ fontSize: 18, marginBottom: '-2px' }}/></span>}
              {...tabProps(1)}
              sx={{ fontSize: 18 }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {type === 'year' || type === 'month' || type === 'last12' || type === 'range' ? (
            <div>
              {disp.map((row) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id={`panel1a-header-${row.label}`}
                  >
                    <Typography
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: 14,
                      }}
                    >
                      {row.label}
                      {type === 'month' || type === 'last12' || type === 'range' ? (
                        <div style={{ display: 'flex' }}>
                          Qualified Directs: {row.avg_qual_directs}
                        </div>
                      ) : ''}
                      <div style={{ display: 'flex' }}>
                        Total Missed:
                        <MaskInput
                          mask_type="currency"
                          id="total_missed"
                          name="total_missed"
                          value={row.total_max_amount - row.total_amount}
                          readOnly
                          customInput={InputBase}
                          style={{
                            textAlign: 'right',
                            fontSize: 14,
                            fontFamily: 'Maven Pro,Roboto,Circular Std,sans-serif',
                            fontWeight: 'bold',
                          }}
                        />
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <Table sx={{ minWidth: 650, px: 3 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Pillar</TableCell>
                            <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                              <div>
                                Directs Required
                                {type !== 'year' ? (
                                  <Launch
                                    sx={{
                                      fontSize: 18,
                                      marginBottom: '-4px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      const height = 800;
                                      const width = 600;
                                      const left = (window.screen.width - width) / 2;
                                      const top = (window.screen.height - height) / 2;
                                      let ops = `popup=true,height=${height},width=${width},top=${top},left=${left}`;
                                      ops += 'location=no,toolbar=no,menubar=no';
                                      const url = `/qualified/${row.label}`;
                                      window.open(url, '_blank', ops);
                                    }}
                                  />
                                ) : ''}
                              </div>
                            </TableCell>
                            <TableCell align="right">Earned</TableCell>
                            <TableCell align="right">Missed</TableCell>
                            <TableCell align="right">Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.details.map((det) => (
                            <TableRow
                              key={det.tier}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {det.tier}
                              </TableCell>
                              <TableCell align="right">
                                {getRequirement(det.tier) || ''}
                              </TableCell>
                              <TableCell align="right">
                                <MaskInput
                                  mask_type="currency"
                                  id="actual"
                                  name="actual"
                                  value={det.total_amount}
                                  readOnly
                                  customInput={InputBase}
                                  style={{
                                    textAlign: 'right',
                                    fontSize: 14,
                                    fontFamily: 'Maven Pro,Roboto,Circular Std,sans-serif',
                                  }}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <MaskInput
                                  mask_type="currency"
                                  id="missed"
                                  name="missed"
                                  value={det.total_max_amount - det.total_amount}
                                  readOnly
                                  customInput={InputBase}
                                  style={{
                                    textAlign: 'right',
                                    fontSize: 14,
                                    fontFamily: 'Maven Pro,Roboto,Circular Std,sans-serif',
                                    color: det.total - det.actual > 0 ? 'red' : 'green',
                                  }}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <MaskInput
                                  mask_type="currency"
                                  id="total"
                                  name="total"
                                  value={row.total_max_amount}
                                  readOnly
                                  customInput={InputBase}
                                  style={{
                                    textAlign: 'right',
                                    fontSize: 14,
                                    fontFamily: 'Maven Pro,Roboto,Circular Std,sans-serif',
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          ) : ''}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Bar
            options={options}
            data={chartData}
            style={{
              height: '300px',
            }}
          />
          {type === 'month' || type === 'last12' || type === 'range' ? (
            <Line
              options={lineChartOptions}
              data={lineChartData}
              style={{
                height: '300px',
              }}
            />
          ) : ''}
        </TabPanel>
      </Box>
    </div>
  );
}

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
