import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import parse from 'html-react-parser';

import { apiFetch } from '../lib/fetch';
import useUser from '../lib/useUser';
import { Button } from '../lib/common';

const useStyles = makeStyles({
  container: {
    margin: '5px',
  },
  noteContainer: {
    border: '1px solid grey',
    padding: '3px',
    background: 'white',
  },
  noteText: {
    width: '100%',
    minHeight: '20px',
  },
  notePre: {
    whiteSpace: 'pre-wrap',
  },
  noteBy: {
    backgroundColor: '#DDD',
  },
});

const NotesArchive = () => {
  const [notes, setNotes] = useState([]);
  const user = useUser();
  const classes = useStyles();
  const params = useParams();
  const { householdId } = params;

  useEffect(async () => {
    const records = await apiFetch(`/users/${user.id}/household/${householdId}/notes`);
    setNotes(records);
  }, []);

  return (
    <div className={classes.container}>
      <div>
        <Button
          onClick={() => {
            window.close();
          }}
          style={{ margin: '10px' }}
        >
          Close
        </Button>
      </div>
      {notes.map((n, idx) => {
        return (
          <div key={'note' + idx}>
            <div className={classes.noteContainer}>
              <div className={classes.noteText}>
                {parse(n.text)}
              </div>
              <div className={classes.noteBy}>
                <span style={{ marginRight: '10px' }}>
                  {n.created_at ? moment(n.created_at).format('MM/DD/yyyy HH:mm') : ''}
                </span>
                <span>By: {n.firstname} {n.lastname} #{n.num}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NotesArchive;
