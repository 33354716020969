import React, { useState } from 'react';

import { Grid, MenuItem } from '@mui/material';

import states from '../lib/states';
import {
  TextField, Select, GridForm, MaskInput,
} from '../lib/common';

const ClientForm = (props) => {
  const {
    update,
  } = props;
  const [client, setClient] = useState({
    ...props.client,
  });

  const handleTextChange = (e) => {
    const { name, value } = e.target || e;
    const updatedClient = { ...client, [name]: value };
    update(updatedClient);
    setClient(updatedClient);
  };

  const {
    firstname,
    lastname,
    phone,
    email,
    pstate,
  } = client;

  return (
    <GridForm>
      <Grid container spacing={2}>
        <TextField
          name="firstname"
          label="First Name"
          value={firstname}
          required
          onChange={handleTextChange}
        />
        <TextField
          label="Last Name"
          name="lastname"
          value={lastname}
          required
          onChange={handleTextChange}
        />
      </Grid>
      <Grid container spacing={2}>
        <MaskInput
          label="Phone Number"
          name="phone"
          value={phone}
          required={true}
          onChange={handleTextChange}
          mask_type="tel"
        />
        <TextField
          label="Email"
          required={true}
          value={email}
          name="email"
          onChange={handleTextChange}
          expand
        />
      </Grid>
      <Grid container spacing={2}>
        <Select
          label="State of Residence *"
          value={pstate}
          required={true}
          name="pstate"
          onChange={handleTextChange}
        >
          {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
        </Select>
      </Grid>
    </GridForm>
  );
};

export default ClientForm;
