import { cleanNumber } from './util';

export function updateDefaults(c) {
  c.goal_ret_age = c.goal_ret_age || null;
  c.email = c.email || null;
  c.gender_id = c.gender_id || null;
  c.tax_filing_status_id = c.tax_filing_status_id || null;
  c.dat_income = c.dat_income || null;
  if (c.dat_income === 'NaN') {
    c.dat_income = null;
  }
  if (c.isNew) {
    return c;
  }
  const { records } = c;
  records.debt.forEach((r) => {
    r.balance = r.balance || 0;
    r.rate = r.rate || 0;
  });
  records.income.forEach((r) => {
    r.annual = r.annual || 0;
    r.source = r.source || null;
  });
  records.termInsurance.forEach((r) => {
    r.carrier_id = r.carrier_id || null;
    r.policy_length = r.policy_length || null;
    r.premium_amount = r.premium_amount || null;
    r.death_amount = r.death_amount || null;
  });
  records.employment.forEach((r) => {
    r.year_hired = r.year_hired || null;
    r.employment_status_id = r.employment_status_id || null;
  });
  records.trusts.forEach((r) => {
    r.percentage = r.percentage || null;
  });
  records.realEstate.forEach((r) => {
    r.balance = r.balance || null;
    r.type_id = r.type_id || null;
    r.rate = r.rate || null;
    r.estimated_value = r.estimated_value || null;
    r.equity = r.equity || null;
  });
  records.assets.forEach((r) => {
    r.amount = r.amount || 0;
    r.holding = r.holding || null;
    r.type = r.type || null;
    if (r.insuranceRecord) {
      [
        'accum_amount',
        'cash_amount',
        'current_loan_rate',
        'death_amount',
        'interest_rate',
        'loan_cap',
        'premium_amount',
        'surrender_schedule',
        'surrender_value',
        'total_surrender',
      ].forEach((v) => {
        r.insuranceRecord[v] = r.insuranceRecord[v] || null;
      });
      r.insuranceRecord.carrier_id = r.insuranceRecord.carrier_id || null;
      r.insuranceRecord.type_id = r.insuranceRecord.type_id || null;
    }
    if (r.employerPlanRecord) {
      r.employerPlanRecord.type_id = r.employerPlanRecord.type_id || null;
    }
    if (r.annuityRecord) {
      [
        'accum_amount',
        'admin_fees',
        'benefit_base_amount',
        'cost_basis',
        'death_amount',
        'death_benefit_fees',
        'fees',
        'fixed_account_rate',
        'init_premium_amount',
        'mortality_fees',
        'rider_fees',
        'sub_account_fees',
        'surrender_amount',
      ].forEach((v) => {
        r.annuityRecord[v] = cleanNumber(r.annuityRecord[v]) || null;
      });
      r.annuityRecord.type_id = r.annuityRecord.type_id || null;
      r.annuityRecord.carrier_id = r.annuityRecord.carrier_id || null;
    }
  });
  return c;
}

export function newEmployerPlan(asset_id, client_id) {
  return {
    asset_id,
    client_id,
    company_name: '',
    type_id: '',
    issue_date: null,
    policy_length: null,
    death_amount: null,
    premium_amount: null,
    frequency: '',
    accum_amount: null,
    cash_amount: null,
    interest_rate: null,
    current_loan_rate: null,
    fixed: '',
    loan_cap: null,
    surrender_schedule: null,
    surrender_value: null,
    total_surrender: null,
    living_benefits: '',
    contract_fees: '',
    subject_to_losses: '',
    isNew: true,
  };
}

export function newInsurance(asset_id, client_id) {
  return {
    asset_id,
    client_id,
    company_name: '',
    carrier_id: '',
    type_id: '',
    issue_date: null,
    death_amount: null,
    premium_amount: null,
    frequency: '',
    accum_amount: null,
    cash_amount: null,
    interest_rate: null,
    current_loan_rate: null,
    fixed: '',
    loan_cap: null,
    surrender_schedule: null,
    surrender_value: null,
    total_surrender: null,
    living_benefits: '',
    contract_fees: '',
    subject_to_losses: '',
    isNew: true,
  };
}

export function newAnnuity(asset_id, client_id) {
  return {
    asset_id,
    client_id,
    company_name: '',
    carrier_id: '',
    type_id: '',
    issue_date: null,
    policy_length: null,
    death_amount: null,
    init_premium_amount: null,
    frequency: '',
    accum_amount: null,
    benefit_base_amount: null,
    partial_roth: '',
    fee_withdrawal_amount: '',
    fixed_account_rate: '',
    guaranteed_roll_up: '',
    income_stream_increase: '',
    payout_options: '',
    inheritance_options: '',
    ltc_benefits: '',
    ltc_benefits_apply: '',
    fees: '',
    sub_account_fees: '',
    rider_fees: '',
    admin_fees: '',
    mortality_fees: '',
    death_benefit_fees: '',
    transferring_fees: '',
    other_fees: '',
    isNew: true,
  };
}

export function newDebt(type) {
  return {
    type,
    balance: '',
    rate: '',
    isNew: true,
  };
}

export function newClient(household_id) {
  return {
    firstname: '',
    middlename: '',
    lastname: '',
    birth_state: '',
    phone: '',
    dob: null,
    email: '',
    marital_status: '',
    goal_ret_age: '',
    tax_filing_status_id: '',
    ssn: '',
    paddress: '',
    pcity: '',
    pstate: '',
    pzip: '',
    maddress: '',
    mcity: '',
    mstate: '',
    mzip: '',
    dl_expiration: null,
    dl_issue: null,
    dl_num: '',
    dl_state: '',
    household_id,
    isNew: true,
  };
}

export function newEmployment() {
  return {
    empaddress: '',
    empcity: '',
    empstate: '',
    empzip: '',
    employment_status_id: null,
    employer_name: '',
    employer_phone: '',
    job_title: '',
    year_hired: '',
    isNew: true,
  };
}

export function addAssetChildren(a) {
  if (!a.insuranceRecord) {
    a.insuranceRecord = newInsurance(a.id, a.client);
  }
  if (!a.annuityRecord) {
    a.annuityRecord = newAnnuity(a.id, a.client);
  }
  if (!a.employerPlanRecord) {
    a.employerPlanRecord = newEmployerPlan(a.id, a.client);
  }
}
