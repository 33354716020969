/* eslint-disable max-len */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import Joi from 'joi';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import { apiFetch } from '../lib/fetch';
import rpd from '../lib/riskProfileData';

const styles = {
  joinImg: {
    width: '260px',
    height: 'auto',
  },
  joinImgContainer: {
    padding: '20px',
    borderRadious: '10px',
  },
  text: {
    color: 'black',
    backgroundColor: 'white',
    width: '200px',
    margin: '20px',
  },
  textfield: {
    color: 'black',
    backgroundColor: 'white',
    width: '320px',
    borderRadius: '5px',
  },
  invalidTextfield: {
    color: 'black',
    border: '1 px solid red',
    width: '320px',
    borderRadius: '5px',
  },
  question: {
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bolder',
  },
  answer: {
    color: 'white',
    fontSize: '16px',
  },
};

const BpIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  backgroundColor: '#838895',
  boxSizing: 'border-box',
  boxShadow: '0 0 0 1px #000000, 0 0 0 2px rgba(0,0,0,.1)',
  'input:hover ~ &': {
    boxShadow: '0 0 5px 3px #222222',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#2E69FF',
  border: '2px solid #838895',
  boxShadow: '0 0 5px 3px #222222',
  'input:hover ~ &': {
    backgroundColor: '#295EE5',
  },
});

function CustomRadio(props) {
  return (
    <Radio
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

// eslint-disable-next-line
const welcome = `Thank you so much for taking the time to complete this risk questionnaire. It is needed so that we know the parameters we need to stay within when designing and managing a portfolio. This questionnaire is designed to assess "YOUR" risk level and no one else. It is ideal that "YOU" answer all questions as you see fit without the influence of anyone. Video explanations are provided on some of the questions to help clarify any confusion that might exist about a particular question.`;

function Ast() {
  return (
    <span
      style={{
        color: 'red',
        fontSize: '2em',
        textAlign: 'center',
        verticalAlign: 'middle',
      }}
    >
      *
    </span>
  );
}

function stripNonNumericAndSymbols(input) {
  // Use a regular expression to match only numbers, parentheses, and dashes
  // eslint-disable-next-line no-useless-escape
  return input.replace(/[^\d\(\)\-]/g, '');
}

const emailValidator = Joi.string().email({ tlds: { allow: false } }).required();

const UserForm = () => {
  document.body.style.backgroundColor = '#2c3345';
  const params = useParams();
  const { num } = params;
  const [errMsg, setErrMsg] = useState('');
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [return_type, setReturnType] = useState('');
  const [years_need, setYearsNeed] = useState('');
  const [expected_5_year, setExpected5Year] = useState('');
  const [inflaction_concern, setInflactionConcern] = useState('');
  const [long_term_delcine_10, setLongTermDelcine10] = useState('');
  const [long_term_delcine_20, setLongTermDelcine20] = useState('');
  const [annual_return, setAnnualReturn] = useState('');
  const [invested_stocks_bonds, setInvestedStocksBonds] = useState('');
  const [choosing_investment, setChoosingInvestment] = useState('');
  const [maximum_loss, setMaximumLoss] = useState('');
  const radiosFilled = return_type && years_need && expected_5_year && inflaction_concern && long_term_delcine_10 && long_term_delcine_20 && annual_return && invested_stocks_bonds && choosing_investment && maximum_loss;

  const validEmail = !emailValidator.validate(email).error;
  const validPhone = phone.length > 9;

  function FormQuestion(props) {
    const {
      name,
      setter,
      value,
      num,
    } = props;
    const d = rpd[name];
    // console.log(d);
    return (
      <div
        style={{
          color: 'white',
          marginBottom: '30px',
        }}
      >
        <FormControl>
          <FormLabel
            id="demo-radio-buttons-group-label"
            style={styles.question}
          >
            {num}. {d.question} <Ast />
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={value}
            onChange={(e) => setter(e.target.value)}
          >
            {d.answers.map((a) => (
              <FormControlLabel
                value={a.value}
                control={(
                  <CustomRadio />
                )}
                label={<span style={styles.answer}>{a.label}</span>}
                key={a.value}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    );
  }

  let agent;
  if (num) {
    const { data } = useSWR(`/agents/${num}`);
    agent = data;
  }

  const handleTextChange = (e, setter) => {
    setter(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        firstname,
        lastname,
        email,
        phone,
        return_type,
        years_need,
        expected_5_year,
        inflaction_concern,
        long_term_delcine_10,
        long_term_delcine_20,
        annual_return,
        invested_stocks_bonds,
        choosing_investment,
        maximum_loss,
      };

      const savedProfile = await apiFetch(`/agents/${agent.num}/risk_profiles`, {
        method: 'POST',
        body: data,
      });

      if (savedProfile) {
        setSubmitted(true);
      }
    } catch (err) {
      setErrMsg(err.message);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#11111B',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <div
        style={{
          color: 'white',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            // width: '80%',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <img
              style={styles.joinImg}
              src="/images/SCP_Logo Finals_SWP-Vertical-Gold.png"
              alt="stronghold wealth partners"
            />
          </div>
          {!submitted ? (
            <div
              style={{
                // width: '90%',
                backgroundColor: '#424756',
                color: 'white',
                margin: '20px',
                borderRadius: '10px',
                textAlign: 'left',
              }}
            >
              <div style={styles.joinImgContainer}>
                <div style={{ textAlign: 'center' }}>
                  <h1 style={styles.joinH1}><strong>Risk Profile Questionnaire</strong></h1>
                </div>
                <hr/>
                <div>
                  <Typography variant="h6">
                    {welcome}
                  </Typography>
                </div>
                <hr/>
                {errMsg && (
                  <div
                    style={{
                      color: 'red',
                      textAlign: 'center',
                      margin: '10px',
                    }}
                  >
                    {errMsg}
                  </div>
                )}
                <Grid
                  style={{ marginBottom: '25px' }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div style={styles.question}>
                        First Name <Ast />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={styles.question}>
                        Last Name <Ast />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="firstname"
                        value={firstname}
                        required
                        onChange={(e) => handleTextChange(e, setFirstName)}
                        errorMsg="First name is required"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="lastname"
                        value={lastname}
                        required
                        onChange={(e) => handleTextChange(e, setLastName)}
                        errorMsg="Last name is required"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div style={styles.question}>
                        Email <Ast />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={styles.question}>
                        Phone <Ast />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="email"
                        value={email}
                        required
                        onChange={(e) => handleTextChange(e, setEmail)}
                        errorMsg="Email is required"
                        style={styles.textfield}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="phone"
                        value={phone}
                        required
                        onChange={(e) => {
                          const val = stripNonNumericAndSymbols(e.target.value);
                          setPhone(val);
                        }}
                        errorMsg="Phone is required"
                        style={styles.textfield}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <FormQuestion
                  name="return_type"
                  setter={setReturnType}
                  value={return_type}
                  num={1}
                />
                <FormQuestion
                  name="years_need"
                  setter={setYearsNeed}
                  value={years_need}
                  num={2}
                />
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Qp5vmr3MANw?si=MV88snVfJCJ1Hx35"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                />
                <FormQuestion
                  name="expected_5_year"
                  setter={setExpected5Year}
                  value={expected_5_year}
                  num={3}
                />
                <FormQuestion
                  name="inflaction_concern"
                  setter={setInflactionConcern}
                  value={inflaction_concern}
                  num={4}
                />
                <FormQuestion
                  name="long_term_delcine_10"
                  setter={setLongTermDelcine10}
                  value={long_term_delcine_10}
                  num={5}
                />
                <FormQuestion
                  name="long_term_delcine_20"
                  setter={setLongTermDelcine20}
                  value={long_term_delcine_20}
                  num={6}
                />
                <FormQuestion
                  name="annual_return"
                  setter={setAnnualReturn}
                  value={annual_return}
                  num={7}
                />
                <FormQuestion
                  name="invested_stocks_bonds"
                  setter={setInvestedStocksBonds}
                  value={invested_stocks_bonds}
                  num={8}
                />
                <FormQuestion
                  name="choosing_investment"
                  setter={setChoosingInvestment}
                  value={choosing_investment}
                  num={9}
                />
                <FormQuestion
                  name="maximum_loss"
                  setter={setMaximumLoss}
                  value={maximum_loss}
                  num={10}
                />
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '20px',
                  }}
                >
                  <Button
                    type="button"
                    onClick={handleSubmit}
                    style={styles.oAuthBtn}
                    disabled={!firstname || !lastname || !validEmail || !validPhone || !radiosFilled}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                color: 'black',
                marginTop: '135px',
                marginBottom: '500px',
              }}
            >
              <Typography variant="h5" style={styles.question}>
                Thank you for your sumbission.<br/>
                Your results have been sent to your advisor.<br/><br/>
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserForm;
