import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import { Button, GridForm, MaskInput } from '../lib/common';

import ClientAssetForm from './client-asset-form';
import ClientDebtForm from './client-debt-form';
import {
  newAnnuity,
  newEmployerPlan,
  newInsurance,
  newDebt,
} from '../lib/initializers';

const ClientAssetsList = (props) => {
  const { client, assetTypes } = props;
  const { records } = client;
  const [assets, setAssets] = useState(records.assets);
  const [debt, setDebt] = useState(records.debt);

  let totalTaxable = 0;
  let totalTaxDeferred = 0;
  let totalTaxFree = 0;
  let totalDebt = 0;

  const taxableAssets = assets.filter((a) => (!a.tax_free && !a.tax_deferred));
  const taxDeferredAssets = assets.filter((a) => a.tax_deferred);
  const taxFreeAssets = assets.filter((a) => a.tax_free);

  const taxableAssetTypes = assetTypes.filter((a) => a.taxable);
  const taxFreeAssetTypes = assetTypes.filter((a) => a.tax_free);
  const taxDeferredAssetTypes = assetTypes.filter((a) => a.tax_deferred);

  function newAsset(tax_free, tax_deferred) {
    return {
      type: '',
      holding: '',
      amount: '',
      institution: '',
      tax_deferred,
      tax_free,
      isNew: true,
    };
  }

  assets.forEach((a) => {
    a.displays = a.displays || {
      showAnnuity: false,
      showInsurance: false,
      showEmployerPlan: false,
    };
    if (!a.annuityRecord) {
      a.annuityRecord = newAnnuity(a.id, client.id);
    }
    if (!a.insuranceRecord) {
      a.insuranceRecord = newInsurance(a.id, client.id);
    }
    if (!a.employerPlanRecord) {
      a.employerPlanRecord = newEmployerPlan(a.id, client.id);
    }
  });

  taxableAssets.forEach((a) => {
    if (!a.to_delete) {
      totalTaxable += Number(a.amount || 0);
    }
  });
  taxDeferredAssets.forEach((a) => {
    if (!a.to_delete) {
      totalTaxDeferred += Number(a.amount || 0);
    }
  });
  taxFreeAssets.forEach((a) => {
    if (!a.to_delete) {
      totalTaxFree += Number(a.amount || 0);
    }
  });
  debt.forEach((a) => {
    if (!a.to_delete) {
      totalDebt += Number(a.balance || 0);
    }
  });

  function update() {
    records.assets = assets.slice();
    records.debt = debt.slice();
    setAssets(records.assets);
    setDebt(records.debt);
  }

  const assetsUpdated = (b, wasNew, del) => {
    if (del) {
      setAssets(assets.filter((a) => b.id !== a.id));
      return;
    }

    if (wasNew) {
      b.insuranceRecord = newInsurance(b.id, b.client_id);
      b.annuityRecord = newAnnuity(b.id, b.client_id);
      b.employerPlanRecord = newEmployerPlan(b.id, b.client_id);
    }

    const updatedAssets = assets.slice();

    assets.forEach((a, idx) => {
      if (wasNew) {
        if (a.isNew && (a.tax_free === b.tax_free) && (a.tax_free === b.tax_free)) {
          updatedAssets[idx] = b;
        }
      } else if (!wasNew && (a.id === b.id)) {
        b.insuranceRecord = a.insuranceRecord;
        b.annuityRecord = a.annuityRecord;
        b.employerPlanRecord = a.employerPlanRecord;
        updatedAssets[idx] = b;
      }
    });
    setAssets(updatedAssets);
  };

  const debtUpdated = (b, wasNew, del) => {
    if (del) {
      setDebt(debt.filter((a) => b.id !== a.id));
      return;
    }

    const updatedDebt = debt.slice();

    debt.forEach((a, idx) => {
      if (wasNew) {
        if (a.isNew) {
          updatedDebt[idx] = b;
        }
      } else if (a.id === b.id) {
        updatedDebt[idx] = b;
      }
    });
    setDebt(updatedDebt);
  };

  const cumulativeTotal = totalTaxFree + totalTaxable + totalTaxDeferred;
  return (
    <>
      <GridForm>
        <Typography variant="h5" align="center">Taxable Bucket</Typography>
        {assets.map((r, idx) => {
          if (r.to_delete || r.tax_free || r.tax_deferred) {
            return '';
          }
          return (
            <ClientAssetForm
              key={r.id || 'newtaxable'}
              client={client}
              asset={r}
              carriers={props.carriers}
              institutions={props.institutions}
              holdingTypes={props.holdingTypes}
              assetTypes={taxableAssetTypes}
              fundTypes={props.fundTypes}
              annuityTypes={props.annuityTypes}
              assetsUpdated={assetsUpdated}
              insuranceTypes={props.insuranceTypes}
              employerPlanTypes={props.employerPlanTypes}
              update={(record) => {
                assets[idx] = record;
                update();
              }}
            />
          );
        })}
      </GridForm>
      <GridForm>
        <Grid container spacing={2}>
          <Grid item xs={9}><Typography variant="body2" align="right">Taxable Total:</Typography></Grid>
          <Grid item xs={3}>
            <MaskInput
              mask_type="currency"
              id="totalTaxable"
              name="totalTaxable"
              value={totalTaxable}
              fullWidth
              readOnly
              customInput={InputBase}
              style={{ textAlign: 'end', fontSize: 14 }}
            />
          </Grid>
        </Grid>
      </GridForm>
      <Button
        id="addTaxable"
        householdadd
        style={{ margin: '5px' }}
        onClick={() => {
          const newAssetRecord = newAsset(false, false);
          assets.push(newAssetRecord);
          update();
        }}
      >
        Add Asset
      </Button>

      <GridForm>
        <Typography variant="h5" align="center">Tax Deferred Bucket</Typography>
        {assets.map((r, idx) => {
          if (r.to_delete || !r.tax_deferred) {
            return '';
          }
          return (
            <ClientAssetForm
              key={r.id || 'newtaxadeferred'}
              client={client}
              asset={r}
              carriers={props.carriers}
              institutions={props.institutions}
              holdingTypes={props.holdingTypes}
              assetTypes={taxDeferredAssetTypes}
              fundTypes={props.fundTypes}
              annuityTypes={props.annuityTypes}
              assetsUpdated={assetsUpdated}
              insuranceTypes={props.insuranceTypes}
              employerPlanTypes={props.employerPlanTypes}
              update={(record) => {
                assets[idx] = { ...record };
                update();
              }}
            />
          );
        })}
        <Grid container spacing={2}>
          <Grid item xs={9}><Typography variant="body2" align="right">Tax Deferred Total:</Typography></Grid>
          <Grid item xs={3}>
            <MaskInput
              mask_type="currency"
              id="totalTaxDeferred"
              name="totalTaxDeferred"
              value={totalTaxDeferred}
              fullWidth
              readOnly
              customInput={InputBase}
              style={{ textAlign: 'end', fontSize: 14 }}
            />
          </Grid>
        </Grid>
      </GridForm>
      <Button
        id="addTaxDeferred"
        householdadd
        style={{ margin: '5px' }}
        onClick={() => {
          const newAssetRecord = newAsset(false, true);
          assets.push(newAssetRecord);
          update();
        }}
      >
        Add Asset
      </Button>

      <GridForm>
        <Typography variant="h5" align="center">Tax Free Bucket</Typography>
        {assets.map((r, idx) => {
          if (r.to_delete || !r.tax_free) {
            return '';
          }
          return (
            <ClientAssetForm
              key={r.id || 'newTaxFree'}
              client={client}
              asset={r}
              carriers={props.carriers}
              institutions={props.institutions}
              holdingTypes={props.holdingTypes}
              assetTypes={taxFreeAssetTypes}
              fundTypes={props.fundTypes}
              annuityTypes={props.annuityTypes}
              assetsUpdated={assetsUpdated}
              insuranceTypes={props.insuranceTypes}
              employerPlanTypes={props.employerPlanTypes}
              update={(record) => {
                assets[idx] = record;
                update();
              }}
            />
          );
        })}
        <Grid container spacing={2}>
          <Grid item xs={9}><Typography variant="body2" align="right">Tax Free Total:</Typography></Grid>
          <Grid item xs={3}>
            <MaskInput
              mask_type="currency"
              id="totalTaxFree"
              name="totalTaxFree"
              value={totalTaxFree}
              fullWidth
              readOnly
              customInput={InputBase}
              style={{ textAlign: 'end', fontSize: 14 }}
            />
          </Grid>
        </Grid>
      </GridForm>
      <Button
        id="addTaxFree"
        householdadd
        style={{ margin: '5px' }}
        onClick={() => {
          const newAssetRecord = newAsset(true, false);
          assets.push(newAssetRecord);
          update();
        }}
      >
        Add Asset
      </Button>

      <GridForm>
        <Grid container spacing={2}>
          <Grid item xs={9}><Typography variant="body2" align="right">Cumulative Total Assets:</Typography></Grid>
          <Grid item xs={3}>
            <MaskInput
              mask_type="currency"
              id="cumulativeTotal"
              name="cumulativeTotal"
              value={cumulativeTotal}
              fullWidth
              readOnly
              customInput={InputBase}
              style={{ textAlign: 'end', fontSize: 14 }}
            />
          </Grid>
        </Grid>
      </GridForm>

      <GridForm>
        <Typography variant="h5" align="center" mt={5}>Debt</Typography>
        {debt.filter((r) => !r.to_delete).map((r, idx) => {
          return (
            <ClientDebtForm
              key={r.id || 'newDebt' + idx}
              client={client}
              debt={r}
              debtTypes={props.debtTypes}
              debtUpdated={debtUpdated}
              update={(record) => {
                debt[idx] = record;
                client.records.debt = debt.slice();
                setDebt(client.records.debt);
              }}
            />
          );
        })}
        <Grid container spacing={2}>
          <Grid item xs={9}><Typography variant="body2" align="right">Debt Total:</Typography></Grid>
          <Grid item xs={3}>
            <MaskInput
              mask_type="currency"
              id="totalDebt"
              name="totalDebt"
              value={totalDebt}
              fullWidth
              readOnly
              customInput={InputBase}
              style={{ textAlign: 'end', fontSize: 14 }}
            />
          </Grid>
        </Grid>
      </GridForm>
      <Button
        householdadd
        style={{ margin: '5px' }}
        onClick={() => {
          const newDebtRecord = newDebt(props.debtTypes[0].id);
          debt.push(newDebtRecord);
          update();
        }}
      >
        Add Debt
      </Button>
    </>
  );
};

export default ClientAssetsList;
