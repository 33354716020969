import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import ClientAdvisorForm from './client-advisor-form';
import { Button } from '../lib/common';

const useStyles = makeStyles({
  container: {
    overflowX: 'scroll',
  },
  table: {
    minWidth: 1130,
  },
});

const Advisors = (props) => {
  const { client, updateAll, trustedAdvisors } = props;
  const [advisors, setAdvisors] = useState(client.records.advisors);

  function newAdvisor() {
    return {
      firstname: '',
      lastname: '',
      type: '',
      firm_name: '',
      phone: '',
      email: '',
      isNew: true,
    };
  }

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        {advisors.filter((advisor) => !advisor.to_delete).map((advisor, idx) => {
          return (
            <ClientAdvisorForm
              key={idx}
              index={idx}
              client={client}
              advisor={advisor}
              trustedAdvisors={trustedAdvisors}
              update={(advisor) => {
                advisors[idx] = advisor;
                client.records.advisors = advisors.slice();
                setAdvisors(client.records.advisors);
              }}
            />
          );
        })}
        <Button
          householdadd
          style={{ margin: '15px' }}
          onClick={() => {
            const advisor = newAdvisor();
            advisors.push(advisor);
            client.records.advisors = advisors.slice();
            updateAll();
          }}
        >
          Add Advisor
        </Button>
      </div>
    </div>
  );
};

export default Advisors;
