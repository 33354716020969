import React, { useState } from 'react';
import moment from 'moment';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { MaskInput, Select, TextField } from '../lib/common';
import { formatCurrency } from '../lib/util';

const ClientInsuranceForm = (props) => {
  const {
    carriers,
    insurance,
    insuranceTypes,
    update,
  } = props;

  const [other, setOther] = useState(false);
  const [company_name, setCompanyName] = useState(insurance.company_name);
  const [type_id, setTypeId] = useState(insurance.type_id);
  const [issue_date, setIssueDate] = useState(insurance.issue_date);
  const [death_amount, setDeathAmount] = useState(formatCurrency(insurance.death_amount));
  const [premium_amount, setPremiumAmount] = useState(formatCurrency(insurance.premium_amount));
  const [frequency, setFrequency] = useState(insurance.frequency);
  const [accum_amount, setAccumAmount] = useState(formatCurrency(insurance.accum_amount));
  const [cash_amount, setCashAmount] = useState(formatCurrency(insurance.cash_amount));
  const [interest_rate, setInterestRate] = useState(insurance.interest_rate);
  const [carrier_id, setCarrierId] = useState(insurance.carrier_id);

  const data = {
    company_name,
    type_id,
    issue_date,
    death_amount,
    premium_amount,
    frequency,
    accum_amount,
    cash_amount,
    interest_rate,
    carrier_id,
  };

  const handleTextChange = (e, setter) => {
    const { name, value } = e.target || e;
    update({ ...insurance, ...data, [name]: value });
    setter(value);
  };

  const handleDateChange = (e, setter) => {
    const { name, value } = e.target;
    const formattedDate = moment(value).toISOString();
    update({ ...insurance, ...data, [name]: formattedDate });
    setter(formattedDate);
  };

  const handleCompanyName = (e, setter) => {
    const { value } = e.target;
    if (Number(value) === 0) {
      setOther(true);
    } else {
      setOther(false);
    }
    handleTextChange(e, setter);
  };

  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h6" align="center">Life Policy Details</Typography>
      <Box
        display="grid"
        gridTemplateColumns={`repeat(${other ? 15 : 12}, 1fr)`}
        gap={2}
        width={other ? 985 : 785}
      >
        <Box gridColumn="span 3">
          <Select
            label="Company Name"
            value={carrier_id}
            name="carrier_id"
            onChange={(e) => handleCompanyName(e, setCarrierId)}
          >
            <MenuItem value={0}>Other (Not in List)</MenuItem>
            {carriers.sort((a, b) => a.order_val - b.order_val).map((i) => {
              return (<MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>);
            })}
          </Select>
        </Box>
        {
          other && (
            <Box gridColumn="span 3" sx={{ display: !other && 'hidden' }}>
              <TextField
                label="Company Name (if not in list)"
                id="company_name"
                name="company_name"
                value={company_name}
                onChange={(e) => handleTextChange(e, setCompanyName)}
              />
            </Box>
          )
        }
        <Box gridColumn="span 3">
          <Select
            label="Type"
            value={type_id}
            name="type_id"
            onChange={(e) => handleTextChange(e, setTypeId)}
          >
            {insuranceTypes.sort((a, b) => a.order_val - b.order_val).map((i) => {
              return (<MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>);
            })}
          </Select>
        </Box>
        <Box gridColumn="span 3">
          <TextField
            label="Issue Date"
            value={issue_date}
            name="issue_date"
            onChange={(e) => handleDateChange(e, setIssueDate)}
            type="date"
          />
        </Box>
        <Box gridColumn="span 3">
          <MaskInput
            mask_type="currency"
            label="Death Benefit Amount"
            id="death_amount"
            name="death_amount"
            value={death_amount}
            onChange={(e) => handleTextChange(e, setDeathAmount)}
          />
        </Box>
      </Box>
      <Grid container spacing={2}>
        <MaskInput
          mask_type="currency"
          label="Premium Amount"
          name="premium_amount"
          value={premium_amount}
          onChange={(e) => handleTextChange(e, setPremiumAmount)}
        />
        <Select
          label="Frequency"
          value={frequency}
          name="frequency"
          onChange={(e) => handleTextChange(e, setFrequency)}
        >
          <MenuItem value="MONTHLY">Monthly</MenuItem>
          <MenuItem value="Annually">Annually</MenuItem>
        </Select>
        <MaskInput
          mask_type="currency"
          label="Accumulation Amount"
          name="accum_amount"
          value={accum_amount}
          onChange={(e) => handleTextChange(e, setAccumAmount)}
        />
        <MaskInput
          mask_type="currency"
          label="Cash Value Amount"
          name="cash_amount"
          value={cash_amount}
          onChange={(e) => handleTextChange(e, setCashAmount)}
        />
      </Grid>
      <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
        Interest Rate on Fixed Account?
      </Typography>
      <TextField
        id="interest_rate"
        name="interest_rate"
        value={interest_rate}
        onChange={(e) => handleTextChange(e, setInterestRate)}
        type="number"
        fullWidth
      />
    </div>
  );
};

export default ClientInsuranceForm;
