import { json2csv } from 'json-2-csv';
import moment from 'moment';

function download(rows, filename) {
  json2csv(rows, (err, csv) => {
    if (err) {
      return;
    }
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
    element.setAttribute('download', `${filename}-${moment().format('YYYY-MM-DD')}.csv`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  });
}

export default download;
