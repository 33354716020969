import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Grid, MenuItem } from '@mui/material';

import states from '../lib/states';
import {
  TextField, Select, GridForm, MaskInput,
} from '../lib/common';

const ClientForm = (props) => {
  const {
    update,
    client,
    taxFilingStatusTypes,
    genders,
    scpErrors,
    bsfErrors,
  } = props;
  const [firstname, setFirstName] = useState(client.firstname);
  const [middlename, setMiddleName] = useState(client.middlename);
  const [lastname, setLastName] = useState(client.lastname);
  const [suffix, setSuffix] = useState(client.suffix);
  const [birth_state, setBirthState] = useState(client.birth_state);
  const [birth_country, setBirthCountry] = useState(client.birth_country);
  const [phone, setPhone] = useState(client.phone);
  const [home_phone, setHomePhone] = useState(client.home_phone);
  const [dob, setDob] = useState(client.dob);
  const [email, setEmail] = useState(client.email);
  const [marital_status, setMaritalStatus] = useState(client.marital_status);
  const [goal_ret_age, setGoalRetAge] = useState(client.goal_ret_age);
  const [dat_income, setDatIncome] = useState(client.dat_income);
  const [tax_filing_status_id, setTaxFilingStatusId] = useState(client.tax_filing_status_id);
  const [gender_id, setGenderId] = useState(client.gender_id);
  const [ssn, setSsn] = useState(client.ssn);
  const [paddress, setPaddress] = useState(client.paddress);
  const [pcity, setPcity] = useState(client.pcity);
  const [pstate, setPstate] = useState(client.pstate);
  const [pzip, setPzip] = useState(client.pzip);
  const [maddress, setMaddress] = useState(client.maddress);
  const [mcity, setMcity] = useState(client.mcity);
  const [mstate, setMstate] = useState(client.mstate);
  const [mzip, setMzip] = useState(client.mzip);
  const [dl_expiration, setDlExpiration] = useState(client.dl_expiration);
  const [dl_issue, setDlIssue] = useState(client.dl_issue);
  const [dl_num, setDlNum] = useState(client.dl_num);
  const [dl_state, setDlState] = useState(client.dl_state);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setErrors({ ...scpErrors, ...bsfErrors });
  }, []);

  const data = {
    firstname,
    middlename,
    lastname,
    suffix,
    birth_state,
    birth_country,
    phone,
    home_phone,
    dob,
    email,
    marital_status,
    goal_ret_age,
    dat_income: Number(dat_income),
    tax_filing_status_id,
    gender_id,
    ssn,
    paddress,
    pcity,
    pstate,
    pzip,
    maddress,
    mcity,
    mstate,
    mzip,
    dl_expiration,
    dl_issue,
    dl_num,
    dl_state,
  };

  const handleTextChange = (e, setter) => {
    const { name, value } = e.target || e;
    const updatedClient = { ...client, ...data, [name]: value };
    update(updatedClient);
    setter(value);
  };

  const handleDateChange = (e, setter) => {
    const { name, value } = e.target;
    const formattedValue = moment(value).toISOString();
    const updatedClient = { ...client, ...data, [name]: formattedValue };
    update(updatedClient);
    setter(formattedValue);
  };

  return (
    <GridForm>
      <Grid container spacing={2}>
        <TextField
          name="firstname"
          label="First Name"
          value={firstname}
          required
          onChange={(e) => handleTextChange(e, setFirstName)}
          error={errors?.firstname}
          errorMsg="First name is required"
        />
        <TextField
          label="Middle Name"
          name="middlename"
          value={middlename}
          onChange={(e) => handleTextChange(e, setMiddleName)}
        />
        <TextField
          label="Last Name"
          name="lastname"
          value={lastname}
          required
          onChange={(e) => handleTextChange(e, setLastName)}
          error={errors?.lastname}
          errorMsg="Last name is required"
        />
        <TextField
          label="Suffix"
          name="suffix"
          value={suffix}
          onChange={(e) => handleTextChange(e, setSuffix)}
          xs={1}
        />
        <TextField
          label="Date of Birth"
          value={dob}
          name="dob"
          onChange={(e) => handleDateChange(e, setDob)}
          type="date"
          error={errors?.dob}
          errorMsg="Date of birth is required"
          xs={2}
        />
      </Grid>
      <Grid container spacing={2}>
        <MaskInput
          label="Cell Phone"
          name="phone"
          value={phone}
          required={true}
          onChange={(e) => handleTextChange(e, setPhone)}
          mask_type="tel"
          error={errors?.phone}
          errorMsg="Phone number is required"
        />
        <MaskInput
          label="Home/Office Phone"
          name="home_phone"
          value={home_phone}
          onChange={(e) => handleTextChange(e, setHomePhone)}
          mask_type="tel"
          error={errors?.home_phone}
        />
        <TextField
          label="Email"
          value={email}
          name="email"
          onChange={(e) => handleTextChange(e, setEmail)}
          expand
          error={errors?.email}
          errorMsg="Email is required"
        />
      </Grid>
      <Grid container spacing={2}>
        <MaskInput
          label="Social Security Number"
          value={ssn}
          name="ssn"
          onChange={(e) => handleTextChange(e, setSsn)}
          mask_type="ssn"
          error={errors?.ssn}
          errorMsg="Social security number is required"
        />
        <Select
          label="Marital Status"
          value={marital_status}
          name="marital_status"
          onChange={(e) => handleTextChange(e, setMaritalStatus)}
          error={errors?.marital_status}
          errorMsg="Marital status is required"
        >
          <MenuItem value="SINGLE">Single</MenuItem>
          <MenuItem value="MARRIED">Married</MenuItem>
          <MenuItem value="DIVORCED">Divorced</MenuItem>
          <MenuItem value="WIDOW">Widow</MenuItem>
        </Select>
        <Select
          label="Tax Filing Status"
          value={tax_filing_status_id}
          name="tax_filing_status_id"
          onChange={(e) => handleTextChange(e, setTaxFilingStatusId)}
        >
          {
            taxFilingStatusTypes
              .sort((a, b) => a.order_val - b.order_val)
              .map((tfs) => <MenuItem value={tfs.id} key={tfs.id}>{tfs.name}</MenuItem>)
          }
        </Select>
        <Select
          label="State of Birth"
          value={birth_state}
          name="birth_state"
          onChange={(e) => handleTextChange(e, setBirthState)}
        >
          {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
        </Select>
      </Grid>
      <Grid container spacing={2}>
        <TextField
          label="Country of Birth"
          name="birth_country"
          value={birth_country}
          onChange={(e) => handleTextChange(e, setBirthCountry)}
        />
        <Select
          label="Gender"
          value={gender_id}
          name="gender_id"
          onChange={(e) => handleTextChange(e, setGenderId)}
          error={errors?.gender_id}
          errorMsg="Gender is required"

        >
          {
            genders
              .sort((a, b) => a.order_val - b.order_val)
              .map((gender) => <MenuItem value={gender.id} key={gender.id}>{gender.name}</MenuItem>)
          }
        </Select>
        <TextField
          label="Goal Retirement Age"
          value={goal_ret_age}
          name="goal_ret_age"
          onChange={(e) => handleTextChange(e, setGoalRetAge)}
          type="number"
          min={1}
          max={100}
          error={errors?.goal_ret_age}
          errorMsg="Field is required"
        />
        <MaskInput
          mask_type="currency"
          label="Desired After Tax Income"
          value={dat_income}
          name="dat_income"
          onChange={(e) => handleTextChange(e, setDatIncome)}
          error={errors?.dat_income}
          errorMsg="Income is required"
        />
      </Grid>
      <Grid container spacing={2}>
        <TextField
          label="Physical Address"
          value={paddress}
          name="paddress"
          onChange={(e) => handleTextChange(e, setPaddress)}
          error={errors?.paddress}
          errorMsg="Primary address is required"
        />
        <TextField
          label="City"
          value={pcity}
          name="pcity"
          onChange={(e) => handleTextChange(e, setPcity)}
          error={errors?.pcity}
          errorMsg="Primary city is required"
        />
        <Select
          label="State"
          value={pstate}
          required={true}
          name="pstate"
          onChange={(e) => handleTextChange(e, setPstate)}
          error={errors?.pstate}
          errorMsg="Primary state is required"
        >
          {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
        </Select>
        <MaskInput
          mask_type="zip"
          label="Postal Code"
          value={pzip}
          name="pzip"
          onChange={(e) => handleTextChange(e, setPzip)}
          error={errors?.pzip}
          errorMsg="Primary zip code is required"
        />
      </Grid>
      <Grid container spacing={2}>
        <TextField
          label="Mailing Address"
          value={maddress}
          name="maddress"
          onChange={(e) => handleTextChange(e, setMaddress)}
          error={errors?.maddress}
          errorMsg="Mailing address is required"
        />
        <TextField
          label="City"
          value={mcity}
          name="mcity"
          onChange={(e) => handleTextChange(e, setMcity)}
          error={errors?.mcity}
          errorMsg="Mailing city is required"
        />
        <Select
          label="State"
          value={mstate}
          name="mstate"
          onChange={(e) => handleTextChange(e, setMstate)}
          error={errors?.mstate}
          errorMsg="Mailing state is required"
        >
          {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
        </Select>
        <MaskInput
          mask_type="zip"
          label="Postal Code"
          value={mzip}
          name="mzip"
          onChange={(e) => handleTextChange(e, setMzip)}
          error={errors?.mzip}
          errorMsg="Mailing zip code is required"
        />
      </Grid>
      <Grid container spacing={2}>
        <TextField
          label="Driver License Number"
          value={dl_num}
          name="dl_num"
          onChange={(e) => handleTextChange(e, setDlNum)}
          error={errors?.dl_num}
          errorMsg="Driver's license number is required"
        />
        <TextField
          label="DL Issue Date"
          value={dl_issue}
          name="dl_issue"
          onChange={(e) => handleDateChange(e, setDlIssue)}
          type="date"
          error={errors?.dl_issue}
          errorMsg="Driver's license issue date is required"
        />
        <TextField
          label="DL Expiration Date"
          value={dl_expiration}
          name="dl_expiration"
          onChange={(e) => handleDateChange(e, setDlExpiration)}
          type="date"
          error={errors?.dl_expiration}
          errorMsg="Expired driver's license"
        />
        <Select
          label="State"
          value={dl_state}
          name="dl_state"
          onChange={(e) => handleTextChange(e, setDlState)}
          error={errors?.dl_state}
          errorMsg="Driver's license state is required"
        >
          {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
        </Select>
      </Grid>
    </GridForm>
  );
};

export default ClientForm;
