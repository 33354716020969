import React, { useState } from 'react';
import { Button, GridForm } from '../lib/common';

import ClientTrustForm from './client-trust-form';

function newTrust() {
  return {
    name: '',
    trust_date: null,
    trust_updated: null,
    isNew: true,
  };
}

const Trusts = (props) => {
  const { client, trustTypes, trustDistinctionTypes } = props;
  const { records } = client;
  const [trusts, setTrusts] = useState(records.trusts);

  function update() {
    records.trusts = trusts.slice();
    setTrusts(records.trusts);
  }

  return (
    <GridForm>
      {trusts.filter((trust) => !trust.to_delete).map((trust, idx) => {
        return (
          <ClientTrustForm
            key={idx}
            index={idx}
            client={client}
            trust={trust}
            trustTypes={trustTypes}
            trustDistinctionTypes={trustDistinctionTypes}
            update={(record) => {
              records.trusts[idx] = record;
              update();
            }}
          />
        );
      })}
      <Button
        householdadd
        style={{ margin: '15px' }}
        onClick={() => {
          const trust = newTrust();
          trusts.push(trust);
          setTrusts(trusts.slice());
        }}
      >
        Add Trust
      </Button>

    </GridForm>
  );
};

export default Trusts;
