import SocketIO from 'socket.io-client';
import EventEmitter from 'events';
import { v4 as uuidv4 } from 'uuid';

import config from '../config';
// import { apiFetch } from './fetch';

let socketInstance = null;
const events = new EventEmitter();
const CHUNK_SIZE = 500 * 1024; // 500 KB
const MAX_CLIENT_FILE_SIZE = 50 * 1024 * 1024;

/* eslint-disable no-inner-declarations */

export async function getSocket() {
  if (!socketInstance) {
    // const { token } = await apiFetch('/users/socket/me');
    socketInstance = SocketIO(`${config.API_URL}`, {
      transports: ['websocket'],
    });
    socketInstance.events = events;
    // socketInstance.jwt = token;
    window.socketInstance = socketInstance; // devtools
    // socketInstance.emit('token_auth', { token });

    socketInstance.on('reconnect', async () => {
      // const { token } = await apiFetch('/users/socket/me');
      // socketInstance.jwt = token;
      // socketInstance.emit('token_auth', { token });
    });
  }
  return socketInstance;
}

export async function sendFile({
  file,
  client_visible,
  parent_id,
  shType,
  statusFunc,
  client_id,
}) {
  const socket = await getSocket();
  const transferId = uuidv4().replace(/-/g, '');

  const promise = new Promise((resolve, reject) => {
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let currentChunk = 0;

    if (file.size > 50 * 1024 * 1024) {
      // return reject(new Error('File size too large'));
    }

    try {
      if (file.size > MAX_CLIENT_FILE_SIZE) {
        // console.log('file size too large', transferId);
        throw new Error('File size greater than 50MB');
      }
      const startData = {
        fileName: file.name,
        type: file.type,
        transferId,
        client_visible,
        parent_id,
        totalChunks,
        size: file.size,
        shType,
        client_id,
      };
      // console.log('start-file-upload', startData);
      socket.emit('start-file-upload', startData);
      // console.log('start-file-upload-emitted');
      socket.on('file-chunk-received' + transferId, (data) => {
        // console.log('file-chunk-received', data);
        if (statusFunc) {
          statusFunc({
            currentChunk: data.currentChunk,
            totalChunks,
          });
        }
      });
      socket.on('file-upload-error' + transferId, (data) => {
        // console.error('file-upload-error', data);
        reject(new Error(data.error));
      });
      socket.on('file-upload-complete' + transferId, (data) => {
        // console.log('file-upload-complete', data);
        resolve(data);
      });
      function sendChunk() {
        const start = currentChunk * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
        const reader = new FileReader();
        reader.onload = (event) => {
          socket.emit('file-chunk' + transferId, {
            fileName: file.name,
            chunk: event.target.result,
            currentChunk,
            totalChunks,
            transferId,
          });

          currentChunk++;
          if (currentChunk < totalChunks) {
            sendChunk();
          } else {
            socket.emit('file-complete' + transferId, {
              fileName: file.name,
              transferId,
            });
          }
        };

        reader.readAsArrayBuffer(chunk);
      }
      setTimeout(() => {
        sendChunk();
      }, 100);
    } catch (e) {
      // console.error('file upload error', e);
      reject(e);
    }
  });
  return promise;
}

export function closeSocket() {
  socketInstance.close();
  socketInstance = null;
}
