import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, GridForm } from '../lib/common';

import ClientRealEstateForm from './client-real_estate-form';
import { cleanNumber, formatCurrency } from '../lib/util';

const RealEstateList = (props) => {
  const { client } = props;
  const [realEstate, setRealEstate] = useState(client.records.realEstate);

  let totalEquity = 0;
  if (realEstate) {
    realEstate.forEach((c) => {
      totalEquity += cleanNumber(c.equity) || 0;
    });
  }

  function newRealEstate() {
    return {
      address: '',
      type_id: '',
      balance: '',
      rate: '',
      estimated_value: '',
      equity: '',
      isNew: true,
    };
  }

  return (
    <GridForm>
      {realEstate.filter((r) => !r.to_delete).map((r, idx) => {
        return (
          <ClientRealEstateForm
            key={idx}
            index={idx}
            client={client}
            real_estate={r}
            isNew={client.isNew}
            realEstateTypes={props.realEstateTypes}
            update={(record) => {
              realEstate[idx] = record;
              client.records.realEstate = realEstate.slice();
              setRealEstate(client.records.realEstate);
            }}
          />
        );
      })}
      <Grid container spacing={2}>
        <Grid item xs={9}><Typography variant="body2" align="right">Total Real Estate Equity:</Typography></Grid>
        <Grid item xs={3}>
          <Typography variant="body2" align="right">${formatCurrency(totalEquity) || 0}</Typography>
        </Grid>
      </Grid>
      <Button
        householdadd
        style={{ margin: '15px' }}
        onClick={() => {
          const newPolicy = newRealEstate();
          realEstate.push(newPolicy);
          client.records.realEstate = realEstate.slice();
          setRealEstate(client.records.realEstate);
        }}
      >
        Add Property
      </Button>

    </GridForm>
  );
};

export default RealEstateList;
