import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';

import { Button, GridForm, MaskInput } from '../lib/common';

import ClientIncomeForm from './client-income-form';

function newIncome(future) {
  return {
    source: '',
    annual: '',
    future,
    isNew: true,
  };
}

const ClientIncomeList = (props) => {
  const { client } = props;
  const { records } = client;
  const [income, setIncome] = useState(records.income);

  let totalCurrent = 0;
  let totalFuture = 0;

  const currentIncome = income.filter((a) => !a.future);
  const futureIncome = income.filter((a) => a.future);

  function update() {
    records.income = income.slice();
    setIncome(records.income);
  }

  currentIncome.forEach((a) => {
    totalCurrent += Number(a.annual || 0);
  });
  futureIncome.forEach((a) => {
    totalFuture += Number(a.annual || 0);
  });

  useEffect(async () => {
    setIncome(client.records.income);
  }, []);

  return (
    <>
      <GridForm>
        <Typography variant="h5" align="center">Current Income</Typography>
        {income.map((r, idx) => {
          if (r.to_delete || r.future) {
            return '';
          }
          return (
            <ClientIncomeForm
              key={r.id || 'newCurrent' + idx}
              client={client}
              income={r}
              incomeSources={props.incomeSources}
              update={(record) => {
                records.income[idx] = record;
                update();
              }}
            />
          );
        })}
        <Grid container spacing={2}>
          <Grid item xs={9}><Typography variant="body2" align="right">Total Current:</Typography></Grid>
          <Grid item xs={3}>
            <MaskInput
              mask_type="currency"
              id="totalCurrent"
              name="totalCurrent"
              value={totalCurrent}
              fullWidth
              readOnly
              customInput={InputBase}
              style={{ textAlign: 'end', fontSize: 14 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={9}><Typography variant="body2" align="right">Total Current Monthly:</Typography></Grid>
          <Grid item xs={3}>
            <MaskInput
              mask_type="currency"
              id="totalCurrent"
              name="totalCurrent"
              value={totalCurrent / 12}
              fullWidth
              readOnly
              customInput={InputBase}
              style={{ textAlign: 'end', fontSize: 14 }}
            />
          </Grid>
        </Grid>
      </GridForm>
      <Button
        householdadd
        style={{ margin: '5px' }}
        onClick={() => {
          const newIncomeRecord = newIncome(false);
          income.push(newIncomeRecord);
          setIncome(income.slice());
        }}
      >
        Add Current income
      </Button>

      <GridForm>
        <Typography variant="h5" align="center">Future Income</Typography>
        {income.map((r, idx) => {
          if (r.to_delete || !r.future) {
            return '';
          }
          return (
            <ClientIncomeForm
              key={r.id || 'newFuture' + idx}
              client={client}
              income={r}
              incomeSources={props.incomeSources}
              update={(record) => {
                records.income[idx] = record;
                update();
              }}
            />
          );
        })}
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Typography variant="body2" align="right">
              Total Future:
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <MaskInput
              mask_type="currency"
              id="totalFuture"
              name="totalFuture"
              value={totalFuture}
              fullWidth
              readOnly
              customInput={InputBase}
              style={{ textAlign: 'end', fontSize: 14 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={9}><Typography variant="body2" align="right">Total Future Monthly:</Typography></Grid>
          <Grid item xs={3}>
            <MaskInput
              mask_type="currency"
              id="totalFuture"
              name="totalFuture"
              value={totalFuture / 12}
              fullWidth
              readOnly
              customInput={InputBase}
              style={{ textAlign: 'end', fontSize: 14 }}
            />
          </Grid>
        </Grid>
      </GridForm>
      <Button
        householdadd
        style={{ margin: '5px' }}
        onClick={() => {
          const newIncomeRecord = newIncome(true);
          income.push(newIncomeRecord);
          setIncome(income.slice());
        }}
      >
        Add Future income
      </Button>
    </>
  );
};

export default ClientIncomeList;
