import * as React from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import throttle from 'lodash/throttle';
import { apiFetch } from '../lib/fetch';
import useUser from '../lib/useUser';

export default function ClientSearch(props) {
  const { value, excludeHousehold, disabled } = props;
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const user = useUser();

  const fetch = React.useMemo(() => throttle(async (request, callback) => {
    let rows;
    if (excludeHousehold) {
      rows = await apiFetch(`/users/${user?.id}/client-search`, {
        query: { input: request.input, excludeHousehold },
      });
    } else {
      rows = await apiFetch(`/users/${user?.id}/client-search`, { query: { input: request.input } });
    }
    const ignore = props.ignore || [];
    rows = rows.filter((r) => {
      return !ignore.includes(r.id);
    });
    return callback(rows);
  }, 200), []);

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  function formatOption(option) {
    if (typeof option === 'string') {
      return option;
    }
    const {
      firstname,
      lastname,
      email,
      dob,
      num,
      suffix,
    } = option;
    const emailStr = email || '';
    const dobStr = dob ? moment(dob).format('YYYY-MM-DD') : '';
    const ageStr = dob ? moment().diff(dob, 'years', false) + ' yrs' : '';
    const suffixStr = suffix ? ` ${suffix}` : '';
    return `${firstname} ${lastname}${suffixStr} ${ageStr} ${dobStr} ${emailStr} ${num}`;
  }
  return (
    <Autocomplete
      sx={{ flexGrow: 1, color: 'black' }}
      getOptionLabel={(option) => formatOption(option)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      disabled={disabled}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if (props.onChange) {
          props.onChange(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Search Client List" fullWidth />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={PersonIcon}
                  sx={{ color: 'black', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="body2" color="text.secondary">
                  {formatOption(option)}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
