import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import InputBase from '@mui/material/InputBase';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment/moment';

import { colors } from '../lib/styles';
import useUser from '../lib/useUser';
import Layout from '../components/layout';
import { MaskInput } from '../lib/common';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  {
    field: 'id',
    headerName: 'Details',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return <Link to={`/override_details/${params.row.id}`} style={{ color: colors.darkAccent }}>Details</Link>;
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return (
        <MaskInput
          mask_type="currency"
          id="amount"
          name="amount"
          value={params.row.amount}
          fullWidth
          readOnly
          customInput={InputBase}
          style={{ textAlign: 'end', fontSize: 14 }}
        />
      );
    },
  },
  {
    field: 'qual_tier',
    headerName: 'Qualified Pillar',
    align: 'right',
    width: 150,
  },
  {
    field: 'statement_date',
    headerName: 'Month',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      const { statement_date } = params.row;
      return <span>{statement_date ? moment(statement_date).add(11, 'hours').format('YYYY-MM') : ''}</span>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'right',
    width: 150,
  },
];

const Payments = () => {
  const user = useUser();
  const classes = useStyles();
  const [err] = useState(null);
  const path = `/users/${user.id}/override_payments?orderBy=created_at`;
  const { data: payments, loading: paymentsLoading } = useSWR(path);

  return (
    <Layout>
      <div>{err?.toString()}</div>
      <Paper>
        {!paymentsLoading ? (
          <div className={classes.gridContainer}>
            <DataGrid columns={columns} rows={payments || []} />
          </div>
        ) : <CircularProgress/>}
      </Paper>
    </Layout>
  );
};

export default Payments;
