import React, { useState } from 'react';
import { Button } from '../lib/common';

import ClientBeneficiaryForm from './client-beneficiary-form';

function newBen() {
  return {
    firstname: '',
    middlename: '',
    lastname: '',
    birth_state: '',
    phone: '',
    dob: null,
    type: null,
    isNew: true,
  };
}

const Beneficiaries = (props) => {
  const { client, relationshipTypes } = props;
  const { records } = client;
  const [bens, setBens] = useState(records.beneficiaries);

  function update() {
    records.beneficiaries = bens.slice();
    setBens(records.beneficiaries);
  }

  return (
    <div>
      <div>
        <div>
          {bens.filter((ben) => !ben.to_delete).map((ben, idx) => {
            return (
              <div key={idx}>
                <ClientBeneficiaryForm
                  idx={idx}
                  client={client}
                  ben={ben}
                  relationshipTypes={relationshipTypes}
                  isNew={client.isNew}
                  update={(record) => {
                    records.beneficiaries[idx] = record;
                    update();
                  }}
                />
              </div>
            );
          })}
          <Button
            householdadd
            onClick={() => {
              const ben = newBen();
              bens.push(ben);
              setBens(bens.slice());
            }}
            style={{ margin: '15px' }}
          >
            Add Beneficiary
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Beneficiaries;
