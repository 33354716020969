import React, { useState } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { TextField, Select, MaskInput } from '../lib/common';
import DeleteButton from './delete-button';
import { formatCurrency } from '../lib/util';

const ClientRealEstateForm = (props) => {
  const { real_estate, update } = props;
  const [address, setAddress] = useState(real_estate.address);
  const [type_id, setTypeId] = useState(real_estate.type_id);
  const [balance, setBalance] = useState(formatCurrency(real_estate.balance));
  const [rate, setRate] = useState(real_estate.rate);
  const [estimated_value, setEstimatedValue] = useState(formatCurrency(real_estate.estimated_value));
  const [equity, setEquity] = useState(formatCurrency(real_estate.equity));

  const handleTextChange = (e, setter) => {
    const { name, value } = e.target || e;
    const data = {
      address,
      type_id,
      balance,
      rate,
      estimated_value,
      equity,
    };
    update({ ...real_estate, ...data, [name]: value });
    setter(value);
  };

  const handleDelete = () => {
    real_estate.to_delete = true;
    update(real_estate);
  };

  return (
    <>
      <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
        Property {'#' + (props.index + 1)}
        <DeleteButton handleDelete={handleDelete} iconStyle={{ marginLeft: '15px' }}/>
      </Typography>

      <Box display="grid" gridTemplateColumns="repeat(15, 1fr)" gap={2} minWidth={985}>
        <Box gridColumn="span 6">
          <TextField
            label="Street Address"
            id="address"
            name="address"
            value={address}
            onChange={(e) => handleTextChange(e, setAddress)}
          />
        </Box>
        <Box gridColumn="span 3">
          <Select
            label="Type"
            value={type_id}
            name="type_id"
            onChange={(e) => handleTextChange(e, setTypeId)}
          >
            {props.realEstateTypes.sort((a, b) => a.order_val - b.order_val).map((i) => {
              return (<MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>);
            })}
          </Select>
        </Box>
        <Box gridColumn="span 3">
          <MaskInput
            mask_type="currency"
            label="Current Loan Balance"
            id="balance"
            name="balance"
            value={balance}
            onChange={(e) => handleTextChange(e, setBalance)}
          />
        </Box>
        <Box gridColumn="span 3" sx={{ display: 'flex', alignItems: 'center' }}>
          <DeleteButton handleDelete={handleDelete}/>
        </Box>
        <Box gridColumn="span 3">
          <TextField
            label="Rate"
            id="rate"
            name="rate"
            type="number"
            value={rate}
            onChange={(e) => handleTextChange(e, setRate)}
          />
        </Box>
        <Box gridColumn="span 3">
          <MaskInput
            mask_type="currency"
            label="Estimated Property Value"
            id="estimated_value"
            name="estimated_value"
            value={estimated_value}
            onChange={(e) => handleTextChange(e, setEstimatedValue)}
          />
        </Box>
        <Box gridColumn="span 3">
          <MaskInput
            mask_type="currency"
            label="Property Equity"
            id="equity"
            name="equity"
            value={equity}
            onChange={(e) => handleTextChange(e, setEquity)}
          />
        </Box>
      </Box>
    </>
  );
};

export default ClientRealEstateForm;
