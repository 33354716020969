import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { omit, sortBy } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useSWR from 'swr';
import DownloadButton from './download-csv';

import useUser from '../lib/useUser';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'address',
    headerName: 'Address',
    align: 'left',
    width: 200,
  },
  {
    field: 'city',
    headerName: 'City',
    align: 'left',
    width: 150,
  },
  {
    field: 'state',
    headerName: 'State',
    align: 'left',
    width: 50,
  },
  {
    field: 'zip',
    headerName: 'Zip',
    align: 'left',
    width: 80,
  },
  {
    field: 'type',
    headerName: 'Type',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <Link to={`/household/${params.row.household_id}`}>edit</Link>;
    },
  },
];

const ClientAddresses = () => {
  const user = useUser();
  const classes = useStyles();

  const path = `/users/${user.id}/clients/mailing`;
  const { data, loading } = useSWR(path);
  let rows = (data || []).map((r) => {
    return omit(r, [
      'maddress',
      'mcity',
      'mstate',
      'mzip',
      'paddress',
      'pcity',
      'pstate',
      'pzip',
    ]);
  });
  rows = sortBy(rows, ['lastname', 'firstname']);

  return (
    <div>
      <Typography variant="h4">Client Addresses</Typography>
      <div>
        <DownloadButton rows={rows} name="ClientAddresses"/>
      </div>
      {!loading ? (
        <div className={classes.gridContainer}>
          <DataGrid columns={columns} rows={rows} />
        </div>
      ) : <CircularProgress/>}
    </div>
  );
};

export default ClientAddresses;
