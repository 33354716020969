import React from 'react';
import Layout from '../components/layout';
import UnderConstruction from '../components/under-construction';

export default function BusinessResources() {
  return (
    <Layout>
      <UnderConstruction />
    </Layout>
  );
}
