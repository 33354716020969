import { resetReducer, makeDuck, promiseHandler } from 'cooldux';
import { omit } from 'lodash';
import Joi from 'joi';

import validator from './validator';
import { apiFetch, setToken } from '../lib/fetch';

const userSchema = {
  password: Joi.string().min(8).max(10000).required(),
  confirmPassword: Joi.string().min(8).max(10000).required(),
  email: Joi.string().email({ tlds: { allow: false } }).required(),
};

const { verifyEnd, verifyHandler, verifyReducer } = promiseHandler('verify', { namespace: 'user', throwErrors: true });

const duck = makeDuck({
  validate: (token) => apiFetch(`/users/validate/${token}`, { method: 'PUT' }),
  pubValidate: (token) => apiFetch(`/users/pubvalid/${token}`, { method: 'PUT' }),
  signup: (newUser) => {
    const valid = validator(newUser, userSchema);
    if (valid.error) {
      return Promise.reject(valid.error);
    }
    if (newUser.password !== newUser.confirmPassword) {
      return Promise.reject('passwords don\'t match');
    }
    const options = { method: 'POST', body: omit(newUser, 'confirmPassword') };
    return apiFetch('/users', options);
  },
  passwordReset: (email) => {
    const valid = validator({ email }, { email: userSchema.email });
    if (valid.error) {
      return Promise.reject(valid.error);
    }
    const options = { method: 'POST', body: { email } };
    return apiFetch('/users/reset', options);
  },
  updatePassword: (token, password, confirmPassword) => {
    // eslint-disable-next-line
    const valid = validator({ password, confirmPassword }, { password: userSchema.password, confirmPassword: userSchema.confirmPassword });
    if (valid.error) {
      return Promise.reject(valid.error);
    }
    if (password !== confirmPassword) {
      return Promise.reject('passwords don\'t match');
    }
    const options = { method: 'PUT', body: { password, verify_password: confirmPassword } };
    return apiFetch(`/users/reset/${token}`, options);
  },
  auth: (email, password) => {
    // eslint-disable-next-line
    const valid = validator({ email, password }, { email: Joi.string().required(), password: Joi.string().required() });
    if (valid.error) {
      return Promise.reject(valid.error);
    }
    const options = { method: 'POST', body: { email, password } };
    return apiFetch('/auth', options);
  },
  // eslint-disable-next-line
  oauth: (provider) => {
    // TODO: Not sure what to do here
    return '';
  },
  logout: () => {
    return apiFetch('/users/logout')
      .catch(() => null)
      .then(() => {
        setToken(null);
        return null;
      });
  },
}, { namespace: 'user', throwErrors: true });

const {
  initialStateCombined,
  reducerCombined,
  updatePasswordEnd,
} = duck;

export function verify() {
  return (dispatch, getState) => {
    const { auth } = getState().user;
    const promise = auth ? Promise.resolve(auth) : apiFetch('/users/me');
    return verifyHandler(promise, dispatch);
  };
}

export const {
  validate, auth, logout, passwordReset, updatePassword, signup, oauth, pubValidate,
} = duck;

const reducer = resetReducer(initialStateCombined, (state, action) => {
  let newState = reducerCombined(state, action);
  newState = verifyReducer(newState, action);
  const { payload } = action;
  switch (action.type) {
    case verifyEnd.type:
      return { ...newState, auth: payload };
    case updatePasswordEnd.type:
      if (payload && payload.token) {
        setToken(payload.token);
      }
      return { ...newState, auth: payload };
    default:
      return newState;
  }
});

export default reducer;
