import React, { useState } from 'react';
import useSWR from 'swr';
import { useHistory, Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';

import useUser from '../lib/useUser';
import {
  colors,
} from '../lib/styles';
import Layout from '../components/layout';
import { Button, Select } from '../lib/common';
import DownloadButton from '../components/download-csv';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  submitBtn: {
    margin: '15px',
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'details',
    headerName: 'Details',
    align: 'left',
    width: 100,
    renderCell: (params) => {
      return <Link style={{ color: colors.darkAccent }} to={`/bsfs/${params.row.id}`}>Detail</Link>;
    },
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'left',
    width: 150,
    renderCell: (params) => {
      return <span>{new Date(params.row.created_at).toLocaleDateString()}</span>;
    },
  },
  {
    field: 'carrier_name',
    headerName: 'Carrier',
    align: 'left',
    width: 150,
  },
  {
    field: 'cfirstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
    renderCell: (params) => {
      const { row } = params;
      const url = `/household/${row.household_id}/client/${row.client_id}`;
      return <Link style={{ color: colors.darkAccent }} to={url}>{row.cfirstname}</Link>;
    },
  },
  {
    field: 'clastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
    renderCell: (params) => {
      const { row } = params;
      const url = `/household/${row.household_id}/client/${row.client_id}`;
      return <Link style={{ color: colors.darkAccent }} to={url}>{row.clastname}</Link>;
    },
  },
  {
    field: 'product_type_name',
    headerName: 'Product',
    align: 'left',
    width: 150,
  },
  {
    field: 'policy_number',
    headerName: 'Policy',
    align: 'left',
    width: 150,
  },
  {
    field: 'split',
    headerName: 'My split',
    align: 'left',
    width: 100,
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'left',
    width: 200,
    renderCell: (params) => {
      if (params.row.status === 'PENDING_AGENTS') {
        return <span>{params.row.agent === params.row.writing_agent ? 'PENDING_AGENTS' : 'NEEDS_APPROVAL'}</span>;
      }
      if (params.row.status === 'OPEN') {
        return <span>APPROVED</span>;
      }
      return <span>{params.row.status}</span>;
    },
  },
  {
    field: 'submitted_at',
    headerName: 'Effective',
    align: 'left',
    width: 150,
    renderCell: (params) => {
      if (!params.row.submitted_at) {
        return '';
      }
      return <span>{new Date(params.row.submitted_at).toLocaleDateString()}</span>;
    },
  },
];

const BSFs = () => {
  const history = useHistory();
  const classes = useStyles();
  const user = useUser();
  const [err] = useState(null);
  const [status, setStatus] = useState('all');
  const [type, setType] = useState('all');
  const [joint, setJoint] = useState('all');
  let url = `/users/${user.id}/bsf?sortOrder=desc&orderBy=created_at`;
  if (status !== 'all') {
    url += `&status=${status}`;
  }
  if (type !== 'all') {
    url += `&type=${type}`;
  }
  if (joint !== 'all') {
    url += `&joint=${joint}`;
  }
  const { data: bsfs, loading: usersLoading } = useSWR(url);

  return (
    <Layout>
      <div>{err?.toString()}</div>
      <div style={{ margin: 15, marginBottom: 25 }}>
        <h1>Add Household to Submit BSF</h1>
        <Button
          addIcon
          onClick={() => {
            history.push('/household/new');
          }}
          sx={{ backgroundColor: colors.black, color: colors.white }}
        >
          Add Household
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          // justifyContent: 'space-between',
          margin: 10,
        }}
      >
        <Select
          label="Status"
          value={status}
          name="status"
          onChange={(evt) => {
            setStatus(evt.target.value);
          }}
          style={{ marginRight: 10, width: 150 }}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="OPEN">Approved</MenuItem>
          <MenuItem value="CLOSED">Closed</MenuItem>
          <MenuItem value="PENDING_AGENTS">Pending Agents</MenuItem>
          <MenuItem value="REJECTED">Rejected</MenuItem>
        </Select>
        <Select
          label="Type"
          value={type}
          name="type"
          onChange={(evt) => {
            setType(evt.target.value);
          }}
          style={{ marginRight: 10, width: 150 }}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="LIFE">Life</MenuItem>
          <MenuItem value="ANNUITY">Annuity</MenuItem>
          <MenuItem value="HEDGEHOG">HedgeHog</MenuItem>
          <MenuItem value="LANDING_ROCK">Landing Rock</MenuItem>
        </Select>
        <Select
          label="Joint"
          value={joint}
          name="joint"
          onChange={(evt) => {
            setJoint(evt.target.value);
          }}
          style={{ width: 150 }}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="true">Joint</MenuItem>
          <MenuItem value="false">Single Agent</MenuItem>
        </Select>
      </div>
      <Paper>
        {!usersLoading ? (
          <div>
            <div>
              <DownloadButton rows={bsfs || []} name="bsfs"/>
            </div>
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={bsfs || []} />
            </div>
          </div>
        ) : <CircularProgress/>}
      </Paper>
    </Layout>
  );
};

export default BSFs;
