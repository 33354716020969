import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CopyIcon from '@mui/icons-material/CopyAll';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';

function CopyLinkButton(props) {
  const { link } = props;
  const [open, setOpen] = useState(false);
  const handleSnackbarClose = () => {
    setOpen(false);
  };
  const copyText = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setOpen(true);
    } catch (e) {
      // eslint-disable-next-line
      console.error('error copying text', e);
    }
  };

  return (
    <>
      <Tooltip title="Copy Link">
        <CopyIcon
          style={{ cursor: 'pointer' }}
          onClick={copyText}
        />
      </Tooltip>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={2500}
        onClose={handleSnackbarClose}
        message="Link Copied"
        action={(
          <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        )}
      />
    </>
  );
}

export default CopyLinkButton;
