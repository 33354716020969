function unescape64(str) {
  return (str + '==='.slice((str.length + 3) % 4))
    .replace(/-/g, '+')
    .replace(/_/g, '/');
}

function escape64(str) {
  return str.replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

export function encode(str = '') {
  return escape64(btoa(str));
}

export function decode(str = '') {
  return atob(unescape64(str));
}
