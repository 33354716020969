import React, { useState } from 'react';
import useSWR from 'swr';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

import Layout from '../components/layout';
import { colors } from '../lib/styles';
import config from '../config';
import { formatPhoneNumber } from '../lib/util';

const SIDE_NAV_WIDTH = 180;

const useStyles = makeStyles({
  sideNav: {
    backgroundColor: colors.lightGrey,
    display: 'flex',
    flexDirection: 'column',
    // position: 'fixed',
    // zIndex: 100,
    top: 90,
    textAlign: 'center',
    height: 'calc(100vh - 85px)',
    width: SIDE_NAV_WIDTH,
    borderRight: `1px solid ${colors.blackOpacity}`,
    overflowY: 'scroll',
    paddingBottom: 25,
    minWidth: 100,
  },
  btnText: {
    color: colors.black,
    fontSize: 14,
  },
  hashLink: {
    fontFamily: 'Maven Pro, Roboto, Circular Std, sans-serif',
    textAlign: 'start',
    padding: '12px',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
  },
});

const Settings = () => {
  const classes = useStyles();
  const [nav, setNav] = useState(0);
  const { loading, data } = useSWR('/agents/all_company_contacts');

  function NavItem(props) {
    const { navIndex, name } = props;
    const textStyle = {};
    if (nav === navIndex) {
      textStyle.fontWeight = 'bold';
    }
    return (
      <button
        type="button"
        onClick={() => setNav(navIndex)}
        className={classes.hashLink}
      >
        <span
          className={classes.btnText}
          style={textStyle}
        >
          {name}
        </span>
      </button>
    );
  }

  return (
    <Layout>
      {loading ? (
        <div>loading...</div>
      ) : ''}
      {(data && data.length) && (
        <div style={{ display: 'flex', backgroudColor: 'white' }}>
          <div className={classes.sideNav} >
            {data.map((company, idx) => (
              <NavItem name={company.name} navIndex={idx} key={idx}/>
            ))}
          </div>
          <Paper style={{ width: '100%', padding: '10px' }}>
            <div>
              <div>
                <div>
                  <Typography variant="h4">{data[nav].name}</Typography>
                </div>
                <div>
                  <a href={data[nav].url} target="_blank" rel="noreferrer">
                    {data[nav].url}
                  </a>
                </div>
              </div>
              <hr/>
            </div>
            {data[nav].contacts.map((contact, cid) => {
              return (
                <div
                  key={cid}
                  style={{
                    display: 'flex',
                    padding: 10,
                    borderBottom: '1px solid #ddd',
                  }}
                >
                  <div style={{ width: '30%' }}>
                    {contact.image && (
                    <img
                      src={`${config.API_URL}/company_contact_images/${contact.image.id}/download`}
                      alt="avatar"
                      style={{ width: '90%' }}
                    />
                    )}
                  </div>
                  <div style={{ width: '70%' }}>
                    <div>
                      <Typography variant="h6">
                        {contact.firstname} {contact.lastname}
                      </Typography>
                    </div>
                    {contact.job_title && <div>{contact.job_title}</div>}
                    <div>
                      <a href={`mailto:${contact.email}`}>
                        {contact.email}
                      </a>
                    </div>
                    {contact.phone && <div>{formatPhoneNumber(contact.phone)}</div>}
                  </div>
                </div>
              );
            })}
          </Paper>
        </div>
      )}
    </Layout>
  );
};

export default Settings;
