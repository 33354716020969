import { apiFetch } from './fetch';

export default function logClient(userId, client_id, type) {
  apiFetch(`/users/${userId}/clients_log`, {
    method: 'POST',
    body: {
      type,
      client_id,
    },
  })
    .catch((e) => {
      /* eslint-disable-next-line */
      console.warn('error logging client', e);
    });
}
