import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import { apiFetch } from '../lib/fetch';
import useUser from '../lib/useUser';
import { Button } from '../lib/common';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const QualByMonth = () => {
  const [rows, setRows] = useState([]);
  const user = useUser();
  const classes = useStyles();
  const params = useParams();
  const { month } = params;

  useEffect(async () => {
    const records = await apiFetch(`/users/${user.id}/qualified/${month}-15`);
    setRows(records);
  }, []);

  const columns = [
    {
      field: 'firstname',
      headerName: 'First',
      align: 'right',
      width: 150,
    },
    {
      field: 'lastname',
      headerName: 'Last',
      align: 'right',
      width: 150,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      align: 'right',
      width: 150,
      renderCell: (params) => {
        const qualified = Number(params.row.fbs) >= 5000;
        return (
          <span
            style={{
              color: params.row.fbs >= 5000 ? 'green' : 'red',
            }}
          >
            {qualified ? 'qualified' : Number(params.row.fbs).toFixed(2)}
          </span>
        );
      },
    },
  ];

  return (
    <div className={classes.container}>
      <div>
        <Button
          onClick={() => {
            window.close();
          }}
          style={{ margin: '10px' }}
        >
          Close
        </Button>
      </div>
      <Paper>
        <div>
          <h2>Qualified by for month {month}</h2>
        </div>
        <div className={classes.gridContainer}>
          <DataGrid columns={columns} rows={rows} />
        </div>
      </Paper>
    </div>
  );
};

export default QualByMonth;
