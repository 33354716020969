import React from 'react';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {
  TextField, Select, GridForm, MaskInput,
} from '../lib/common';
import DeleteButton from './delete-button';

const ClientAdvisorForm = (props) => {
  const {
    advisor,
    update,
    trustedAdvisors,
    index,
  } = props;

  const handleTextChange = (e) => {
    const { name, value } = e.target || e;
    update({ ...advisor, [name]: value });
  };

  const handleDelete = () => {
    advisor.to_delete = true;
    update(advisor);
  };

  const {
    firstname,
    lastname,
    firm_name,
    phone,
    email,
    trusted_advisor_type_id,
  } = advisor;

  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
        Advisor {'#' + (index + 1)}
        <DeleteButton handleDelete={handleDelete} iconStyle={{ marginLeft: '15px' }}/>
      </Typography>
      <GridForm>
        <Grid container spacing={2}>
          <Select
            label="Trusted Advisor Type"
            value={trusted_advisor_type_id}
            name="trusted_advisor_type_id"
            onChange={handleTextChange}
          >
            {trustedAdvisors.sort((a, b) => a.order_val - b.order_val).map((advisor) => {
              return (<MenuItem value={advisor.id} key={advisor.id}>{advisor.name}</MenuItem>);
            })}
          </Select>
          <TextField
            label="First Name"
            id="firstname"
            name="firstname"
            value={firstname}
            onChange={handleTextChange}
          />
          <TextField
            label="Last Name"
            name="lastname"
            value={lastname}
            onChange={handleTextChange}
          />
          <TextField
            id="firm_name"
            name="firm_name"
            label="Firm Name"
            value={firm_name}
            sx={{ width: '150px' }}
            onChange={handleTextChange}
          />
        </Grid>
        <Grid container spacing={2}>
          <TextField
            label="Email Address"
            id="email"
            name="email"
            value={email}
            onChange={handleTextChange}
            expand
          />
          <MaskInput
            label="Phone"
            id="phone"
            name="phone"
            value={phone}
            mask_type="tel"
            onChange={handleTextChange}
          />
        </Grid>
      </GridForm>
    </div>
  );
};

export default ClientAdvisorForm;
