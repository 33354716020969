import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '../lib/common';
import { apiFetch } from '../lib/fetch';

export default function Step2() {
  const [url, setUrl] = useState(null);
  useEffect(async () => {
    const startStripe = await apiFetch('/enrollment_start');
    setUrl(startStripe.url);
  }, []);
  return (
    <div>
      Thank you for registering!<br/>
      Next step is to pay your enrollment fee:
      {url ? (
        <Button
          onClick={() => {
            document.location.href = url;
          }}
        >
          Continue
        </Button>
      ) : (
        <CircularProgress/>
      )}
    </div>
  );
}
