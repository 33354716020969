import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import { get } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';

import { auth, oauth } from '../state/user';
import {
  colors,
  formButton,
} from '../lib/styles';
import Layout from '../components/layout';
import { Button } from '../lib/common';
import { GoogleButton, MicrosoftButton, startOauth } from '../lib/oAuth';
import { decode } from '../lib/hasher';

const styles = {
  cardHeader: {
    color: colors.lightGrey,
    fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
  },
  container: {
    backgroundAttachment: 'scroll',
    backgroundRepeat: 'repeat',
    height: '100%',
  },
  forgotPassword: {
    color: colors.primaryText,
    fontFamily: [
      'Maven Pro, Roboto, sans-serif, Circular Std, sans-serif',
    ].join(','),
  },
  tintedBackground: {
    margin: '0 auto 100px',
    textAlign: 'center',
    width: '500px',
    backgroundColor: colors.blackOpacity,
    position: 'absolute',
    top: 300,
    left: 0,
    right: 0,
  },
  loginField: {
    margin: '10px',
  },
  errorText: {
    color: colors.secondaryAccent,
    backgroundColor: colors.white,
    margin: '15px',
    padding: '5px',
  },
  form: {
    margin: 'auto',
    padding: '0px 50px 50px',
  },
  loginBrand: {
    height: '18px',
    width: '18px',
    marginRight: '15px',
  },
  loginBrandText: {
    fontSize: '1.2em',
    width: '100%',
    textTransform: 'none',
    textAlign: 'left',
  },
  orSpacer: {
    margin: '10px',
    fontSize: '1.2em',
    color: colors.lightGrey,
  },
  textField: {
    '& label.Mui-focused': {
      color: colors.lightGrey,
    },
    '& .MuiInputLabel-root': {
      color: colors.lightGrey,
    },
    '& .MuiOutlinedInput-input': {
      color: colors.lightGrey,
    },
    '& .MuiOutlinedInput-root': {
      '& > fieldset': {
        borderColor: colors.lightGrey,
        '& .Mui-focused': {
          color: colors.lightGrey,
        },
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      color: colors.lightGrey,
      '& > fieldset': {
        borderColor: colors.lightGrey,
        color: colors.lightGrey,
      },
    },
    '& .MuiOutlinedInput-root:hover': {
      '& > fieldset': {
        borderColor: colors.lightGrey,
        color: colors.lightGrey,
      },
    },
  },
  oAuthBtn: {
    fontFamily: 'Roboto, Helvetica, sans-serif',
    textTransform: 'none',
    marginTop: 10,
    width: 300,
    fontSize: 15,
    marginBottom: 30,
    color: 'white',
    cursor: 'pointer',
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
  },
};

const Login = (props) => {
  const [loginError, setLoginError] = useState();
  const [oauthProviders, setOauthProviders] = useState(null);
  const [viewEmailPassword, setViewEmailPassword] = useState(false);
  const params = useParams();

  useEffect(async () => {
    const result = await startOauth('alogin', params.loc);
    setOauthProviders(result);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const { email, password } = values;

      props.auth(email, password)
        .then(() => {
          let path = '/agent-dashboard';
          if (params.loc) {
            path = decode(params.loc);
          }
          props.history.push(path);
        })
        .catch((e) => {
          setLoginError(e);
        });
    },
  });

  const { user } = props;
  const propErrors = {};
  let errorMsg = '';
  if (user.authError && user.authError.details) {
    user.authError.details.forEach((detail) => {
      propErrors[detail.path] = detail.message;
    });
  }
  const err = user.authError || user.oauthError || loginError;
  if (err) {
    // eslint-disable-next-line
    errorMsg = get(err, 'content.message') || get(err, 'content.error') || err.message;
    if (errorMsg === 'Unauthorized') {
      errorMsg = 'Incorrect email or password. Approved agents should use their Stronghold email';
    }
  }
  return (
    <Layout>
      <div style={styles.container} >
        <Card style={styles.tintedBackground}>
          <CardHeader style={styles.cardHeader} title={<Typography variant="h5">Log In</Typography>} />
          <div style={{ ...styles.errorText, display: errorMsg ? '' : 'none' }}>{errorMsg}</div>
          <div style={styles.form}>
            {viewEmailPassword ? (
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  style={styles.loginField}
                  className="login-field"
                  label="Email"
                  name="email"
                  fullWidth={true}
                  error={formik.touched.email && !!formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                  onChange={formik.handleChange}
                  sx={styles.textField}
                />
                <TextField
                  style={styles.loginField}
                  className="login-field"
                  label="Password"
                  name="password"
                  fullWidth={true}
                  error={formik.touched.password && !!formik.errors.password}
                  helperText={formik.touched.password && formik.errors.password}
                  value={formik.values.password}
                  type="password"
                  onChange={formik.handleChange}
                  sx={styles.textField}
                />
                <Button
                  gold
                  type="submit"
                  style={formButton}
                >
                  Log In
                </Button>
                <p><Link style={styles.forgotPassword} href="/forgot">Forgot your password?</Link></p>
              </form>
            ) : (
              <div>
                <div>
                  {oauthProviders?.google && (
                    <GoogleButton
                      authUrl={oauthProviders?.google?.url}
                    />
                  )}
                  {oauthProviders?.microsoft && (
                    <MicrosoftButton
                      authUrl={oauthProviders?.microsoft?.url}
                    />
                  )}
                </div>
              </div>
            )}
            <Divider color="#888" style={styles.divider} />
            {/* eslint-disable-next-line */}
            <Link onClick={() => setViewEmailPassword(!viewEmailPassword)} style={styles.oAuthBtn}>
              Log in with {viewEmailPassword ? 'a provider' : 'email'}
            </Link>
          </div>
        </Card>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps, { auth, oauth })(Login);
