import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { TreeItem } from '@mui/x-tree-view';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory } from 'react-router-dom';

import { apiFetch } from '../lib/fetch';
import useUser from '../lib/useUser';
import Layout from '../components/layout';
import { Button } from '../lib/common';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const UserItem = (props) => {
  let fbs = 0;
  let isDirect = false;
  const { stats } = props;
  if (stats && stats.directsBusiness) {
    stats.directsBusiness.forEach((db) => {
      if (db.id === props.id) {
        fbs = db.fbs;
        isDirect = true;
      }
    });
  }
  let name = `${props.firstname} ${props.lastname} #${props.num}`;
  if (props.is_deleted) {
    name += ' - inactive';
  }
  return (
    <TreeItem
      nodeId={props.id}
      id={props.id}
      itemId={props.id}
      label={(
        <Typography variant="subtitle1">
          {name}
          {isDirect && !props.is_deleted ? (
            <span style={{ color: fbs >= 5000 ? 'green' : 'red' }}>
              &nbsp; &nbsp;{fbs >= 5000 ? 'qualified' : `$${Number(fbs).toFixed(2)}`}
            </span>
          ) : ''}
        </Typography>
      )}
      sx={{ margin: '10px' }}
    >
      {props.user.children.map((c) => {
        return <UserItem {...c} key={c.id} stats={stats} user={c} id={c.id}/>;
      })}
    </TreeItem>
  );
};

const MyNetwork = () => {
  const classes = useStyles();
  const history = useHistory();
  const [err] = useState(null);
  const [users, setUsers] = useState(null);
  const [stats, setStats] = useState(null);
  const auth = useUser();
  const userId = auth?.id;
  useEffect(async () => {
    if (userId) {
      const statsList = await apiFetch(`/users/${userId}/stats`);
      const userList = await apiFetch(`/users/${userId}/tree`);
      setUsers(userList);
      setStats(statsList);
    }
  }, []);

  let topUser;

  if (users) {
    const userMap = {};
    users.forEach((u) => {
      userMap[u.id] = u;
      u.children = [];
      if (u.id === userId) {
        topUser = u;
      }
    });
    users.forEach((u) => {
      if (u.parent_id && userMap[u.parent_id]) {
        userMap[u.parent_id].children.push(u);
      }
    });
    // remove deleted with no children
    users.forEach((u) => {
      if (u.is_deleted && !u.children.length) {
        delete userMap[u.id];
        if (u.parent_id && userMap[u.parent_id]) {
          userMap[u.parent_id].children = userMap[u.parent_id].children.filter((c) => c.id !== u.id);
        }
      }
    });
  }

  return (
    <Layout>
      <div>{err?.toString()}</div>
      <Paper>
        {topUser ? (
          <div className={classes.gridContainer}>
            <SimpleTreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
              }}
              defaultExpandedItems={[topUser.id]}
            >
              <UserItem {...topUser} stats={stats} user={topUser} id={topUser.id}/>
            </SimpleTreeView>
          </div>
        ) : <CircularProgress/>}
      </Paper>
      <div style={{ margin: '15px' }}>
        <Button
          gold
          onClick={() => {
            history.push(`/register/${auth.num}`);
          }}
        >
          My Recruitment Page
        </Button>
      </div>
    </Layout>
  );
};

export default MyNetwork;
