import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';

import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';
import useUser from '../lib/useUser';
import { Button } from '../lib/common';

const styles = {
  header: {
    backgroundColor: colors.black,
    padding: '5px',
  },
  headerText: {
    color: colors.highlightBright,
    fontWeight: 100,
    paddingLeft: '10px',
  },
  icon: {
    color: colors.white,
  },
  logo: {
    height: '80px',
    padding: '5px 5px 5px 10px',
    marginRight: '10px',
  },
  menuItem: {
    margin: '5px',
    backgroundColor: 'white',
    padding: '3px',
  },
  pubLink: {
    textDecoration: 'none',
    color: 'white',
    margin: '10px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  pubSubLink: {
    textDecoration: 'none',
    color: 'white',
    margin: '8px',
    marginLeft: '30px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
};

function HeaderButton(props) {
  const { pathname } = useLocation();
  const { href, label } = props;

  return (
    <Button
      href={href}
      variant="inherit"
      sx={{
        fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
        fontSize: 12,
        background: href === pathname ? colors.gold : 'primary',
        color: href === pathname ? colors.black : colors.primaryText,
        ':hover': {
          background: href === pathname ? colors.goldHighlight : colors.primaryAccent,
          color: href === pathname ? colors.black : colors.primaryText,
        },
      }}
    >
      {label}
    </Button>
  );
}

function Header() {
  const user = useUser();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  const updateScreenWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    updateScreenWidth();
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  const handleLogout = async () => {
    await apiFetch('/users/logout');
    history.push('/login');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  let btn = (
    <Button
      gold
      href="/agent-dashboard"
      sx={{
        marginRight: '10px',
      }}
    >
      Login
    </Button>
  );

  if (user) {
    btn = (
      <Button
        gold
        href="/"
        onClick={handleLogout}
        sx={{
          marginRight: '10px',
        }}
      >
        Logout
      </Button>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Hidden lgDown>
          <Toolbar style={styles.header}>
            {user ? (
              <Button variant="inherit" href="/">
                <img alt="Home" style={styles.logo} src="/images/stronghold_logo.png" />
              </Button>
            ) : (
              <Button variant="inherit" href="https://strongholdwealthpartners.com/">
                <img alt="Home" style={styles.logo} src="/images/horizontal-gold-web.png" />
              </Button>
            )}
            <Box sx={{ flexGrow: 1 }}>
              {user ? (
                <>
                  {user.approved ? (
                    <>
                      <HeaderButton href="/bsfs" label="My BSFs"/>
                      <HeaderButton href="/payments" label="Payments"/>
                      <HeaderButton href="/overrides" label="Wealth Share"/>
                      <HeaderButton href="/network" label="My Network"/>
                      <HeaderButton href="/new-bsf" label="Submit New BSF"/>
                      <HeaderButton
                        href="https://https://academy.strongholdwealthpartners.com/"
                        label="Training"
                      />
                    </>
                  ) : ''}
                  {user.num ? (
                    <HeaderButton
                      href={`/register/${user.num}`}
                      label="Recruit"
                    />
                  ) : ''}
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    margin: '15px',
                    fontFamily: 'Maven Pro,Helvetica,Arial,Lucida,sans-serif',
                    textDecoration: 'none',
                  }}
                >
                  <div style={{ width: '100%' }}/>
                  <a style={styles.pubLink} href="https://strongholdwealthpartners.com/">Home</a>
                  <a style={styles.pubLink} href="https://strongholdwealthpartners.com/about">About</a>
                  <a style={styles.pubLink} href="https://strongholdwealthpartners.com/services">Services</a>
                  <a style={styles.pubLink} href="https://strongholdwealthpartners.com/contact">Contact</a>
                </div>
              )}
            </Box>
            {btn}
          </Toolbar>
        </Hidden>
        <Hidden lgUp>
          <Toolbar style={styles.header}>
            {user ? (
              <Button variant="inherit" href="/" sx={{ mr: 'auto' }}>
                <img alt="Home" style={styles.logo} src="/images/stronghold_logo.png" />
              </Button>
            ) : (
              <Button
                variant="inherit"
                href="https://strongholdwealthpartners.com/"
                sx={{ mr: 'auto' }}
              >
                <img alt="Home" style={styles.logo} src="/images/horizontal-gold-web.png" />
              </Button>
            )}
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleOpen}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
          <Box>
            {!user ? (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{ marginTop: '30px' }}
                PaperProps={{
                  style: {
                    width,
                    backgroundColor: 'black',
                    borderTop: `3px solid ${colors.secondaryColor}`,
                  },
                }}
              >
                <MenuItem>
                  <a style={styles.pubLink} href="https://strongholdwealthpartners.com/">Home</a>
                </MenuItem>
                <MenuItem>
                  <a style={styles.pubLink} href="https://strongholdwealthpartners.com/about">About</a>
                </MenuItem>
                <MenuItem>
                  <a style={styles.pubLink} href="https://strongholdwealthpartners.com/services">Services</a>
                </MenuItem>
                <MenuItem>
                  <a style={styles.pubSubLink} href="https://strongholdwealthpartners.com/risk-management/">
                    Risk Management
                  </a>
                </MenuItem>
                <MenuItem>
                  <a style={styles.pubSubLink} href="https://strongholdwealthpartners.com/wealth-management/">
                    Wealth Management
                  </a>
                </MenuItem>
                <MenuItem>
                  <a style={styles.pubSubLink} href="https://strongholdwealthpartners.com/tax-planning/">
                    Tax Planning
                  </a>
                </MenuItem>
                <MenuItem>
                  <a style={styles.pubSubLink} href="https://strongholdwealthpartners.com/estate-planning/">
                    Estate Planning
                  </a>
                </MenuItem>
                <MenuItem>
                  <a style={styles.pubSubLink} href="https://strongholdwealthpartners.com/retirement-planning/">
                    Retirement Planning
                  </a>
                </MenuItem>
                <MenuItem>
                  <a style={styles.pubLink} href="https://strongholdwealthpartners.com/contact">Contact</a>
                </MenuItem>
                <MenuItem>
                  {btn}
                </MenuItem>
              </Menu>
            ) : (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{ marginTop: '30px' }}
                PaperProps={{
                  style: {
                    width,
                    backgroundColor: 'black',
                    borderTop: `3px solid ${colors.secondaryColor}`,
                  },
                }}
              >
                <MenuItem>
                  <HeaderButton href="/bsfs" label="My BSFs"/>
                </MenuItem>
                <MenuItem>
                  <HeaderButton href="/payments" label="Payments"/>
                </MenuItem>
                <MenuItem>
                  <HeaderButton href="/overrides" label="Wealth Share"/>
                </MenuItem>
                <MenuItem>
                  <HeaderButton href="/network" label="My Network"/>
                </MenuItem>
                <MenuItem>
                  <HeaderButton href="/new-bsf" label="Submit New BSF"/>
                </MenuItem>
                <MenuItem>
                  <HeaderButton
                    href="https://academy.strongholdwealthpartners.com/"
                    label="Training"
                  />
                </MenuItem>
                {user.num ? (
                  <MenuItem>
                    <HeaderButton
                      href={`/register/${user.num}`}
                      label="Recruit"
                    />
                  </MenuItem>
                ) : ''}
                <MenuItem>
                  <div style={{ marginTop: '40px' }}>{btn}</div>
                </MenuItem>
              </Menu>
            )}
          </Box>
        </Hidden>
      </AppBar>
    </Box>
  );
}

export default Header;
