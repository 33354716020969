import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';

import { apiFetch } from '../lib/fetch';
import useUser from '../lib/useUser';
import { Button } from '../lib/common';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

function DupeClients(props) {
  const { client, dupes, shareRequested } = props;
  const [submitting, setSubmitting] = useState(false);
  const [requests, setRequests] = useState([]);
  const user = useUser();
  const classes = useStyles();

  useEffect(() => {
    const fetchRequests = async () => {
      if (user) {
        const url = `/users/${user.id}/shares/requests/requestor`;
        const response = await apiFetch(url);
        setRequests(response);
      }
    };
    fetchRequests();
  }, []);

  function alreadyRequested(householdId) {
    return requests.some((r) => r.household_id === householdId);
  }

  const dupColumns = [
    { field: 'id', hidden: true },
    {
      field: 'agent',
      headerName: 'Agent',
      width: 200,
      renderCell: (params) => {
        const { agent } = params.row;
        return <span>{`${agent.firstname} ${agent.lastname} #${agent.num}`}</span>;
      },
    },
    { field: 'firstname', headerName: 'First Name', width: 150 },
    {
      field: 'lastname',
      headerName: 'Last Name',
      width: 150,
      renderCell: (params) => {
        const { lastname } = params.row;
        if (lastname.toLowerCase() === ('' + client.lastname).toLowerCase()) {
          return <span style={{ color: 'red' }}>{lastname}</span>;
        }
        return <span>{lastname}</span>;
      },
    },
    {
      field: 'dob',
      headerName: 'DOB',
      width: 100,
      renderCell: (params) => {
        const { dob } = params.row;
        const dobStr = dob ? dob.split('T')[0] : '';
        const clientDobStr = client.dob ? client.dob.split('T')[0] : '';
        if (dobStr === clientDobStr) {
          return <span style={{ color: 'red' }}>{dobStr}</span>;
        }
        return <span>{dobStr}</span>;
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      renderCell: (params) => {
        const { email } = params.row;
        if (email && client.email && (email.toLowerCase() === client.email.toLowerCase())) {
          return <span style={{ color: 'red' }}>{email}</span>;
        }
        return <span>{email}</span>;
      },
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 150,
      renderCell: (params) => {
        const { phone } = params.row;
        if (params.row.dupeByPhone) {
          return <span style={{ color: 'red' }}>{phone}</span>;
        }
        return <span>{phone}</span>;
      },
    },
    {
      field: 'request',
      headerName: 'Access',
      width: 200,
      renderCell: (params) => {
        const { row } = params;
        const already = alreadyRequested(row.household_id);
        if (row.alreadyShared) {
          const link = `/household/${row.household_id}/client/${row.id}`;
          return (
            <Link
              to={link}
              target="_blank"
            >
              Already Shared
            </Link>
          );
        }
        if (row.household_agent === user.id) {
          const link = `/household/${row.household_id}/client/${row.id}`;
          return (
            <Link
              to={link}
              target="_blank"
            >
              My Own Client
            </Link>
          );
        }
        return (
          <Button
            onClick={async () => {
              setSubmitting(true);
              const url = `/users/${user.id}/household/${row.household_id}/request`;
              const hhr = await apiFetch.post(url, { type: 'READ' });
              setRequests([...requests, hhr]);
              if (shareRequested) {
                shareRequested();
              }
              setSubmitting(false);
            }}
            disabled={submitting || already}
          >
            {already ? 'Access Requested' : 'Request Access'}
          </Button>
        );
      },
    },
  ];
  return (
    <div className={classes.gridContainer}>
      <DataGrid columns={dupColumns} rows={dupes || []} />
    </div>
  );
}

export default DupeClients;
