import React, { useState, useEffect } from 'react';
import { omit } from 'lodash';
import { useHistory } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import ClientForm from '../components/client-form-minimal';
import { newClient, updateDefaults } from '../lib/initializers';
import { apiFetch } from '../lib/fetch';
import useUser from '../lib/useUser';
import Layout from '../components/layout';
import { Button, Select } from '../lib/common';
import UserSearch from '../components/user-search';
import DupeClients from '../components/dupe-clients';

const useStyles = makeStyles({
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
  submitBtn: {
    margin: '15px',
  },
  error: {
    fontWeight: 'bold',
    color: 'red',
    margin: '15px',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  ignoreBtn: {
    margin: '15px',
  },
});

const Client = () => {
  const history = useHistory();
  const user = useUser();
  const [client, setClient] = useState(newClient());
  const [submitting, setSubmitting] = useState(false);
  const [err, setErr] = useState(null);
  const [configs, setConfigs] = useState(null);
  const [agentShare1, setAgentShare1] = useState(null);
  const [agentShare1Type, setAgentShare1Type] = useState('READ');
  const [agentShare2, setAgentShare2] = useState(null);
  const [agentShare2Type, setAgentShare2Type] = useState('READ');
  const [duplicates, setDuplicates] = useState(null);
  const classes = useStyles();

  useEffect(async () => {
    const ct = await apiFetch('/clients/config_types');
    setConfigs(ct);
  }, []);

  const handleSave = async () => {
    setSubmitting(true);
    setErr(null);
    try {
      const toSave = omit(updateDefaults(client), ['isNew', 'displays', 'records']);
      if (agentShare1) {
        toSave.agent_share1 = agentShare1.id;
        toSave.agent_share1_type = agentShare1Type;
      }
      if (agentShare2) {
        toSave.agent_share2 = agentShare2.id;
        toSave.agent_share2_type = agentShare2Type;
      }
      if (client.ignore_dupes) {
        toSave.ignore_dupes = true;
      }
      const results = await apiFetch.post(`/users/${user.id}/clients`, toSave);
      if (results.client) {
        history.push(`/household/${results.client.household_id}/client/${results.client.id}`);
      } else {
        setSubmitting(false);
        setDuplicates(results.duplicates);
      }
    } catch (e) {
      setSubmitting(false);
      setErr(e.content || e);
    }
  };

  let invalid = !client.phone || !client.pstate || !client.firstname || !client.email;

  let dupeByEmail = false;

  if (duplicates && client.email) {
    // console.log('duplicates', duplicates);
    duplicates.forEach((d) => {
      if (d.email && d.dupeByEmail && (d.email.toLowerCase() === ('' + client.email).toLowerCase())) {
        dupeByEmail = true;
      }
      if (d.email && d.email.toLowerCase() === client.email.toLowerCase()) {
        invalid = true;
      }
    });
  }

  return (
    <Layout>
      {configs ? (
        <div>
          {duplicates ? (
            <>
              <div className={classes.error}>
                <Typography variant="h4">Client potentially already in system</Typography>
                <DupeClients
                  dupes={duplicates}
                  client={client}
                />
              </div>
              {!dupeByEmail ? (
                <div className={classes.ignoreBtn}>
                  <Button
                    onClick={() => {
                      client.ignore_dupes = true;
                      setClient({ ...client });
                      handleSave();
                    }}
                    disabled={submitting || (invalid)}
                  >
                    Ingore Duplicates and Save
                  </Button>
                </div>
              ) : ''}
            </>
          ) : ''}
          <div>
            <Typography variant="h4">Client Personal Information</Typography>
            {err ? (
              <div className={classes.error}>{err.message}</div>
            ) : ''}
            <ClientForm
              client={client}
              isNew={true}
              update={(c) => {
                setClient({ ...c });
              }}
            />
            <div style={{ width: '450px', margin: '20px' }}>
              <Typography variant="h5">Share with other agents:</Typography>
              <Box sx={{
                gridArea: 'row4',
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                marginBottom: 4,
              }}
              >
                <UserSearch
                  defaultValue=""
                  ignore={[user?.id]}
                  value={agentShare1}
                  onChange={setAgentShare1}
                />
                <Select
                  label="Access"
                  value={agentShare1Type}
                  style={{ width: 200, height: 50 }}
                  onChange={(e) => {
                    setAgentShare1Type(e.target.value);
                  }}
                >
                  <MenuItem value="READ">Read-Only</MenuItem>
                  <MenuItem value="EDIT">Edit</MenuItem>
                </Select>
              </Box>
              <Box sx={{
                gridArea: 'row4',
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                marginBottom: 4,
              }}
              >
                <UserSearch
                  defaultValue=""
                  ignore={[user?.id]}
                  value={agentShare2}
                  onChange={setAgentShare2}
                />
                <Select
                  label="Access"
                  value={agentShare2Type}
                  style={{ width: 200, height: 50 }}
                  onChange={(e) => {
                    setAgentShare2Type(e.target.value);
                  }}
                >
                  <MenuItem value="READ">Read-Only</MenuItem>
                  <MenuItem value="EDIT">Edit</MenuItem>
                </Select>
              </Box>
            </div>
          </div>
          <div className={classes.submitBtn}>
            <Button
              onClick={handleSave}
              disabled={submitting || (invalid)}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <CircularProgress/>
      )}
    </Layout>
  );
};

export default Client;
