import React, { useState } from 'react';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import DeleteButton from './delete-button';
import { TextField, Select, MaskInput } from '../lib/common';
import { formatCurrency } from '../lib/util';

const ClientTermInsuranceForm = (props) => {
  const { term_insurance, update, idx } = props;
  const [company_name, setCompanyName] = useState(term_insurance.company_name);
  const [type_id, setTypeId] = useState(term_insurance.type_id);
  const [issue_date, setIssueDate] = useState(term_insurance.issue_date);
  const [policy_length, setPolicyLength] = useState(term_insurance.policy_length);
  const [death_amount, setDeathAmount] = useState(formatCurrency(term_insurance.death_amount));
  const [premium_amount, setPremiumAmount] = useState(formatCurrency(term_insurance.premium_amount));
  const [frequency, setFrequency] = useState(term_insurance.frequency);

  const data = {
    company_name,
    issue_date,
    policy_length,
    death_amount,
    premium_amount,
    frequency,
    type_id,
  };

  const handleTextChange = (e, setter) => {
    const { name, value } = e.target || e;
    update({ ...term_insurance, ...data, [name]: value });
    setter(value);
  };

  const handleDateChange = (e, setter) => {
    const { name, value } = e.target;
    const formattedDate = moment(value).toISOString();
    update({ ...term_insurance, ...data, [name]: formattedDate });
    setter(formattedDate);
  };

  const handleDelete = () => {
    term_insurance.to_delete = true;
    update(term_insurance);
  };

  return (
    <>
      <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
        Term Insurance {'#' + (idx + 1)}
        - {company_name}
        - ${formatCurrency(death_amount)}
        <DeleteButton handleDelete={handleDelete} iconStyle={{ marginLeft: '15px' }}/>
      </Typography>
      <Grid container spacing={2}>
        <TextField
          name="company_name"
          label="Company name*"
          value={company_name}
          onChange={(e) => handleTextChange(e, setCompanyName)}
        />
        <Select
          label="Type"
          value={type_id}
          name="type_id"
          onChange={(e) => handleTextChange(e, setTypeId)}
        >
          {props.termInsuranceTypes.sort((a, b) => a.order_val - b.order_val).map((i) => {
            return (<MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>);
          })}
        </Select>
        <TextField
          label="Issue Date"
          value={issue_date}
          name="issue_date"
          onChange={(e) => handleDateChange(e, setIssueDate)}
          type="date"
        />
        <TextField
          name="policy_length"
          label="Policy length (yrs)"
          value={policy_length}
          onChange={(e) => handleTextChange(e, setPolicyLength)}
          type="number"
        />
      </Grid>
      <Grid container spacing={2}>
        <MaskInput
          mask_type="currency"
          name="death_amount"
          label="Death Benefit Amount"
          value={death_amount}
          onChange={(e) => handleTextChange(e, setDeathAmount)}
        />
        <MaskInput
          mask_type="currency"
          name="premium_amount"
          label="Premium Amount"
          value={premium_amount}
          onChange={(e) => handleTextChange(e, setPremiumAmount)}
        />
        <Select
          label="Frequency"
          value={frequency}
          name="frequency"
          onChange={(e) => handleTextChange(e, setFrequency)}
        >
          <MenuItem value="MONTHLY">Monthly</MenuItem>
          <MenuItem value="Annually">Annually</MenuItem>
        </Select>
      </Grid>
    </>
  );
};

export default ClientTermInsuranceForm;
