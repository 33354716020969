import React from 'react';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import downloadCsv from '../lib/local-csv';

function Download(props) {
  return (
    <Tooltip title="Download CSV">
      <IconButton
        aria-label="Download"
        onClick={() => {
          downloadCsv(props.rows, props.name);
        }}
        sx={{ color: 'black' }}
      >
        <DownloadIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
}

export default Download;
