import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import { TextField, Select, MaskInput } from '../lib/common';
import ClientAnnuityForm from './client-annuities-form';
import ClientInsuranceForm from './client-insurance-form';
import ClientEmployerPlanForm from './client-employer-plans-form';
import DeleteButton from './delete-button';

import { formatCurrency } from '../lib/util';

const ClientAssetForm = (props) => {
  const {
    asset,
    client,
    assetTypes,
    holdingTypes,
    carriers,
    fundTypes,
    annuityTypes,
    insuranceTypes,
    employerPlanTypes,
    update,
  } = props;

  const [amount, setAmount] = useState(formatCurrency(asset.amount));
  const [holding, setHolding] = useState(asset.holding);
  const [institution, setInstitution] = useState(asset.institution);
  const [type, setType] = useState(asset.type);

  const {
    showAnnuity,
    showInsurance,
    showEmployerPlan,
  } = asset.displays;

  const data = {
    amount,
    holding,
    institution,
    type,
  };

  const handleTextChange = (e, setter) => {
    const { name, value } = e.target || e;
    const updatedClient = { ...asset, ...data, [name]: value };
    update(updatedClient);
    setter(value);
  };

  let hasAnnuity = false;
  holdingTypes.forEach((t) => {
    if ((Number(holding) === t.id) && t.annuity) {
      hasAnnuity = true;
    }
  });

  let hasInsurance = false;
  let hasEmployerPlan = false;
  assetTypes.forEach((t) => {
    if ((Number(type) === t.id) && t.insurance) {
      hasInsurance = true;
    } else if ((Number(type) === t.id) && t.employer_plan) {
      hasEmployerPlan = true;
    }
  });

  const handleDelete = () => {
    asset.to_delete = true;
    update(asset);
  };

  return (
    <>
      <Box display="grid" gridTemplateColumns="repeat(15, 1fr)" gap={2} minWidth={985}>
        <Box gridColumn="span 3">
          <Select
            xs="auto"
            value={type}
            label="Type"
            name="type"
            onChange={(e) => handleTextChange(e, setType)}
          >
            {assetTypes.sort((a, b) => a.order_val - b.order_val).map((i) => {
              return (<MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>);
            })}
          </Select>
        </Box>
        <Box gridColumn="span 3">
          <Select
            xs="auto"
            value={holding}
            label="Holding"
            name="holding"
            onChange={(e) => handleTextChange(e, setHolding)}
          >
            {holdingTypes.sort((a, b) => a.order_val - b.order_val).map((i) => {
              return (<MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>);
            })}
          </Select>
        </Box>
        <Box gridColumn="span 3">
          <TextField
            xs="auto"
            id="institution"
            label="Institution"
            name="institution"
            value={institution}
            onChange={(e) => handleTextChange(e, setInstitution)}
          />
        </Box>
        <Box gridColumn="span 3">
          <MaskInput
            mask_type="currency"
            xs="auto"
            id="amount"
            label="Amount"
            name="amount"
            value={amount}
            onChange={(e) => handleTextChange(e, setAmount)}
          />
        </Box>
        <Box gridColumn="span 3" sx={{ display: 'flex', alignItems: 'center' }}>
          {hasAnnuity && (
            <Tooltip title="Annuity">
              <InfoIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  update({ ...asset, displays: { ...asset.displays, showAnnuity: !showAnnuity } });
                }}
              />
            </Tooltip>
          )}
          {hasInsurance && (
            <Tooltip title="Life Policy">
              <InfoIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  update({ ...asset, displays: { ...asset.displays, showInsurance: !showInsurance } });
                }}
              />
            </Tooltip>
          )}
          {hasEmployerPlan && (
            <Tooltip title="Employer Plan">
              <InfoIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  update({ ...asset, displays: { ...asset.displays, showEmployerPlan: !showEmployerPlan } });
                }}
              />
            </Tooltip>
          )}
          <DeleteButton handleDelete={handleDelete}/>
        </Box>
      </Box>

      <Box>
        {showAnnuity && (
          <ClientAnnuityForm
            carriers={carriers}
            annuity={asset.annuityRecord}
            fundTypes={fundTypes}
            annuityTypes={annuityTypes}
            update={(annuityRecord) => {
              update({ ...asset, annuityRecord });
            }}
          />
        )}
        {showInsurance && (
          <ClientInsuranceForm
            carriers={carriers}
            insurance={asset.insuranceRecord}
            insuranceTypes={insuranceTypes}
            update={(insuranceRecord) => {
              update({ ...asset, insuranceRecord });
            }}
          />
        )}
        {showEmployerPlan && (
          <ClientEmployerPlanForm
            client={client}
            insuranceTypes={insuranceTypes}
            employer_plan={asset.employerPlanRecord}
            employerPlanTypes={employerPlanTypes}
            update={(employerPlanRecord) => {
              const epr = { ...employerPlanRecord };
              const updatedAsset = { ...asset, employerPlanRecord: epr };
              update(updatedAsset);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default ClientAssetForm;
