import React from 'react';

const Contracting = () => {
  return (
    <div>
      <h1>Coming Soon</h1>
    </div>
  );
};

export default Contracting;
