import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { apiFetch } from '../lib/fetch';
import useUser from '../lib/useUser';

export default function EPSuccess() {
  const user = useUser();
  const params = useParams();
  const { evtId } = params;
  useEffect(async () => {
    await apiFetch(`/users/${user.id}/ep_success/${evtId}`);
    document.location.href = '/agent-dashboard';
  }, []);
  return (
    <div>
      Thank you for paying your enrollment fee!<br/>
      <CircularProgress/>
    </div>
  );
}
