import React from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import {
  colors,
} from '../lib/styles';

import { stateMap } from '../lib/states';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  submitBtn: {
    margin: '15px',
  },
  tintedBackground: {
    backgroundColor: colors.blackOpacity,
  },
  linkText: {
    color: 'white',
  },
});

const SearchStates = () => {
  const classes = useStyles();

  const { data } = useSWR('/agents/states');

  return (
    <div style={classes.container}>
      <div style={classes.mainBody}>
        <Typography variant="h4">Agents by State</Typography>
        {data && data.length ? (
          <div className={classes.gridContainer}>
            <ul>
              {data.map((d, idx) => {
                if (stateMap[d.state]) {
                  return (
                    <li key={idx}>
                      <Typography variant="h5">
                        <Link to={`/search/${d.state}`} className={classes.linkText}>
                          {stateMap[d.state]}
                        </Link>
                      </Typography>
                    </li>
                  );
                }
                return '';
              })}
            </ul>
          </div>
        ) : <CircularProgress/>}
      </div>
    </div>
  );
};

export default SearchStates;
