// move to config in future?
export const submissionForms = [
  {
    name: 'Howard Capital Management',
    link: 'https://docs.google.com/document/d/1g58OhHcglqdylmDxpLIaV740EKH6WrKIZktJTWLXyUk/edit?usp=sharing',
  },
  {
    name: 'Portfolio Medics',
    link: 'https://docs.google.com/document/d/1IB4i9TWNfxC9IFOcWEoRKCY8NeZKn8lYGRLK-kLaHbg/edit?usp=sharing',
  },
];

export const requestForms = [
  {
    name: 'Portfolio Medics Contracting',
    link: 'https://hipaa.jotform.com/223618467314155',
  },
  {
    name: 'Portfolio Medics Comparison',
    link: 'https://hipaa.jotform.com/230097723065152',
  },
  {
    name: 'Portfolio Medics Risk Profile Update',
    link: 'https://form.jotform.com/232006400716139',
  },
  {
    name: 'Portfolio Medics ROTH Conversion',
    link: 'https://form.jotform.com/230567879987179',
  },
  {
    name: 'Portfolio Medics Funding',
    link: 'https://form.jotform.com/231655536477162',
  },
  {
    name: 'Portfolio Medics Move Money',
    link: 'https://form.jotform.com/222861602184150',
  },
  {
    name: 'Reverse Mortgage Quote',
    link: 'https://form.jotform.com/232096109052147',
  },
  {
    name: 'FlexMethod Contracting',
    link: 'https://form.jotform.com/232148597508162',
  },
  {
    name: '401GO Contracting Request',
    link: 'https://form.jotform.com/241258344149155',
  },
];
