import React from 'react';
import useSWR from 'swr';
import { Link, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import {
  colors,
} from '../lib/styles';

import { stateMap } from '../lib/states';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  submitBtn: {
    margin: '15px',
  },
  tintedBackground: {
    backgroundColor: colors.blackOpacity,
  },
  linkText: {
    color: 'white',
  },
});

const SearchStates = () => {
  const classes = useStyles();
  const params = useParams();
  const { state } = params;

  const { data } = useSWR(`/agents/list/${state}`);

  return (
    <div style={classes.container}>
      <div style={classes.mainBody}>
        <Typography variant="h4">Agents by City in {stateMap[state] || '?'}</Typography>
        {data && data.length ? (
          <div className={classes.gridContainer}>
            <ul>
              {data.map((d, idx) => {
                return (
                  <li key={idx}>
                    <Typography variant="h5">
                      <Link to={`/register/${d.num}`} className={classes.linkText}>
                        {`${d.city} : ${d.firstname} ${d.lastname}`}
                      </Link>
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : <CircularProgress/>}
      </div>
    </div>
  );
};

export default SearchStates;
