import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button } from '../lib/common';

function DeleteButton(props) {
  const { iconStyle, title } = props;
  const [open, setOpen] = useState(false);
  const fullIconStyle = { ...(iconStyle || { margin: '5px' }), cursor: 'pointer' };
  return (
    <>
      <Tooltip title="Delete">
        <span
          style={fullIconStyle}
          onClick={() => {
            setOpen(true);
          }}
        >
          <DeleteIcon/>{title || ''}
        </span>
      </Tooltip>
      <Dialog open={open}>
        <DialogTitle>Are You Sure?</DialogTitle>
        <div>
          <div>
            <Button
              onClick={(e) => {
                e.preventDefault();
                if (props.handleDelete) {
                  props.handleDelete();
                }
                setOpen(false);
              }}
              style={{ margin: '10px' }}
            >
              Ok
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              style={{ margin: '10px' }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default DeleteButton;
