import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import {
  TextField, Select, TextArea, MaskInput,
} from '../lib/common';
import { formatCurrency } from '../lib/util';

const ClientEmployerPlanForm = (props) => {
  const {
    update,
    employer_plan,
  } = props;

  const [employer_name, setEmployerName] = useState(employer_plan.employer_name);
  const [type_id, setTypeId] = useState(employer_plan.type_id);
  const [account_balance, setAccountBalance] = useState(formatCurrency(employer_plan.account_balance));
  const [contribution_percent, setContributionPercent] = useState(employer_plan.contribution_percent);
  const [owners_annual_income, setOwnersAnnualIncome] = useState(formatCurrency(employer_plan.owners_annual_income));
  const [matching_structure, setMatchingStructure] = useState(employer_plan.matching_structure);
  const [rollover, setRollover] = useState(employer_plan.rollover);
  const [rollover_amount, setRolloverAmount] = useState(formatCurrency(employer_plan.rollover_amount));
  const [contribution_amount, setContributionAmount] = useState(formatCurrency(employer_plan.contribution_amount));

  const handleTextChange = (e, setter) => {
    const { name, value } = e.target || e;
    const data = {
      employer_name,
      type_id,
      account_balance,
      contribution_percent,
      owners_annual_income,
      matching_structure,
      rollover,
      rollover_amount,
      contribution_amount,
    };

    update({ ...employer_plan, ...data, [name]: value });
    setter(value);
  };

  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h6" align="center">Employer Plan Details</Typography>
      <Grid container spacing={2}>
        <TextField
          label="Employer Name"
          id="employer_name"
          name="employer_name"
          value={employer_name}
          onChange={(e) => handleTextChange(e, setEmployerName)}
        />
        <Select
          label="Type"
          value={type_id}
          name="type_id"
          onChange={(e) => handleTextChange(e, setTypeId)}
        >
          {props.employerPlanTypes.sort((a, b) => a.order_val - b.order_val).map((i) => {
            return (<MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>);
          })}
        </Select>
        <MaskInput
          mask_type="currency"
          label="Owner's Annual Income"
          id="owners_annual_income"
          name="owners_annual_income"
          value={owners_annual_income}
          onChange={(e) => handleTextChange(e, setOwnersAnnualIncome)}
        />
        <TextField
          label="If term, policy length (yrs)"
          id="account_balance"
          name="account_balance"
          value={account_balance}
          onChange={(e) => handleTextChange(e, setAccountBalance)}
          type="number"
        />
      </Grid>
      <Grid container spacing={2}>
        <TextField
          label="Contribution Percent"
          id="contribution_percent"
          name="contribution_percent"
          value={contribution_percent}
          onChange={(e) => handleTextChange(e, setContributionPercent)}
          type="number"
        />
        <MaskInput
          mask_type="currency"
          label="OR - Dollar Amount"
          id="contribution_amount"
          name="contribution_amount"
          value={contribution_amount}
          onChange={(e) => handleTextChange(e, setContributionAmount)}
        />
        <Select
          label="Any Rollover funds in this plan?"
          value={rollover}
          name="rollover"
          onChange={(e) => handleTextChange(e, setRollover)}
        >
          <MenuItem value={true}>Yes</MenuItem>
          <MenuItem value={false}>No</MenuItem>
        </Select>
        <MaskInput
          mask_type="currency"
          label="Rollover Amount?"
          id="rollover_amount"
          name="rollover_amount"
          value={rollover_amount}
          onChange={(e) => handleTextChange(e, setRolloverAmount)}
          sx={{ display: rollover ? '' : 'none' }}
        />
      </Grid>

      <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
        If any, what is the matching structure?
      </Typography>
      <Grid container spacing={2}>
        <TextArea
          id="matching_structure"
          name="matching_structure"
          value={matching_structure}
          onChange={(e) => handleTextChange(e, setMatchingStructure)}
        />
      </Grid>
    </div>
  );
};

export default ClientEmployerPlanForm;
